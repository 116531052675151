import React, { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';

const Note = ({modalIsOpenNotes, onClose}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;
    const token = localStorage.getItem('token');
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [selectedNote, setSelectedNote] = useState('');
    const [notes, setNotes] = useState([]);
    const [textNote, setTextNote] = useState('');
    const [linkNote, setLinkNote] = useState('');
    const [hhTime, setHHTime] = useState('00');
    const [mmTime, setMMTime] = useState('00');
    const [hideCreateNote, setHideCreateNote] = useState(true);
    const [value, onChangeCalendar] = useState(new Date());

    useEffect(() => {
        updateNotes()
    }, [modalIsOpenNotes])
    function closeModal() {
        onClose()
    };
    const handleChangeHHTime = (event) => {
        setHHTime(event.target.value);
    };
    const handleChangeMMTime = (event) => {
        setMMTime(event.target.value);
    };
    const handleChangelinkNote = (event) => {
        setLinkNote(event.target.value);
    };
    const handleChangeCalendar = (data) => {
        onChangeCalendar(data);
        closeModalCalendar();
    }
    const closeModalCalendar = () => {
        setIsOpenCalendar(!modalIsOpenCalendar);
    }
    const textareaRef = useRef(null);
    const handleInput = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }; 
    function updateNotes() {
        api.get(`${apiUrl}api/v1/notes/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setNotes(response.data);
            }
        }).catch(error =>{
            console.error(error);
        });
    };
    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
        const rawData = window.atob(base64);
        return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
    }
    function getsogl() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.getRegistration().then(function (registration) {
                if (registration && registration.pushManager) {
                    console.log('Сервисный работник уже зарегистрирован. Подписка не требуется.');
                    return;
                }
                return Notification.requestPermission().then(function (permissionResult) {
                    if (permissionResult !== 'granted') {
                        throw new Error('Permission not granted.');
                    }
                    return navigator.serviceWorker.register('/service-worker.js');
                }).then(function (registration) {
                    return navigator.serviceWorker.ready;
                }).then(function (registration) {
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlB64ToUint8Array(publicVapidKey)
                    });
                }).then(function (subscription) {
                    console.log("Подписка завершена, отправляю данные.");
                    const subscriptionData = {
                        endpoint: subscription.endpoint,
                        keys: {
                            p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                            auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))
                        }
                    };
                    return api.post(`${apiUrl}api/subscribe/`, subscriptionData, {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    });
                }).then((response) => {
                    if (response.status === 201) {
                        console.log('Успешная подписка на уведомления.');
                    }
                }).catch(error => {
                    console.error('Ошибка во время подписки:', error);
                });
            }).catch(error => {
                console.error('Ошибка получения регистрации сервисного работника:', error);
            });
        } else {
            console.log("Push-уведомления или Service Worker не поддерживаются в этом браузере.");
        }
    }
    const convertToUTC = (date, time) => {
        const localDateTime = new Date(`${date}T${time}:00`);
        const utcDateTime = new Date(localDateTime).toISOString();
        return utcDateTime;
    };
    const createNote = (e) => {
        const utcDateTime = convertToUTC(format(new Date(value), 'yyyy-MM-dd'), `${hhTime}:${mmTime}`); 
        if (textNote) {
            api.post(`${apiUrl}api/v1/notes/`, {
                'text': textNote,
                'date_time_send_notification': utcDateTime,
                'link': linkNote,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    updateNotes();
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (error.response.status === 400) {
                    if (error.response.data.errors[0].attr === 'link') {
                        alert(`Код ошибки: ${status}, некорректная ссылка.`, error);
                    }
                }
            });
        }
        else {
            alert("Введите заметку");
        }
    };
    const updateNote = (e) => {
        const utcDateTime = convertToUTC(format(new Date(value), 'yyyy-MM-dd'), `${hhTime}:${mmTime}`); 
        if (textNote) {
            api.patch(`${apiUrl}api/v1/notes/${selectedNote}/`, {
                'text': textNote,
                'date_time_send_notification': utcDateTime,
                'link': linkNote,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateNotes();
                    setSelectedNote("");
                    setHideCreateNote(!hideCreateNote);
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (error.response.status === 400) {
                    if (error.response.data.errors[0].attr === 'link') {
                        alert(`Код ошибки: ${status}, некорректная ссылка.`, error);
                    }
                }
            });
        }
        else {
            alert("Введите заметку");
        }
    };
    const deleteNote = (e) => {
        if (window.confirm('Удалить заметку?')) {
            api.delete(`${apiUrl}api/v1/notes/${selectedNote}/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    setSelectedNote("");
                    setTextNote("");
                    setLinkNote("");
                    setHHTime('00');
                    setMMTime('00');
                    onChangeCalendar(new Date());
                    setHideCreateNote(true);
                    updateNotes();
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    useEffect(() => {
    }, [textNote]);
    
    useEffect(() => {
    }, [linkNote]);

    const editNote = (e, n) => {
        setSelectedNote(n.id);
        setTextNote(n.text || "");
        setLinkNote(n.link || "");
        setHHTime((new Date(n.date_time_send_notification).toLocaleString()).split(", ")[1].slice(0, 2));
        setMMTime((new Date(n.date_time_send_notification).toLocaleString()).split(":")[1].slice(0, 2));
        let datetimelocalformat = new Date(n.date_time_send_notification).toLocaleString();
        let [startday, startmonth, startyear] = datetimelocalformat.split(", ")[0].split(".");
        onChangeCalendar(new Date(`${startyear}-${startmonth}-${startday}`));
        setHideCreateNote(false);
    };
    return (
        <Modal
            className="Modal-for-notes"
            isOpen={modalIsOpenNotes}
            onRequestClose={closeModal}
            overlayClassName="Overlay-for-notes"
            shouldCloseOnOverlayClick={false}
        > 
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModalCalendar}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >   
                <img
                    className="div-data-component-list-img-return"
                    src={apiUrlFrontend + "arrow-return-right.svg"}
                    alt=''
                    onClick={closeModalCalendar}
                />
                <div className="div-calendar">        
                    <Calendar  onChange={(date) => {
                        handleChangeCalendar(date);
                    }}  value={value}/>
                </div>
            </Modal>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                <img
                    className="div-data-component-list-img-return"
                    src={apiUrlFrontend + "arrow-return-right.svg"}
                    alt=''
                    onClick={!hideCreateNote ? () => {
                        setHideCreateNote(!hideCreateNote);
                        setSelectedNote("");
                    }: closeModal}
                />
                <img
                    style={{width: '40px', height: '40px'}}
                    className="div-data-component-list-img"
                    src={apiUrlFrontend + "note-icon.svg"}
                    alt=''
                />
                <label className="div-data-component-list-title">
                    Личные заметки
                </label>
            </div>
            {hideCreateNote === false ?
            <div>
                {!selectedNote ? 
                    <label id="title-create-note">Новая заметка</label>:
                    <label id="title-create-note">Изменить заметку</label>
                }
                <div id="div-create-note">
                    <textarea id="auto-resizing-textarea"
                        ref={textareaRef}
                        value={textNote}
                        onInput={handleInput}
                        onChange={e => setTextNote(e.target.value)}
                        placeholder="Введите текст..."
                    />
                </div>
                <input
                    id="input-note-link"
                    type="url"
                    placeholder="Вставьте ссылку"
                    value={linkNote}
                    onChange={handleChangelinkNote}
                />
                <div style={{display: "flex"}}>
                    <button id="btn-create-note" onClick={() => setIsOpenCalendar(!modalIsOpenCalendar)}>
                    { format(new Date(value), 'yyyy-MM-dd')}
                    </button>
                    <select id="select-time-hh" value={hhTime} onChange={handleChangeHHTime}>
                        {[...Array(24).keys()].map((m) => (
                        <option key={m} value={m < 10 ? `0${m}` : m}>
                            {m < 10 ? `0${m}` : m}
                        </option>
                    ))}
                    </select>
                    <label id="label-time-hh-mm">:</label>
                    <select id="select-time-mm" value={mmTime} onChange={handleChangeMMTime}>
                        {[...Array(60).keys()].map((m) => (
                        <option key={m} value={m < 10 ? `0${m}` : m}>
                            {m < 10 ? `0${m}` : m}
                        </option>
                        ))}
                    </select>
                    {!selectedNote ? 
                        <button id="btn-create-note-accept" onClick={(e) => createNote(e)}>
                            Создать
                        </button>:
                        <div style={{display: 'flex'}}>
                            <button id="btn-delete-note" onClick={(e) => deleteNote(e)}>
                                Удалить
                            </button>
                            <button id="btn-create-note-accept" onClick={(e) => updateNote(e)}>
                                Изменить
                            </button>
                        </div>
                    }
                </div>
                <label id="hint-for-create-note">
                    Вы можете выбрать дату и время отправки напоминания, а так же указать свою ссылку.
                    Напоминание в мессенджеры и на почту придет как обычно, 
                    если требуется отправка непосредственно в браузер, разрешите уведомления.
                </label>
            </div>: 
            <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                <button id="btn-open-create-note" onClick={() => {
                    setHideCreateNote(!hideCreateNote);
                    getsogl();
                }}>
                    Создать
                </button>
            </div>
            }
            <div className="div-data-component-list">
                {notes.map((n, nindex) => 
                    <div style={{display: 'flex', width: '100%'}} key={nindex}>
                        <button id="btn-edit-note" onClick={(e) => editNote(e, n)}>
                            <img
                                style={{width: '20px', height: '20px'}}
                                className="div-data-component-list-img"
                                src={apiUrlFrontend + "edit-icon.svg"}
                                alt=''
                            />
                        </button>
                        <div style={{width: '100%'}}>
                            <div id="div-with-link-and-datetime-note">
                                <label id="note-date-time">
                                    {format(new Date(n.date_time_send_notification), 'dd.MM.yyyy hh:mm')}
                                </label>
                                {n.link ?
                                <a id="note-link" href={n.link} target="_blank" rel="noopener noreferrer">
                                    {n.link.slice(0, 25)}...
                                </a>: null}
                            </div>
                            <div style={{display: "flex"}}>
                                <div id="div-text-note">
                                    {n.text}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default Note;