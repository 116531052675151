import React, { useEffect, useState, useRef} from 'react';
import './styles/Settings.css';
import { format } from 'date-fns';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import CKeditor5 from './components/CKeditor5';
import GetOnlineUser from './components/CustomOnlineUser';
// import './styles/cked.css';
// import 'ckeditor5/ckeditor5.css';

const Settings = () => {
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const timerRef = useRef(null);
    const [firstLoading, setFirstLoading] = useState(true);
    const textareaRef = useRef(null);
    const textareaTemplateTaskTitleRef = useRef(null);
    const [templateDaysInviteLinkActive] = useState([1,2,3,4,5,6,7,8,9,10]);
    const [role, setRole] = useState([]);
    const [filteredrole, setFilteredRole] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [permission, setpermission] = useState([]);
    const [openModalUsers, setOpenModalUsers] = useState(false);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [selectedPerm, setSelectedPerm] = useState([]);
    const [namerole, SetNameRole] = useState('');
    const [descrole, setDescrole] = useState('');
    const [idSelectedRole, setIdSelectedRole] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [openModalAllow, setOpenModalAllow] = useState(false);
    const [modalIsOpenSettingsRoles, setIsOpenSettingsRole] = useState(false);
    const [modalIsOpenSettingsRoles2, setIsOpenSettingsRole2] = useState(false);
    const [checkPermissionRole, setPermissionRole] = useState(false);
    const [canChangeRole, setCanChangeRole] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [canDeleteGroup, setCanDeleteGroup] = useState(false);
    const [createusermodal, setIsOpenCreateUser] = useState(false);
    const [post, setPost] = useState([]);
    const [selectedpost, setselectedpost] = useState([]);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [nameuser, setNameUser] = useState("");
    const [lastname, setLastName] = useState("");
    const [patronymic, setPatronymic] = useState("");
    const [emailuser, setEmailUser] = useState("");
    const [telegramid, setTelegramId] = useState("");
    const [canAddUser, setCanAddUser] = useState(false);
    const [canViewPost, setCanViewPost] = useState(false);
    const [canAddPost, setCanAddPost] = useState(false);
    const [canInviteUser, setCanInviteUser] = useState(false);
    const [loginUserInviteOrganization, setLoginUserInviteOrganization] = useState();
    const [canViewLabel, setCanViewLabel] = useState(false);
    const [modalIsOpenPost, setIsOpenPost] = useState(false);
    const [namepost, setNamePost] = useState("");
    const [flameDaysTask, setFlameDaysTask] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [useUsername, setUseUsername] = useState();
    const [isOpenModalTemplateTask, setIsOpenModalTemplateTask] = useState(false);
    // const [isOpenModalDetailTemplateTask, setIsOpenModalDetailTemplateTask] = useState(false);
    const [templateTask, setTemplateTask] = useState();
    const [selectedTemplateTask, setSelectedTemplateTask] = useState();
    const [selectedAllPermission, setSelectedAllPermission] = useState(false);
    const [label, setLabel] = useState([]);
    const [inviteLink, setInviteLink] = useState("");
    const [dateEndActiveInviteLink, setDateEndActiveInvitelink] = useState();
    const [performer, setPerformer] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [selectedTemplateTaskTitle, setSelectedTemplateTaskTitle] = useState("");
    const [selectedTemplateTaskDescription, setSelectedtemplateTaskDescription] = useState("");
    const [selectedTemplateTaskPriority, setSelectedTemplateTaskPriority] = useState();
    const [modalIsOpenCreateLink, setModalIsOpenCreateLink] = useState(false);
    const [countDaysActiveLink, setCountDaysActivelink] = useState(1);
    const [acceptCreateTaskInStatusCompleted, setAcceptCreateTaskInStatusCompleted] = useState(false);

    const handleCheckboxChangePerformer = (performer) => {
        if (selectedperformers.includes(performer)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedPerformers([...selectedperformers, performer]);
        }
    };

    const handleOpenModalCreateLink = () => {
        api.get(`${apiUrl}api/v1/organizations/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setOrganizationId(response.data[0].id);
                if (Object.keys(response.data[0].invite_link).length !== 0) {
                    setInviteLink(response.data[0].invite_link.link);
                    setDateEndActiveInvitelink(format(new Date(response.data[0].invite_link.date_end_active), 'dd-MM-yyyy'));
                }
                setModalIsOpenCreateLink(!modalIsOpenCreateLink);
            }
        }).catch(error =>{
            const status = error.response ? error.response.status : null;
            if (status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
        } else {
            setSelectedLabels([...selectedlabels, label]);
        }
    };

    function fetchAllUsersForTemplate(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsersForTemplate(page + 1, updatedUsersList);
                } else {
                    setPerformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleOpenModalDetailTemplateTask = (event, templateTask) => {
        setSelectedTemplateTask(templateTask);
        setSelectedtemplateTaskDescription(templateTask.description);
        setSelectedTemplateTaskTitle(templateTask.title);
        setSelectedTemplateTaskPriority(templateTask.priority);
        fetchAllUsersForTemplate();
        setSelectedLabels(templateTask.label.split(',').map(Number));
        setSelectedPerformers(templateTask.performer.split(','));
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
            }
        }).catch(error =>{
            const status = error.response ? error.response.status : null;
            if (status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
        // setIsOpenModalDetailTemplateTask(true);
    };
    
    const handleChangeFlameDays = (event) => {
        const inputValue = event.target.value;
        if (/^[0-9]*$/.test(inputValue)) {
            setFlameDaysTask(inputValue);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (flameDaysTask !== event.target.value) {
                if (event.target.value) {
                    api.patch(`${apiUrl}api/v1/organizations/${organizationId}/`, {
                        'setting_flame_task': event.target.value,
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }).then((response) => {
                        if (response.status === 200) {
                            setFlameDaysTask(event.target.value);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }, 500);
    };

    function closeModal() {
        setIsOpenCreateUser(false);
        setIsOpenSettingsRole(false);
        setIsOpenSettingsRole2(false);
        setOpenModalAllow(false);
        setIsOpenPost(false);
        setModalIsOpenCreateLink(false);
        // setIsOpenModalDetailTemplateTask(false);
        setIsOpenModalTemplateTask(false);
        setOpenModalUsers(false);
        setSelectedPerm("");
        setSelectedUser("");
    };

    const openModalAllowAccess = () => {
        closeModal();
        fetchAllUsers();
        setOpenModalAllow(true);
    };

    const handleTextareachange = (event) => {
        setSearchItem(event.target.value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setIsTextSelected(false);
            if (event.target.value) {
                const regex = new RegExp(searchItem, "i");
                const filtered = role.filter((role) => regex.test(role.name));
                setFilteredRole(filtered);
            } 
            else {
                setFilteredRole(role);
            }
        }, 500);
    };
    const handleDescrolechange = (event) => {
        setDescrole(event.target.value);
    };
    const handleLoginChange = (event) => {
        if (event.target.value.length <= 50) {
            const newValue = event.target.value;
            if (!/[\u0400-\u04FF]/.test(newValue) && !/\s/.test(newValue)) {
            setLogin(newValue);
            }
        } else {
            return;
        }
    };
    const handleNamePostChange = (event) => {
        if (event.target.value.length <= 200) {
            setNamePost(event.target.value);
        } else {
            return;
        }
    }
    const handlePasswordChange = (event) => {
        if (event.target.value.length <= 50) {
            setPassword(event.target.value);
        } else {
            return;
        }
    };
    const handleNameUserChange = (event) => {
        if (event.target.value.length <= 100) {
            setNameUser(event.target.value);
        } else {
            return;
        }
    };
    const handleLastNameChange = (event) => {
        if (event.target.value.length <= 100) {
            setLastName(event.target.value);
        } else {
            return;
        }
    };
    const handlePatronymicChange = (event) => {
        if (event.target.value.length <= 100) {
            setPatronymic(event.target.value);
        } else {
            return;
        }
    };
    const handleEmailUserChange = (event) => {
        if (event.target.value.length <= 100) {
            setEmailUser(event.target.value);
        } else {
            return;
        }
    };

    function handleOpenModalCreateRole() {
        setSelectedAllPermission(false);
        api.get(`${apiUrl}api/identity/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setpermission(response.data);
                setIsOpenSettingsRole(true);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function handleOpenModalCreateUser() {
        api.get(`${apiUrl}api/identity/post/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status===200){
                setPost(response.data);
                setIsOpenCreateUser(true);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } 
                else {
                    setUsers(updatedUsersList);
                    updatedUsersList.forEach(i => {
                        if (i.group.includes(idSelectedRole)) {
                            setSelectedUser(prevSelectedUsers => [...prevSelectedUsers, i.id]);
                        }
                    });
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleSetTruPerm = (data) => {
        let newSelectedPerm = [...selectedPerm];
        if (data === true) {
            setSelectedPerm(permission.map(item => item.id));
            setSelectedAllPermission(true);
        }
        else {
            setSelectedAllPermission(false);
            if (data.id) {
                if (newSelectedPerm.includes(data.id)) {
                    newSelectedPerm = newSelectedPerm.filter(item => item !== data.id);
                } 
                else {
                    newSelectedPerm.push(data.id);
                }
                setSelectedPerm(newSelectedPerm);
            }
            else {
                setSelectedPerm([]);
            }
        }
    };

    const handleSetTruRole = (data) => {
        let newSelectedRole = [...selectedUser];
        if (newSelectedRole.includes(data.id)) {
            newSelectedRole = newSelectedRole.filter(item => item !== data.id);
        } 
        else {
            newSelectedRole.push(data.id);
        }
        setSelectedUser(newSelectedRole);
    };

    const handleDeleteRole = () => {
        api.delete(`${apiUrl}api/identity/groupsuser/${idSelectedRole}/`,{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) {
                closeModal();
                alert("Успешно удалено");
                updateAll();
            }
        })
        .catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleCreateRole = () => {
        if (namerole === ""){
            alert("Введите наименование роли!");
        }
        else {
            api.post(`${apiUrl}api/identity/groupsuser/`, {
                name: namerole,
                name_group: namerole,
                permissions: selectedPerm
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status===201){
                    setSelectedPerm("");
                    SetNameRole("");
                    closeModal();
                    updateAll();
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };

    const handleUpdateRoleAddUsers = () => {
        api.put(`${apiUrl}api/identity/users/${idSelectedRole}/update-users/`, {
            user_id: selectedUser,
            group: idSelectedRole
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status===200){
                setSelectedPerm("");
                closeModal();
                updateAll();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleUpdateRole = () => {
        api.put(`${apiUrl}api/identity/groupsuser/${idSelectedRole}/`, {
            name: namerole,
            name_group: namerole,
            permissions: selectedPerm
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status===200) {
                setSelectedPerm("");
                closeModal();
                updateAll();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleTextAreaDescBlur = (data) => {
        setIsTextSelected(false);
        if (descrole !== "") {
            api.put(`${apiUrl}api/identity/groupsuser/${data.role_id}/`, {
                description: descrole
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setDescrole("");
                }
            }).catch(error => {
            });
        }
        else{
            setDescrole("");
        }
    };

    function updateAll() {
        const loadBoards = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can change group')) {
                        setCanChangeRole(true);
                    }
                    if (response.data.includes('Can add group') && response.data.includes('Can view permission')) {
                        setPermissionRole(true);
                    }
                    if (response.data.includes('Can add Должность')) {
                        setCanAddPost(true);
                    }
                    if (response.data.includes('Can add Пользователь')) {
                        setCanAddUser(true);
                    }
                    if (response.data.includes('Can view Должность')) {
                        setCanViewPost(true);
                    }
                    if (response.data.includes('Can view Пользователь')) {
                        setCanViewUser(true);
                    }
                    if (response.data.includes('Can delete group')) {
                        setCanDeleteGroup(true);
                    }
                    if (response.data.includes('Can view Метка')) {
                        setCanViewLabel(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanChangeRole(true);
                        setPermissionRole(true);
                        setCanAddPost(true);
                        setCanAddUser(true);
                        setCanViewPost(true);
                        setCanViewUser(true);
                        setCanDeleteGroup(true);
                        setCanInviteUser(true);
                    }
                    setFirstLoading(false);
                }
            }).catch(error => {
            });
            await api.get(`${apiUrl}api/v1/organizations/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status===200){
                    setOrganizationId(response.data[0].id);
                    setFlameDaysTask(response.data[0].setting_flame_task);
                    setUseUsername(response.data[0].use_username);
                    setAcceptCreateTaskInStatusCompleted(response.data[0].allow_create_task_in_status_completed);
                }
            }).catch(error => {
            });
        }
        loadBoards();
        api.get(`${apiUrl}api/identity/groupsuser/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status===200){
                setRole(response.data);
                setFilteredRole(response.data);
            }
        }).catch(error => {
            if (error.response.status === 403) {
            }
        });
        api.get(`${apiUrl}api/identity/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setpermission(response.data);
            }
        }).catch(error => {
            if (error.response.status === 403) {
                setPermissionRole(false);
            }
        });
    };
    const autoResize = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };
    const handleNameRoleChange = (event) => {
        const lines = event.target.value.split('\n').length;
        if (lines <= 6) {
            SetNameRole(event.target.value);
            autoResize();
        }
    };

    const handleChangeSelectedTemplateTaskTitle = (event) => {
        const lines = event.target.value.split('\n').length;
        if (lines <= 6) {
            setSelectedTemplateTaskTitle(event.target.value);
            autoResizeTitleTemplateTask();
        }
    };
    const autoResizeTitleTemplateTask = () => {
        const textarea = textareaTemplateTaskTitleRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    const handleUpdateInviteLink = () => {
        if (countDaysActiveLink) {
            api.patch(`${apiUrl}api/v1/organizations/${organizationId}/update-invite-link/`, {
                'date_end_active': countDaysActiveLink
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        navigator.clipboard.writeText(response.data.invite_link.link);
                        alert("Ссылка скопирована в буфер обмена");
                    } catch (err) {
                        console.error('Не удалось скопировать: ', err);
                    }
                    setInviteLink(response.data.invite_link.link);
                    setDateEndActiveInvitelink(format(new Date(response.data.invite_link.date_end_active), 'dd-MM-yyyy'));
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            alert("Укажите количество суток действия ссылки");
        }
    };

    const handleCreateUser = (event) => {
        event.preventDefault();
        if (selectedpost.length > 0 && login !== "" && password !== "" && emailuser !== "") {
            if (telegramid) {
                api.post(`${apiUrl}api/identity/users/`, {
                    username: login,
                    password: password,
                    first_name: nameuser,
                    last_name: lastname,
                    patronymic: patronymic,
                    post: selectedpost,
                    email: emailuser,
                    user_telegram_id: telegramid,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 201){
                        setLogin("");
                        setPassword("");
                        setNameUser("");
                        setLastName("");
                        setPatronymic("");
                        setEmailUser("");
                        setTelegramId("");
                        setselectedpost([]);
                        closeModal();
                    }
                }).catch(error => {
                    const status = error.response ? error.response.status : null;
                    if (status === 400) {
                        if (error.response.data.errors[0].attr === 'email') {
                            alert(`Код ошибки: ${status}, некорректный адрес электронной почты`, error);
                        }                        
                        else if (error.response.data.errors[0].attr === 'username') {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                        }
                        else if (error.response.data.errors[0].attr === 'post') {
                            alert(`Код ошибки: ${status}, Выберите должность`, error);
                        }
                        else {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                        }
                    }
                });
            }
            else {
                api.post(`${apiUrl}api/identity/users/`, {
                    username: login,
                    password: password,
                    first_name: nameuser,
                    last_name: lastname,
                    patronymic: patronymic,
                    post: selectedpost,
                    email: emailuser,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 201){
                        setLogin("");
                        setPassword("");
                        setNameUser("");
                        setLastName("");
                        setPatronymic("");
                        setEmailUser("");
                        setTelegramId("");
                        setselectedpost([]);
                        closeModal();
                    }
                }).catch(error => {
                    const status = error.response ? error.response.status : null;
                    if (status === 400) {
                        if (error.response.data.errors[0].attr === 'email') {
                            alert(`Код ошибки: ${status}, некорректный адрес электронной почты`, error);
                        }                        
                        else if (error.response.data.errors[0].attr === 'username') {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                        }
                        else if (error.response.data.errors[0].attr === 'post') {
                            alert(`Код ошибки: ${status}, Выберите должность`, error);
                        }
                        else {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                        }
                    }
                });
            }
        }
        else{
            alert("Заполните данные.");
        }
    };

    const handleOpenInfoForRole = (data) => {
        setSelectedPerm(data.perm_id);
        SetNameRole(data.role_name);
        setIdSelectedRole(data.role_id);
        api.get(`${apiUrl}api/identity/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setpermission(response.data);
                if (data.perm_id.length === response.data.length) {
                    setSelectedAllPermission(true);
                }
                else {
                    setSelectedAllPermission(false);
                }
                setIsOpenSettingsRole2(true);
                autoResize();
            }
        }).catch(error => {
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    useEffect(() => {
        updateAll();
        // eslint-disable-next-line
    }, []);

    const handlePostChange = (event) =>{
        const groups = event.target.value;
        if (groups === "Должность *") {
            // setFilteredTask(task)    
        }
        else{
            setselectedpost(groups);
        }
    };

    const handleCreatePost = (event) => {
        event.preventDefault();
        api.post(`${apiUrl}api/identity/post/`, {
            name: namepost
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 201){
                setNamePost("");
                closeModal();
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status === 400) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleCopyInvitelink = () => {
        try {
            navigator.clipboard.writeText(inviteLink);
            alert("Ссылка скопирована в буфер обмена");
        } catch (err) {
            console.error('Не удалось скопировать: ', err);
        }
    };

    const handleChangeUseUsername = (use_username) => {
        setUseUsername(!useUsername);
        api.patch(`${apiUrl}api/v1/organizations/${organizationId}/`, {
            'use_username': use_username,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                alert("Успешно обновлено");
                window.location.href = '/settings';
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeAcceptCreateTaskInStatusCompleted = (accept_create_task_completed) => {
        setAcceptCreateTaskInStatusCompleted(!acceptCreateTaskInStatusCompleted);
        api.patch(`${apiUrl}api/v1/organizations/${organizationId}/`, {
            'allow_create_task_in_status_completed': accept_create_task_completed,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                alert("Успешно обновлено");
                window.location.href = '/settings';
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleOpenTemplateModal = (event) => {
        event.preventDefault();
        setSelectedTemplateTask("");
        closeModal();
        api.get(`${apiUrl}api/v1/template-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setTemplateTask(response.data);
                if (response.data.length > 0) {
                    setIsOpenModalTemplateTask(true);
                }
                else {
                    alert("Шаблонов задач нет");
                }
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleChangeTemplateTask = () => {
        api.patch(`${apiUrl}api/v1/template-task/${selectedTemplateTask.id}/`, {
            title: selectedTemplateTaskTitle,
            description: selectedTemplateTaskDescription,
            priority: selectedTemplateTaskPriority,
            label: `${selectedlabels}`,
            performer: `${selectedperformers}`,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                closeModal();
                alert('Успешно обновленно');
            }
        }).catch(error => {
        });
    };

    const handleDeleteSelectedTemplateTask = () => {
        const result = window.confirm(`Действительно хотите удалить шаблон задачи ${selectedTemplateTask.title}`);
        if (result) {
            api.delete(`${apiUrl}api/v1/template-task/${selectedTemplateTask.id}/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    alert("Успешно удалено");
                    closeModal();
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };

    const handleSendNotificationInviteLink = () => {
        if (loginUserInviteOrganization) {
            api.get(`${apiUrl}api/v1/organizations/${organizationId}/invite-user/`, {
                params: {
                    'username': loginUserInviteOrganization
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    alert("Приглашение было отправлено");
                }
            }).catch(error => {
                const status = error.response ? error.response.status: null;
                if (status === 403 && error.response.data.errors[0].detail === 'LinkNotActive') {
                    alert(`Код ошибки: ${status}, Время действия ссылки истекло, обновите ссылку и повторите попытку.`, error);
                }
                if (status === 403 && error.response.data.errors[0].detail === 'UserExistsInThisOrganization') {
                    alert(`Код ошибки: ${status}, Пользователь уже состоит в текущей организации.`, error);
                }
                if (status === 403 && error.response.data.errors[0].detail === 'UserNoActiveNotificationInviteOrganization') {
                    alert(`Код ошибки: ${status}, Пользователь недоступен для приглашения в другие организации.`, error);
                }
                if (status === 500 && error.response.data.errors[0].detail === 'NotAcceptedInviteOrganization') {
                    alert(`Код ошибки: ${status}, Приглашение не было принято.`, error);
                }
                if (status === 404 && error.response.data.errors[0].detail === 'UserNotFound') {
                    alert(`Код ошибки: ${status}, Пользователь не найден.`, error);
                }
            });
        }
        else {
            alert("Заполните логин пользователя");
        }
    };

    const handleOpenModalUsers = () => {
        fetchAllUsers();
        setOpenModalUsers(!openModalUsers);
    };

    const handleEditorChange = (data) => {
        setSelectedtemplateTaskDescription(data);
    };

    const handlePriorityChange = (event) => {
        setSelectedTemplateTaskPriority(event.target.value);
    };

    var screenHeight = window.innerHeight;

    document.title = "Настройки - ТвояЗадача";

    if (firstLoading) {
        return (
            <div className="settings-page">
            <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <span class="loader"></span>
                </div>
            </div>
        )
    }

    return (
        <div className="settings-page">
            <div id="settings-window" style={{maxHeight: screenHeight - 220}}>
                <Modal className="modal-add-data-component"
                    isOpen={isOpenModalTemplateTask}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "template-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Шаблоны
                        </label>
                    </div>
                    {selectedTemplateTask ?
                    <div id="div-detail-template-task">
                        <div style={{display: 'flex', width: '100%', marginTop: '10px', marginBottom: '10px'}}>
                            <textarea id="title-task"
                                value={selectedTemplateTaskTitle}
                                placeholder='Заголовок задачи'
                                onChange={(event) => handleChangeSelectedTemplateTaskTitle(event)}
                                maxLength={250}
                                ref={textareaTemplateTaskTitleRef}
                            />
                        </div>
                        <CKeditor5
                            onEditorChange={(data) => handleEditorChange(data)}
                            editorDisabled={true}
                            descriptiontask={selectedTemplateTaskDescription}
                        />
                        <div style={{display: 'flex'}}>
                            {canViewLabel ?
                                <div id="div-with-performer-and-labels">
                                    <label id="performerlabel-change">Метка</label>
                                    <div style={{height: '200px', overflowY: 'auto'}}>
                                        {label.map((labeli, labelindex) => (
                                            <label id="div-with-label" key={labelindex}>
                                                <div style={{
                                                    background: labeli.color, 
                                                    height: "25px",
                                                    width: "25px",
                                                    borderRadius: "5px",
                                                    }}
                                                >
                                                    <label id="name-labels"
                                                        key={labelindex} 
                                                        style={{fontFamily: 'Roboto', fontSize: '16px'}}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            key={labelindex}
                                                            name="performers"
                                                            onChange={() => handleCheckboxChangeLabel(labeli.id)}
                                                            checked={selectedlabels.includes(labeli.id)}
                                                        />
                                                        <span style={{marginLeft: "25px", textWrap: "nowrap", overflow: "hidden"}}>
                                                            {labeli.name}
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                        ))}
                                    </div>                
                                </div>: null
                            }
                            <div id="div-with-performer-and-labels">
                                <label id="performerlabel-change">Исполнитель</label>
                                <div style={{height: '200px', overflowY: 'auto'}}>
                                    {performer.map((performer, index) => (
                                        <label id="div-with-label" key={index}>
                                            {performer.image ?
                                                <img id="photo-profile-for-performer"
                                                    src={performer.image}
                                                    alt=""
                                                />:
                                                <img id="not-photo-profile-for-performer"
                                                    src={apiUrlFrontend + "person-square.svg"}
                                                    alt=""
                                                />
                                            }
                                            <input
                                                type="checkbox"
                                                key={index}
                                                name="performers"
                                                onChange={() => handleCheckboxChangePerformer(performer.username)}
                                                checked={selectedperformers.includes(performer.username)}
                                            />
                                            <span style={{marginLeft: "10px", fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'bold'}}>
                                                {performer.formated_fio ?
                                                    performer.formated_fio:
                                                    performer.username
                                                }
                                            </span>
                                        </label>
                                    ))}
                                </div>    
                            </div>
                            <div style={{marginLeft: "10px", display: 'flex', flexDirection: 'column', justifyItems: 'center'}}>
                                <label id="performerlabel" style={{marginLeft: '0px '}} >Приоритет</label>
                                <select
                                    id="post-select-change" style={{marginTop: '-15px', height: '52px'}}
                                    value={selectedTemplateTaskPriority}
                                    onChange={handlePriorityChange}
                                >
                                    {Array.from({ length: 10 }, (_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '10px'}}>
                            <button id="openAccessDelete"
                                onClick={() => handleDeleteSelectedTemplateTask()}
                            >
                                Удалить
                            </button>
                            <button id="confirmtask-create"
                                onClick={() => handleChangeTemplateTask()}
                            >
                                Изменить
                            </button>
                        </div>
                    </div>:
                    <div id="div-template-task">
                        <div id="div-with-mini-card-related">
                            {templateTask ? templateTask.map((tt, ttindex) => (
                                <button id="mini-card-detail-task" key={ttindex}
                                    onClick={(event) => handleOpenModalDetailTemplateTask(event, tt)}
                                >
                                    <label id="mini-card-task-title">{tt.title}</label>
                                    <div id="mini-card-div-with-deadline-and-priority">
                                        <label style={{marginLeft: "auto", 
                                                color: tt.priority <= 3 ? 'rgba(100, 120, 214, 1.00)':
                                                    tt.priority <= 7 ? 'rgba(220, 136, 0, 1.00)':
                                                    'rgba(175, 52, 52, 1.00)'
                                            }}
                                        >
                                            &#9733;
                                        </label>
                                    </div>
                                </button>)): null
                            }
                        </div>
                    </div>
                    }
                </Modal>
                <Modal
                    className="modal-add-data-component"
                    isOpen={modalIsOpenSettingsRoles}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Создание роли
                        </label>
                    </div>
                    <div id="up-role-modal-div">
                        <input id="create-name-role"
                            className="board-input"
                            type="text"
                            onChange={handleNameRoleChange}
                            placeholder="Введите наименование роли"
                            maxLength={80}
                        />
                    </div>
                    <div className="div-select-all-and-add-role">
                        <div id="checkbox-with-perm">
                            <div id="name-per">
                                <label id="selected-all-permission">
                                    Выбрать все
                                </label>
                                <label class="checkbox style-e" style={{marginLeft: 'auto'}}>
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruPerm(!selectedAllPermission)}
                                        checked={selectedAllPermission}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div id="div-with-permission-role">
                        {permission.map((pm, permindex) => (
                            <div id="checkbox-with-perm" index={permindex}>
                                <div id="name-per">
                                    <label id="namePerm">
                                        {pm.name}
                                    </label>
                                </div>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruPerm({id: pm.id})}
                                        checked={selectedPerm.includes(pm.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        ))}
                    </div>
                    <button id="confirmtask-create"
                        onClick={handleCreateRole}
                    >
                        Создать
                    </button>
                </Modal>
                <Modal
                    className="modal-add-data-component"
                    isOpen={modalIsOpenSettingsRoles2}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Изменение роли
                        </label>
                    </div>
                    <textarea id="nameRole"
                        ref={textareaRef}
                        value={namerole}
                        onChange={handleNameRoleChange}
                        maxLength={80}
                        placeholder="Введите наименование роли"
                    />
                    <div className="div-select-all-and-add-role">
                        <div id="checkbox-with-perm">
                            <div id="name-per">
                                <label id="selected-all-permission">
                                    Выбрать все
                                </label>
                            </div>
                            <label class="checkbox style-e">
                                <input id="check-group"
                                    type="checkbox"
                                    onChange={() => handleSetTruPerm(!selectedAllPermission)}
                                    checked={selectedAllPermission}
                                />
                                <div class="checkbox__checkmark"></div>
                            </label>
                        </div>
                        {canViewUser ?
                            <button id="open-role-btn"
                                onClick={openModalAllowAccess}
                            >
                                Назначить роль
                            </button>
                        : null}
                    </div>
                    <div id="div-with-permission-role">
                        {permission.map((pm, permindex) => (
                            <div id="checkbox-with-perm" index={permindex}>
                                <div id="name-per">
                                    <label id="namePerm">
                                        {pm.name}
                                    </label>
                                </div>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruPerm({id: pm.id})}
                                        checked={selectedPerm.includes(pm.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        ))}
                    </div>
                    <div style={{display: 'flex'}}>
                        {canDeleteGroup ?
                            <button id="openAccessDelete"
                                onClick={() => handleDeleteRole()}
                            >
                                Удалить
                            </button>: 
                        null}
                        <button id="confirmtask-create"
                            onClick={handleUpdateRole}
                        >
                            Сохранить
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-add-data-component"
                    isOpen={openModalAllow}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Назначение роли
                        </label>
                    </div>
                    <label id="performerlabel-create-task-label">
                        {namerole}
                    </label>
                    <div id='div-with-names-and-checkbox'>
                        {users.slice().sort((a, b) => {
                            const aSelected = selectedUser.includes(a.id);
                            const bSelected = selectedUser.includes(b.id);
                            if (aSelected && !bSelected) return -1;
                            if (!aSelected && bSelected) return 1; 
                            return 0;
                        }).map((us, usindex) => (
                            <div id="checkbox-add-user-board" index={usindex}>
                                <GetOnlineUser user={us.id}/>
                                <img id="image-users"
                                    src={us.image ? us.image : apiUrlFrontend + "person-square.svg"}
                                    alt=''
                                />
                                <div id="name-per">
                                    <label id="nameuser">
                                        {us.formated_fio ? us.formated_fio.slice(0, 20) : us.username.slice(0, 20)}
                                    </label>
                                </div>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruRole({id: us.id, group_id: us.group})}
                                        checked={selectedUser.includes(us.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        ))}
                    </div>
                    <button id="confirmtask-create"
                        onClick={handleUpdateRoleAddUsers}
                    >
                        Сохранить
                    </button>
                </Modal>
                <Modal
                    className="modal-add-data-component"
                    isOpen={openModalUsers}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Пользователи
                        </label>
                    </div>
                    <div id='div-with-names-and-checkbox'>
                        {users.map((us, usindex) => (
                            <div id="checkbox-add-user-board" index={usindex} style={{cursor: 'pointer'}} onClick={() => window.location.href=`/profile/${us.username}`}>
                                <GetOnlineUser user={us.id}/>
                                <img id="image-users"
                                    src={us.image ? us.image : apiUrlFrontend + "person-square.svg"}
                                    alt=''
                                />
                                <div id="name-per">
                                    <label id="nameuser" style={{cursor: 'pointer'}}>
                                        {us.formated_fio ? us.formated_fio.slice(0, 20) : us.username.slice(0, 20)}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal>
                <Modal
                    className="modal-add-data-component"
                    isOpen={createusermodal}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Создание пользователя
                        </label>
                    </div>
                    <form id="create-user-form" onSubmit={(event) => handleCreateUser(event)}>
                        <input
                            type="text"
                            className="board-input"
                            id="create-name-role"
                            value={login}
                            onChange={handleLoginChange}
                            placeholder='Логин *'
                            required
                        />
                        <input
                            type="password"
                            className="board-input"
                            id="create-name-role"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder='Пароль *'
                            required
                        />
                        <input
                            type="text"
                            className="board-input"
                            id="create-name-role"
                            value={nameuser}
                            onChange={handleNameUserChange}
                            placeholder='Имя'
                        />
                        <input
                            type="text"
                            className="board-input"
                            id="create-name-role"
                            value={lastname}
                            onChange={handleLastNameChange}
                            placeholder='Фамилия'
                        />
                        <input
                            type="text"
                            className="board-input"
                            id="create-name-role"
                            value={patronymic}
                            onChange={handlePatronymicChange}
                            placeholder='Отчество'
                        />
                        <input
                            type="text"
                            className="board-input"
                            id="create-name-role"
                            value={emailuser}
                            onChange={handleEmailUserChange}
                            placeholder='Адрес электронной почты *'
                            required
                        />
                        <select id="post-select" onChange={handlePostChange}>
                            <option>Должность *</option>
                            {post.map((group, index) => (
                                <option key={index} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                        <button id="confirmtask-create" type="submit">
                            Создать
                        </button>
                    </form>
                </Modal>
                <Modal
                    className="modal-add-data-component"
                    isOpen={modalIsOpenPost}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Создание должности
                        </label>
                    </div>
                    <form id="create-user-form" onSubmit={(event) => handleCreatePost(event)}>
                        <input
                            type="text"
                            className="board-input"
                            id="create-name-role"
                            value={namepost}
                            onChange={handleNamePostChange}
                            placeholder='Наименование должности *'
                            required
                        />
                        <button id="confirmtask-create" type="submit">
                            Создать
                        </button>
                    </form>
                </Modal>
                <Modal
                    className="modal-add-data-component" 
                    isOpen={modalIsOpenCreateLink}
                    onRequestClose={closeModal}
                    overlayClassName="overlay-add-data-component"
                >
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                        <img
                            className="div-data-component-list-img-return"
                            src={apiUrlFrontend + "arrow-return-right.svg"}
                            alt=''
                            onClick={closeModal}
                        />
                        <img
                            className="div-data-component-list-img"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="div-data-component-list-title">
                            Приглашение в организацию
                        </label>
                    </div>
                    <div id="div-create-link">
                        <div id="div-with-invite-link">
                            <label id="invite-link">
                                {inviteLink}
                            </label>
                            <img id="copy-link"
                                src={apiUrlFrontend + "copy.svg"}
                                alt=''
                                onClick={() => handleCopyInvitelink()}
                            />
                        </div>
                        <label id="date-end-active-link">
                            Текущая ссылка неактивна с {dateEndActiveInviteLink}
                        </label>
                        <div id="div-with-time-active-link">
                            <label id="label-mini-title">
                                Срок действия ссылки:
                            </label>
                            <select id="select-with-days-active-link"
                                onChange={(e) => setCountDaysActivelink(e.target.value)}
                                defaultValue={1}
                            >
                                {templateDaysInviteLinkActive.map((day, dayIndex) => 
                                    <option>{day}</option>
                                )}
                            </select>
                            <label id="label-mini-title">
                                (количество суток)
                            </label>
                        </div>
                        <input 
                            id="login-user-create-link"
                            type="text"
                            className="board-input"
                            placeholder="Введите логин пользователя"
                            maxLength={50}
                            onChange={(e) => setLoginUserInviteOrganization(e.target.value)}
                        />
                        <div id="div-with-time-active-link">
                            <button id="send-link-for-user"
                                onClick={() => handleSendNotificationInviteLink()}
                            >
                                Отправить приглашение
                            </button>
                            <label id="hint-for-send-link-user">
                                Отправляет ссылку приглашение выбранному пользователю, если его учетная запись существует в системе.
                            </label>
                        </div>
                        <div id="div-with-time-active-link">
                            <label id="hint-for-send-link-user">
                                При обновлении ссылки, предыдущая перестанет быть активной.
                            </label>
                            <button id="send-link-for-user"
                                onClick={() => handleUpdateInviteLink()}
                            >
                                Обновить ссылку
                            </button>
                        </div>
                    </div>
                </Modal>
                {role.length > 0 ?
                    <div id="div-with-search">
                        <input id="search-group"
                            placeholder="Начните вводить наименование роли"
                            onChange={handleTextareachange}
                        />            
                    </div>: null
                }
                <label id="hint-for-group">
                    Роль — это набор разрешений на выполнение определенных операций в ТвояЗадача. 
                    Роль назначается на уровне организации и выдается пользователям. 
                    Чтобы просмотреть и настроить роль, нажмите на ее имя в списке.
                    Чтобы назначить роль пользователю нажмите на имя роли в списке, 
                    далее нажмите назначить роль, выберите нужных пользователей.
                </label>
                <div id='div-for-left-btn-in-settings' style={{display: "flex"}}>
                    <div id="div-right-button">
                        <button id="template-settings-button"
                            onClick={(event) => handleOpenTemplateModal(event)}
                        >
                            Шаблон задач
                        </button>    
                        {canAddPost ?
                            <button id="template-settings-button"
                                onClick={() => setIsOpenPost(!modalIsOpenPost)}
                            > 
                                Новая должность
                            </button>: null
                        }
                        {canAddUser && canViewPost ?
                            <button id="template-settings-button"
                                onClick={() => handleOpenModalCreateUser()}
                            > 
                                Новый пользователь
                            </button>: null
                        }
                        {checkPermissionRole ?
                            <button id="template-settings-button"
                                onClick={() => handleOpenModalCreateRole()}
                            > 
                                Новая роль
                            </button>: null
                        }
                        {canInviteUser ?
                            <button id="template-settings-button"
                                onClick={() => handleOpenModalCreateLink()}
                            > 
                                Пригласить пользователя
                            </button>: null
                        }
                        {canViewUser ?
                            <button id="template-settings-button"
                                onClick={(e) => handleOpenModalUsers(e)}
                            > 
                                Просмотр пользователей
                            </button>: null
                        }
                    </div>
                    <div id="div-for-right-btn-in-settings">
                        <div id="div-change-username-or-fio">
                            <label id="change-username-on-fio">
                                Отображать Фамилию И.О.
                            </label>
                            <div id="custom-checkbox-settings">
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeUseUsername(!useUsername)}
                                        checked={useUsername}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        </div> 
                        <div id="div-change-username-or-fio">
                            <label id="change-username-on-fio">
                                Разрешить создавать задачи в статусе выполнено
                            </label>
                            <div id="custom-checkbox-settings">
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeAcceptCreateTaskInStatusCompleted(!acceptCreateTaskInStatusCompleted)}
                                        checked={acceptCreateTaskInStatusCompleted}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        </div>
                        <div style={{display: 'flex', width: '180px'}}>
                            <div id="task-flame-in-days">
                                Считать задачу горящей за
                            </div>
                            <input id="task-flame-in-days-txt"
                                placeholder='3'
                                value={flameDaysTask}
                                onChange={handleChangeFlameDays}
                            />
                            <div id="task-flame-in-days-right">
                                суток
                            </div>
                        </div> 
                    </div>
                    {role.length > 0 ?
                        <div id="div-with-all-element-role">
                            <label id='performerlabel-create-task'>Список ролей</label>
                            <div id='div-for-role-and-desc'>
                            </div>
                            {filteredrole.length > 0 ?
                                filteredrole.map((rl, roleindex) => (
                                    <div id="div-with-role-and-desc" index={roleindex}>
                                        <div id="name-role-and-count-permission">
                                            {canChangeRole ?
                                                <button id="open-settings-role"
                                                    onClick={() => handleOpenInfoForRole({perm_id: rl.permissions, role_name: rl.name_group, role_id: rl.role_id})}
                                                >
                                                    {rl.name_group}
                                                </button>: 
                                                <button id="open-settings-role">
                                                    {rl.name_group}
                                                </button>
                                            }
                                            <label id="count-permission">
                                                Права доступа {rl.permissions.length}
                                            </label>
                                        </div>
                                        {canChangeRole ?
                                            <textarea id="desc-role"
                                                type="text"
                                                placeholder="Добавьте описание"
                                                onChange={handleDescrolechange}
                                                onBlur={() => handleTextAreaDescBlur({role_id: rl.role_id})}
                                            >
                                                {rl.description}
                                            </textarea>: 
                                            <textarea id="desc-role"
                                                type="text"
                                                readOnly='true'
                                                placeholder="Добавьте описание"
                                                value={rl.description}
                                            >
                                            </textarea>
                                        }
                                    </div>
                                )):
                                <div id="not-found-role-label">
                                    Не найдено
                                </div>
                            }
                        </div>: null
                    }
                </div>
            </div>
        </div>
    );
}

export default Settings;