import React, { useState, useEffect, useRef } from 'react';
import './styles/Profile.css';
import api from '/app/frontend/src/api';
import TelegramLoginButton from 'react-telegram-login';
import { Tooltip } from 'react-tooltip';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { format } from 'date-fns';
import GetOnlineUser from './components/CustomOnlineUser';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js';
import { useTheme } from '../ThemeProvider';

const Profile = () => {
    const { resolvedTheme } = useTheme();
    const apiUrl = process.env.REACT_APP_API_URL;
    const YA_CLIENT_ID = process.env.REACT_APP_YA_CLIENT_ID;
    const [firstLoading, setFirstLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [login, setLogin] = useState('');
    const [user, setUser] = useState('');
    const [surname, setUserSurname] = useState('');
    const [name, setUserName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [post, setPost] = useState('');
    const [checkisuser, setCheck] = useState('');
    const [notificationTg, setNotificationTg] = useState();
    const [notificationMail, setNotificationMail] = useState();
    const [checkEdit, setcheckEdit] = useState(false);
    const [editUserTrue, seteditUserTrue] = useState(false);
    const [notThisUser, setNotThisUser] = useState([]);
    const [userid, setuserid] = useState('');
    const [selectedpost, setselectedpost] = useState([]);
    const [canBlockUser, setCanBlockUser] = useState(false);
    const [userpost, setuserpost] = useState([]);
    const [email, setEmail] = useState([]);
    const [notificationTaskMovement, setNotificationTaskMovement] = useState();
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const telegramBotName = process.env.REACT_APP_TELEGRAM_BOT_NAME;
    const [notificationLabelChange, setNotificationLabelChange] = useState();
    const [notificationTitleChange, setNotificationTitleChange] = useState();
    const [notificationDescriptionChange, setNotificationDescriptionChange] = useState();
    const [notificationPriorityChange, setNotificationPriorityChange] = useState();
    const [notificationFileChange, setNotificationFileChange] = useState();
    const [notificationInviteOrganizationChange, setNotificationInviteOrganization] = useState();
    const [notificationAddBoard, setNotificationAddBoard] = useState();
    const [notificationNote, setNotificationNote] = useState();
    const [notificationReminder, setNotificationReminder] = useState();
    const [image, setImage] = useState();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [flameTask, setFlameTask] = useState([]);
    const [completedTask, setComplitedTask] = useState([]);
    const [countCompletedTask, setCountComplitedTask] = useState('');
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const yandexAuthButtonContainerId = 'yandexAuthButtonContainerProfile';
    const YANDEX_CLIENT_ID = process.env.REACT_APP_YA_CLIENT_ID;
    const YANDEX_REDIRECT_URI = 'https://yourtask.ru/yandex_auth_callback.html';
    const YANDEX_TOKEN_PAGE_ORIGIN = 'https://yourtask.ru';
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
    );
    const data = {
        labels: ['', '', '', '', '', '', '', '', '', ''],
        datasets: [{
            data: completedTask,
            backgroundColor: '#14A3B2',
            borderColor: '#14A3B2',
            borderWidth: 1,
            borderRadius: 6,
            borderSkipped: false,
            maxBarThickness: 20,
        }]
    };
    const options = {
      scales: {
        y: {
            ticks: {
                color: resolvedTheme === 'dark' ? '#ffffff': '#333333',
            },
            type: 'linear',
            beginAtZero: true
        }
      }
    };

    useEffect(() => {
        if (user && !user.yandex_id) {
            window.YaAuthSuggest.init(
            {
                client_id: YANDEX_CLIENT_ID,
                response_type: 'token',
                redirect_uri: YANDEX_REDIRECT_URI,
            },
            YANDEX_TOKEN_PAGE_ORIGIN,
            {
                view: 'button',
                parentId: yandexAuthButtonContainerId,
                buttonSize: 'm',
                buttonView: 'main',
                buttonTheme: 'dark',
                buttonBorderRadius: '10',
                buttonIcon: 'ya',
            }
            )
            .then(({ handler }) => handler())
            .then(data => {
                const accessToken = data?.access_token;
                if (accessToken) {
                sendTokenToBackend(accessToken);
                }
            })
            .catch(error => {
                console.error('Ошибка авторизации:', error);
            });
        }
    }, [user]);
  
    const sendTokenToBackend = async (accesstoken) => {
      console.log('Sending token to backend:', accesstoken);
      try {
          await api.patch(`${apiUrl}api/identity/auth/yandex_id/`, {
                access_token: accesstoken,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
              if (response.status === 200) {
                alert("Яндекс ID привязан");
                updateAll();
              }
          }).catch(error =>{
            console.log(error)
            const status = error.response ? error.response.status : null;
            let message = `Ошибка авторизации (код ${status}).`;
            if (status === 404) {
              message = 'Аккаунт с полученными данными не найден. Чтобы авторизоваться через Яндекс ID, привяжите аккаунт в профиле!';
            } else if (error.response.data?.errors?.[0]?.detail) {
                message = `Код ошибки: ${status}, ${error.response.data.errors[0].detail}`;
            } else if (error.response.data?.detail) {
                message = error.response.data.detail;
            }
            alert(message)
          });
      } catch (error) {
        console.error('Failed to send token to backend:', error);
        alert('Ошибка при отправке данных на сервер.');
      }
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }
    
    const base64ToFile = (base64, filename) => {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);
        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleCropImageChanges = async () => {
        try {
            const croppedImageBase64 = await getCroppedImg(image, croppedAreaPixels);
            const imageFile = base64ToFile(croppedImageBase64, `croppedImage_${Date.now()}_${Math.random().toString(36).substr(2, 9)}.jpg`);
            const formData = new FormData();
            formData.append('image', imageFile);
            updatePhotoProfile(formData);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchFilteredData = async () => {
        await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
            overdue: "1"
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setFlameTask(response.data);
            }
        }).catch(error =>{
            console.error(error);
        });
    }

    const handlePostSelect = (event) =>{
        const groups = event.target.value;
        if (groups === "Должность *") {
            // setFilteredTask(task)
        }
        else{
            setselectedpost(groups);
        }
    }
    const handleUserSurnameChange = (event) => {
        setUserSurname(event.target.value);
    };
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };
    const handlePatronymicChange = (event) => {
        setPatronymic(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleLoginChange = (event) => {
        const newValue = event.target.value;
        if (!/[\u0400-\u04FF]/.test(newValue) && !/\s/.test(newValue)) {
            setLogin(newValue);
        }
    };

    function updateAll() {
        const path = window.location.pathname;
        const parts = path.split('/');
        const username = parts[2];
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                if (username !== response.data.username) {
                    api.get(`${apiUrl}api/identity/users/search-by-username/`, {
                        params: {
                            'username': username
                        },
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }).then((response) => {
                        if (response.status === 200) { 
                            setCheck(username);
                            setNotThisUser(response.data[0]);
                            checkIsedituser();
                            setuserid(response.data[0].id);
                            setUserName(response.data[0].first_name);
                            setUserSurname(response.data[0].last_name);
                            setPatronymic(response.data[0].patronymic);
                            setuserpost(response.data[0].post_name);
                            setEmail(response.data[0].email);
                            setLogin(response.data[0].username);
                            setselectedpost(response.data.post);
                            // setFirstLoading(false);
                        }
                    }).catch(error => {
                        if (error.response.status === 404) {
                            alert("Пользователь не найден");
                            window.location.href = '/main';
                        }
                    });
                }
                else {
                    setCheck(username);
                    setUser(response.data);
                    setUserName(response.data.first_name);
                    setUserSurname(response.data.last_name);
                    setEmail(response.data.email);
                    setPatronymic(response.data.patronymic);
                    setNotificationMail(response.data.notification_mail);
                    setNotificationTg(response.data.notification_telegram);
                    setNotificationTaskMovement(response.data.notification_task_movement);
                    setNotificationLabelChange(response.data.notification_label_change);
                    setNotificationTitleChange(response.data.notification_title_task_change);
                    setNotificationDescriptionChange(response.data.notification_description_task_change);
                    setNotificationPriorityChange(response.data.notification_priority_task_change);
                    setNotificationFileChange(response.data.notification_file_change);
                    setNotificationInviteOrganization(response.data.notification_invite_organization);
                    setNotificationNote(response.data.notification_note);
                    setNotificationReminder(response.data.notification_reminder);
                    setNotificationAddBoard(response.data.notification_add_board);
                    setLogin(response.data.username);
                    setselectedpost(response.data.post);
                    // setFirstLoading(false);
                }
            }
        }).catch(error => {
        });
        api.get(`${apiUrl}api/identity/post/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setPost(response.data);
                if (response.data.includes(post)) {
                    setselectedpost(post);
                }
            }
        }).catch(error => {
            console.error(error);
        });
        api.get(`${apiUrl}api/v1/get-statistics/user-detail-profile/`, { 
            params: {
                'username': username
            },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setComplitedTask(Object.values(response.data.dates));
                setCountComplitedTask(response.data.total);
                setFirstLoading(false);
            }
        }).catch(error =>{
            console.error(error);
        });
    }

    React.useEffect(() => {
        updateAll();
        fetchFilteredData();
        // eslint-disable-next-line
    }, []);

    async function checkIsedituser(){
        await api.get(`${apiUrl}api/v1/user/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
          }).then((response) => {
            if (response.status === 200) {
                if (response.data.includes('Can block user')) {
                    setCanBlockUser(true);
                }
                if (response.data.includes('owner_organization')) {
                    seteditUserTrue(true);
                    setCanBlockUser(true);
                }
                if (response.data.includes('Can change Пользователь')) {
                    seteditUserTrue(true);
                }
                else{
                    seteditUserTrue(false);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleSaveChanges = (event) => {
        event.preventDefault()
        if (name === '' || surname === '' || patronymic === '') {
            alert("Заполните все поля");
        }
        else{
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'first_name': name,
                'last_name': surname,
                'patronymic': patronymic,
                'email': email,
                'username': login,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    alert("Данные обновленны");
                    window.location.href = `${login}`;
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (status === 400) {
                    if (error.response.data.errors[0].attr === 'email') {
                        alert(`Код ошибки: ${status}, некорректный адрес электронной почты`, error);
                    }                        
                    else if (error.response.data.errors[0].attr === 'username') {
                        alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    }
                    else {
                        alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    }
                }
            });
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 26214400) {
                alert("Ошибка: Файл должен быть не более 25MB");
            }
            else{
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    function updatePhotoProfile(acceptedFiles) {       
        api.patch(`${apiUrl}api/identity/auth/users/me/`, acceptedFiles, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Фото успешно добавлено");
                setImage();
                updateAll();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationMail = (notification_mail) => {
        setNotificationMail(!notificationMail);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_mail': notification_mail,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationTaskMovement = (notification_task_movement) => {
        setNotificationTaskMovement(!notificationTaskMovement);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_task_movement': notification_task_movement,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationLabelChange = (notification_label_change) => {
        setNotificationLabelChange(!notificationLabelChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_label_change': notification_label_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleGoToFlameTask = (e, task_slug) => {
        window.location.href = `/task/${task_slug}`;
    };
    const handleGoToFlameTaskNewPin = (e, task_slug) => {
        if (e.button === 1) {
          e.preventDefault();
          window.open(`/task/${task_slug}`);
        }
    };

    const handleChangeNotificationFileChange = (notification_file_change) => {
        setNotificationFileChange(!notificationFileChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_file_change': notification_file_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationTitleChange = (notification_title_change) => {
        setNotificationTitleChange(!notificationTitleChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_title_task_change': notification_title_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationDescriptionChange = (notification_description_change) => {
        setNotificationDescriptionChange(!notificationDescriptionChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_description_task_change': notification_description_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationPriorityChange = (notification_priority_change) => {
        setNotificationPriorityChange(!notificationPriorityChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_priority_task_change': notification_priority_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationInviteOrganizationChange = (notification_invite_change) => {
        setNotificationInviteOrganization(!notificationInviteOrganizationChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_invite_organization': notification_invite_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationAddBoard = (notification_add_board) => {
        setNotificationAddBoard(!notificationAddBoard);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_add_board': notification_add_board,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationNote = (notification_note_change) => {
        setNotificationNote(!notificationNote);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_note': notification_note_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationReminder = (notification_reminder_change) => {
        setNotificationReminder(!notificationReminder);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_reminder': notification_reminder_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationTg = (notification_tg) => {
        setNotificationTg(!notificationTg);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_telegram': notification_tg,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const changePassword = (event) => {
        event.preventDefault();
        api.post(`${apiUrl}api/identity/auth/users/reset_password/`, {
            email: user.email
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) { 
                alert("Письмо для смены пароля отправлено на электронную почту. Следуйте указанием из письма.");
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const changePasswordForAdmin = (event) => {
        event.preventDefault();
        api.post(`${apiUrl}api/identity/auth/users/reset_password/`, {
            email: email
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) { 
                alert("Письмо для смены пароля отправлено на электронную почту. Следуйте указанием из письма.")
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleCanEdit = (event) => {
        event.preventDefault();
        setcheckEdit(!checkEdit);
    };

    const handleSaveChangesNotUser = (event) => {
        event.preventDefault();
        if (!email || !selectedpost || !login) {
            alert('Заполните поля');
        }
        api.patch(`${apiUrl}api/identity/users/${userid}/`, {
            'first_name': name,
            'last_name': surname,
            'patronymic': patronymic,
            'post': selectedpost,
            'email': email,
            'username': login,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Успешно обновленно");
                setcheckEdit(false);
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status === 400) {
                if (error.response.data.errors[0].attr === 'email') {
                    alert(`Корректно заполните поле с электронной почтой`);
                }
                else if (error.response.data.errors[0].attr === 'post') {
                    alert(`Код ошибки: ${status}, Выберите должность`, error);
                }
                else if (error.response.data.errors[0].attr === 'username') {
                    alert(`Корректно заполните поле с login пользователя`);
                }
                else {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            }
        });
    };

    const handleTelegramUnconnect = (event) => {
        event.preventDefault();
        api.patch(`${apiUrl}api/identity/users/${user.id}/`, {
            user_telegram_id: null
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Успешно обновленно");
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleTelegramResponse = (response) => {
        if (user.id) {
            api.get(`${apiUrl}api/identity/users/${user.id}/connection-telegram/`, {
                params:response,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.status === 200) {
                    alert("Телеграм id успешно привязан");
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            });
        }
    };

    const handleChangeUserAccess = (e) => {
        e.preventDefault();
        api.get(`${apiUrl}api/identity/users/${notThisUser.id}/delete-from-organization/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Пользователь больше не имеет доступа к вашей организации");
                window.location.href = '/main';
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status === 403 && error.response.data.errors[0].detail === 'Unable to block organization owner') {
                alert(`Код ошибки: ${status}, Невозможно заблокировать владельца организации.`, error);
            }
            else {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleYandexUnconnect = (data) => {
        api.patch(`${apiUrl}api/identity/auth/yandex_id/`, {
            "unconnect_yandex_id": true
        }).then(response => {
            alert("Яндекс ID отвязан");
            updateAll();
        })
        .catch(error => {
            const status = error.response ? error.response.status : null;
            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
        });
    };

    document.title = `Профиль ${user.username ? user.username: notThisUser.username} - ТвояЗадача`;

    if (firstLoading) {
        return (
            <div className="main-profile">
            <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <span class="loader"></span>
                </div>
            </div>
        )
    }

    return (
            <div className="main-profile">
                <img style={{width: '100%', height: '400px', position: 'absolute', left: 0, top: 0, objectFit: 'cover'}}
                    src={resolvedTheme === 'light' ? apiUrlFrontend + 'background-profile-light-1.png': apiUrlFrontend + 'background-profile-1.png'}
                    alt=''
                />
                <div className="left-column-profile">
                    {image ?
                        <div id="cropped-image">
                            {image && (
                                <Cropper
                                    image={image}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    aspect={1}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            )}
                        </div>: null
                    }
                    {image ?
                        <button id="save-changes-cropp-photo" onClick={handleCropImageChanges}>
                            Обрезать
                        </button>: null
                    }
                    {!image ?
                        <img id="photoprofile"
                            src={user.username === checkisuser ? user.image ? user.image : apiUrlFrontend + "person-square.svg": notThisUser.image ? notThisUser.image: apiUrlFrontend + "person-square.svg"}
                            alt=''
                        />
                    : null}
                    {!image ?
                        <label id="mainusername"><GetOnlineUser user={user.username === checkisuser ? user.id: notThisUser.id}/>{user.username === checkisuser ? user.username: notThisUser.username}</label>
                    : null}
                    <input
                        readOnly={(editUserTrue || user.username === checkisuser) && checkEdit ? false: true}
                        type="text"
                        id="profile-template"
                        value={surname}
                        onChange={(editUserTrue || user.username === checkisuser) && checkEdit ? handleUserSurnameChange: null}
                        placeholder="Фамилия"
                    />
                    <input
                        readOnly={(editUserTrue || user.username === checkisuser) && checkEdit ? false: true}
                        type="text"
                        id="profile-template"
                        value={name}
                        onChange={(editUserTrue || user.username === checkisuser) && checkEdit ? handleUserNameChange: null}
                        placeholder="Имя"
                    />
                    <input
                        readOnly={(editUserTrue || user.username === checkisuser) && checkEdit ? false: true}
                        type="text"
                        id="profile-template"
                        value={patronymic}
                        onChange={(editUserTrue || user.username === checkisuser) && checkEdit ? handlePatronymicChange: null}
                        placeholder="Отчество"
                    />
                    <input
                        readOnly={(editUserTrue || user.username === checkisuser) && checkEdit ? false: true}
                        type="text"
                        id="profile-template"
                        value={email}
                        onChange={(editUserTrue || user.username === checkisuser) && checkEdit ? handleEmailChange: null}
                        placeholder="Электронная почта"
                    />
                    <input
                        readOnly={(editUserTrue || user.username === checkisuser) && checkEdit ? false: true}
                        type="text"
                        id="profile-template"
                        value={login}
                        onChange={(editUserTrue || user.username === checkisuser) && checkEdit ? handleLoginChange: null}
                        placeholder="Логин"
                    />
                    {checkEdit === false ?
                        <input 
                            id="profile-template"
                            readOnly={true}
                            type="text"
                            defaultValue={user.username === checkisuser ? user.post_name: notThisUser.post_name}
                            placeholder="Логин"
                        />:
                        <select id="post-select-change" onChange={handlePostSelect}>
                            <option>Должность *</option>
                            {post.map((group, index) => (
                                <option key={index} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                        
                    }
                    <input 
                        id="profile-template"
                        readOnly={true}
                        type="text"
                        defaultValue={user.username === checkisuser ? 
                            user.available_organization?.find(org => org.id === user.organization).name
                            :notThisUser.available_organization?.find(org => org.id === notThisUser.organization).name
                        }
                        placeholder="Организация"
                    />
                </div>
                <div className="center-column-profile">
                    <div className="center-column-first-row">
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <label id="title-profile-statistics">Статистика</label>
                            <div id="div-profile-statistics">
                                <label id="label-count-statistics">Количество выполненных задач</label>
                                <label id="label-count-data-statistics">{countCompletedTask}</label>
                            </div>
                        </div>
                        <Bar data={data} options={options} />
                    </div>
                    <div className="center-column-second-row">
                        <label id="title-profile-task">Просроченные задачи</label>
                        {flameTask.length > 0 ?
                            <div id="div-with-mini-card-bottom">
                                {flameTask.map((ts, tsindex) => (
                                    <button id="mini-card-detail-task" key={tsindex}
                                        onClick={(e) => handleGoToFlameTask(e, ts.task_slug)}
                                        onMouseDown={(e) => handleGoToFlameTaskNewPin(e, ts.task_slug)}
                                    >
                                        <label id="mini-card-task-title">
                                            {ts.title}
                                        </label>
                                        <div id="mini-card-div-with-deadline-and-priority">
                                            {ts.deadline ?
                                                <label id="mini-card-task-deadline">
                                                    {format(new Date(ts.deadline), 'dd.MM.yyyy')}
                                                </label>: null
                                            }
                                            <label style={{marginLeft: "auto", 
                                                    color: ts.priority <= 3 ? 'rgba(100, 120, 214, 1.00)':
                                                        ts.priority <= 7 ? 'rgba(220, 136, 0, 1.00)':
                                                        'rgba(175, 52, 52, 1.00)'
                                                }}
                                            >
                                                &#9733;
                                            </label>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        :<label style={{alignSelf: 'center', marginTop: "12vh"}}>Задач нет</label>}
                    </div>
                </div> 
                <div className="right-column-profile">
                    {user.username === checkisuser ?
                        <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление в телеграм
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationTg(!notificationTg)}
                                        checked={notificationTg}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление на почту
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationMail(!notificationMail)}
                                        checked={notificationMail}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о движении задач
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationTaskMovement(!notificationTaskMovement)}
                                        checked={notificationTaskMovement}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о назначении/смене меток
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationLabelChange(!notificationLabelChange)}
                                        checked={notificationLabelChange}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о назначении/смене файлов
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationFileChange(!notificationFileChange)}
                                        checked={notificationFileChange}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомления о заметках
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationNote(!notificationNote)}
                                        checked={notificationNote}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомления о напоминаниях
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationReminder(!notificationReminder)}
                                        checked={notificationReminder}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о изменении заголовка задачи
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationTitleChange(!notificationTitleChange)}
                                        checked={notificationTitleChange}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о изменении описания задачи
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationDescriptionChange(!notificationDescriptionChange)}
                                        checked={notificationDescriptionChange}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о изменении приоритета задачи
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationPriorityChange(!notificationPriorityChange)}
                                        checked={notificationPriorityChange}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о приглашении в организацию
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationInviteOrganizationChange(!notificationInviteOrganizationChange)}
                                        checked={notificationInviteOrganizationChange}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                            <div className="div-notification-checkbox">
                                <label id="notification">
                                    Получать уведомление о добавлении на доску
                                </label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeNotificationAddBoard(!notificationAddBoard)}
                                        checked={notificationAddBoard}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                        </div>
                    : null}
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {user.username === checkisuser ?
                            <label className="input-file">
                                <input id="add-photo-profile" type="file" accept="image/*" onChange={handleImageChange}/>            
                                <span id="input-file-text">Сменить фото</span>
                            </label>
                        : null}
                        {editUserTrue || user.username === checkisuser ? 
                            checkEdit === true ?
                                <button id="save-profile" onClick={user.username === checkisuser ? (event) => handleSaveChanges(event): (event) => handleSaveChangesNotUser(event)}>
                                    Сохранить
                                </button>
                            :<button id="edit-profile" onClick={(event) => handleCanEdit(event)}>
                                Редактировать
                            </button>
                        : null}
                        <button id="changeuserpassword" onClick={user.username === checkisuser ? (event) => changePassword(event): (event) => changePasswordForAdmin(event)}>
                            Сменить пароль
                        </button>
                        <label id="hintforpassword">
                            {user.username === checkisuser ? 'После смены пароля потребуется снова войти в аккаунт': 'Отправляет письмо пользователю на почту с предложением сменить пароль'}
                        </label>
                        {canBlockUser ?
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '5px'}}>
                                <button id="changeuserpassword" onClick={(e) => handleChangeUserAccess(e)}>
                                    Заблокировать пользователя
                                </button>
                                <label id="hintforpassword">
                                    Для разблокировки требуется снова пригласить пользователя в организацию
                                </label>
                            </div>: null
                        }
                        {user.username === checkisuser ?
                            user.user_telegram_id ? 
                                <button  id="btn-unconnect-tg" onClick={(e) => handleTelegramUnconnect(e)}>
                                    Отвязать телеграм
                                </button>:
                                <div id="div-tg-btn-connect"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Привязать телеграм"
                                >
                                    <TelegramLoginButton className="custom-tg-button" usePic={false} dataOnauth={handleTelegramResponse} botName={telegramBotName}/>
                                </div>
                        : null}
                        {user.username === checkisuser && !user.yandex_id ? (
                            <div id={yandexAuthButtonContainerId}></div>
                        ): 
                        <button id="btn-unconnect-tg" onClick={(e) => handleYandexUnconnect(e)}>
                            Отвязать Яндекс ID
                        </button>}
                    </div>
                </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
    );
}

export default Profile;