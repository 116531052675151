import React, { useState, useEffect } from 'react';

function TaskTimeInsideStatus({ time }) {
  const [timeMs, setTimeMs] = useState(0);

  useEffect(() => {
    if (time) {
      setTimeMs(parseTimeToMs(time));
    }
  }, [time]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeMs(prevMs => prevMs + 60000);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  return (
    <label>
      {formatMsToTime(timeMs)}
    </label>
  );
}

function parseTimeToMs(time) {
  const timeString = typeof time === 'string' ? time : time.toString();
  const parts = timeString.split(':').map(Number);
  let ms = 0;
  if (parts.length === 2) {
    const [hours, minutes] = parts;
    ms = ((hours * 60) + minutes) * 60 * 1000;
  } else if (parts.length === 3) {
    const [hours, minutes, seconds] = parts;
    ms = ((hours * 60 + minutes) * 60 + seconds) * 1000;
  }
  return ms;
}

function formatMsToTime(ms) {
  const totalSeconds = Math.floor(ms / 1000);
  const totalHours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  if (totalHours < 24) {
    return `${String(totalHours).padStart(2, '0')}ч ${String(minutes).padStart(2, '0')}м`;
  } else {
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    return `${days}д ${String(hours).padStart(2, '0')}ч ${String(minutes).padStart(2, '0')}м`;
  }
}

export default TaskTimeInsideStatus;
