import LoginPage from './pages/LoginPage';
import HelloPage from './pages/HelloPage';
import Main from './pages/MainPage';
import DetailBoard from './pages/DetailBoardPage'
import Profile from './pages/ProfilePage';
import AboutMe from './pages/pages_for_footer/AboutMePage'
import CreateAppeal from './pages/pages_for_footer/CreateAppealPage'
import PrivacyPolicy from './pages/pages_for_footer/PrivacyPolicyPage'
import DevelopersInfo from './pages/pages_for_header/DevelopersInfoPage'
import PricesInfo from './pages/pages_for_header/PricesInfoPage'
import SolutionsInfo from './pages/pages_for_header/SolutionsInfoPage'
import TasksList from './pages/TasksListPage';
import DetailTask from './pages/DetailTaskPage';
import CreateTask from './pages/CreateTaskPage';
import Settings from './pages/SettingsPage';
import ChangePassword from './pages/ChangePassword';
import ChoiceOrganization from './pages/ChoiceOrganizationPage';
import Registration from './pages/RegistrationPage';
import InfoMain from './pages/InfoMainPage';
import InviteOrganization from './pages/InviteOrganization';

function NoMatch() {
    return (
      <div style={{ padding: 200, textAlign: 'center' }}>
        <h2>404: Page Not Found</h2>
        <p>Такой страницы нет, куда ты тыкаешь!</p>
      </div>
    );
}

const routes = [
  { path: '/', element: <HelloPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/main', element: <Main /> },
  { path: '/developers-info', element: <DevelopersInfo /> },
  { path: '/prices-info', element: <PricesInfo /> },
  { path: '/solutions-info', element: <SolutionsInfo /> },
  { path: '/about', element: <AboutMe /> },
  { path: '/create-appeal', element: <CreateAppeal /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '*', element: <NoMatch /> },
  { path: '/boards/:slug', element: <DetailBoard /> },
  { path: '/profile/:slug', element: <Profile /> },
  { path: '/tasks/list/', element: <TasksList /> },
  { path: '/task/:task_slug', element: <DetailTask /> },
  { path: '/create-task', element: <CreateTask /> },
  { path: '/settings', element: <Settings /> },
  { path: '/change-password/:slug/:slug', element: <ChangePassword /> },
  { path: '/choice-organization', element: <ChoiceOrganization /> },
  { path: '/registration', element: <Registration /> },
  { path: '/main-info', element: <InfoMain /> },
  { path: '/invite-organization/:int/:slug/', element: <InviteOrganization /> },
];

export default routes;