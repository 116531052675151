import React, { useState, useRef, useEffect } from 'react';
import { gantt } from 'dhtmlx-gantt';
import '../styles/GanttCustom.css'
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import api from '/app/frontend/src/api';
import { format, parseISO, addDays } from 'date-fns';

const Gant = () => {
    const token = localStorage.getItem('token');
    const ganttContainer = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    let isDoubleClickHandled = false;

    const convertToUTC = (dateTimeStr) => {
        const isoStr = dateTimeStr.replace(' ', 'T') + ':00';
        const utcDateTime = new Date(isoStr).toISOString();
        return utcDateTime;
    };

    useEffect(() => {
        gantt.plugins({
            drag_timeline: true,
            quick_info: true,
        });
        gantt.i18n.setLocale("ru", {
            date: {
                month_full: [
                    "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                    "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
                ],
                month_short: [
                    "Янв", "Фев", "Мар", "Апр", "Май", "Июн",
                    "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"
                ],
                day_full: [
                    "Воскресенье", "Понедельник", "Вторник", "Среда",
                    "Четверг", "Пятница", "Суббота"
                ],
                day_short: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
            },
            labels: {
                new_task: "Новая задача",
                icon_save: "Сохранить",
                icon_cancel: "Отмена",
                icon_details: "Детали",
                icon_edit: "Редактировать",
                icon_delete: "Удалить",
                confirm_closing: "Ваши изменения будут потеряны. Вы уверены?",
                confirm_deleting: "Задача будет удалена без возможности восстановления. Вы уверены?",
                section_description: "Описание",
                section_time: "Период времени",
                section_type: "Тип",
            }
        });
        gantt.i18n.setLocale("ru");
        gantt.config.xml_date = "%d-%m-%Y %H:%i:%s";
        gantt.config.columns = [
            { name: "text", label: "Задача", width: 220, tree: true },
            { name: "start_date", label: "Дата начала", align: "center", width: 110 },
            { name: "end_date", label: "Дата сдачи", align: "center", width: 110 },
        ];
        gantt.config.scroll_on_click = true;
        gantt.config.drag_timeline = true;
        gantt.config.wheel_scroll = true;
        gantt.config.show_progress = false;
        gantt.config.scale_height = 50
        gantt.config.row_height = 45; 
        gantt.config.lightbox_enable_time = true;
        const isFirefox = navigator.userAgent.includes("Firefox");
        const isChrome = navigator.userAgent.includes("Chrome");
        if (isFirefox) {
            gantt.config.wheel_scroll_sensitivity = {
                y: 0.05,
            };
        } else if (isChrome) {
            gantt.config.wheel_scroll_sensitivity = {
                y: 0.5,
            };
        }
        gantt.config.readonly = false;
        gantt.config.quick_info_detached = true;
        gantt.templates.quick_info_content = function (start, end, task) {
            return `
                <div>
                    <strong>Номер:</strong> ${task.id}
                    <p><strong>Группа:</strong> ${task.group}</p>
                    <p><strong>Исполнитель:</strong> ${task.performer}</p>
                    <p><strong>Описание:</strong> ${task.description}</p>
                </div>
            `;
        };
        setScale("month");
        gantt.templates.task_class = function (start, end, task) {
            if (task.priority < 4) {
                return "priority-low";
            } else if (task.priority < 8) {
                return "priority-medium";
            } else if (task.priority < 11) {
                return "priority-high";
            }
            return "";
        };
        gantt.init(ganttContainer.current);

        const loadTasks = async () => {
            try {
                const data = await api.get(`${apiUrl}api/v1/tasks/`, {
                    params: {
                        'slugboard': window.location.pathname.replace(/\/$/, '').split('/').pop()
                    },
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
                const ganttData = {
                    data: transformTasks(data.data),
                    links: transformLinks(data.data),
                };    
                gantt.parse(ganttData);
            } catch (error) {
                console.error('Error loading tasks:', error);
            }
        };

        gantt.attachEvent("onLinkCreated", async (link) => {
            if ((gantt.getTask(link.source).type === gantt.config.types.project) 
                || (gantt.getTask(link.target).type === gantt.config.types.project)) {
                return false;
            }
            else {
                try {
                    const data = await api.get(`${apiUrl}api/v1/tasks/${link.target}/`, {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        },
                    });
                    const targetTaskId = link.target;
                    const sourceTaskId = link.source;
                    const updatedPredecessors = [
                        ...data.data.predecessors, sourceTaskId
                    ];
                    await api.patch(`${apiUrl}api/v1/tasks/${targetTaskId}/`, {
                        predecessors: updatedPredecessors,
                    }, {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        },
                    });
                    // gantt.clearAll();
                    console.log(`Связь успешно создана на сервере`);
                } catch (error) {
                    console.error(`Ошибка при создании связи:`, error);
                }
            }
        });

        gantt.attachEvent("onBeforeTaskDelete", function(id, item) {
            const task = gantt.getTask(id);
            if (task.type === gantt.config.types.project || task.noninteractive) {
                return false;
            }
            return true;
        });

        gantt.attachEvent("onBeforeTaskDrag", function(id, mode, e) {
            const task = gantt.getTask(id);
            if (task.type === gantt.config.types.project) {
                return false;
            }
            return true;
        });

        gantt.attachEvent("onAfterLinkDelete", async (id, link) => {
            try {
                const data = await api.get(`${apiUrl}api/v1/tasks/${link.target}/`, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
                const targetTaskId = link.target;
                const sourceTaskId = link.source;
                
                const updatedPredecessors = data.data.predecessors.filter(
                    (predecessorId) => predecessorId !== sourceTaskId
                );

                await api.patch(`${apiUrl}api/v1/tasks/${targetTaskId}/`, {
                    predecessors: updatedPredecessors,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
                
                console.log(`Связь успешно удалена на сервере`);
            } catch (error) {
                console.error(`Ошибка при создании связи:`, error);
            }
        });

        gantt.attachEvent("onBeforeLinkAdd", function(id, link) {
            const sourceTask = gantt.getTask(link.source);
            const targetTask = gantt.getTask(link.target);
            
            if ((sourceTask.type === gantt.config.types.project) || (targetTask.type === gantt.config.types.project)) {
                return false;
            }
            return true;
        });

        gantt.attachEvent("onAfterTaskUpdate", async (id, task) => {
            try {
                await api.patch(`${apiUrl}api/v1/tasks/${id}/`, {
                    deadline: convertToUTC(format(new Date(task.end_date), 'yyyy-MM-dd HH:mm')),
                    date_start_task: convertToUTC(format(new Date(task.start_date), 'yyyy-MM-dd HH:mm')),
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
                console.log(`Задача ${id} успешно обновлена на сервере`);
            } catch (error) {
                console.error(`Ошибка при обновлении задачи ${id}:`, error);
            }
        });

        gantt.attachEvent("onAfterTaskDelete", async (id, task) => {
            try {
                await api.delete(`${apiUrl}api/v1/tasks/${id}/`,{
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
                console.log(`Задача успешно удалена на сервере`);
            } catch (error) {
                console.error(`Ошибка при создании связи:`, error);
            }
        });

        gantt.attachEvent("onTaskDblClick", function(id, e) {
            if(gantt.getTask(id).type === gantt.config.types.project){
                return false;
            }
            else {
                if (isDoubleClickHandled) {
                    return;
                }
                isDoubleClickHandled = true;
                window.open(`/task/${id}`);
                setTimeout(() => {
                    isDoubleClickHandled = false;
                }, 300);
            }
        });

        gantt.attachEvent("onQuickInfo", function (id) {
            setTimeout(() => {
                const editBtn = document.querySelector(".gantt_qi_big_icon.icon_edit.dhx_gantt_icon_edit");
                if (editBtn) {
                    editBtn.onclick = function (event) {
                        event.preventDefault();
                        event.stopPropagation();
                        editTask(id);
                        return false;
                    };
                }
            }, 100);
        });

        loadTasks();
    }, []);

    
    function editTask(id) {
        if (id.startsWith("group_")) {
            alert("Нет возможности отредактировать группу")
        }
        else {
            window.location.href = `/task/${id}`;
        }
    }

    const transformTasks = (tasks) => {
        const groups = {};
        tasks.forEach(task => {
            const groupName = task.group_name || 'Без группы';
            if (!groups[groupName]) {
                groups[groupName] = [];
            }
            groups[groupName].push(task);
        });
        
        const ganttTasks = [];
        let groupIndex = 1;
        Object.keys(groups).forEach(groupName => {
            const groupTasks = groups[groupName];
            const startDates = groupTasks
                .filter(task => task.date_start_task)
                .map(task => parseISO(task.date_start_task));
            const endDates = groupTasks
                .filter(task => task.deadline)
                .map(task => parseISO(task.deadline));

            let groupStartDate, groupEndDate;
            if (startDates.length) {
                groupStartDate = new Date(Math.min(...startDates));
            } else {
                groupStartDate = new Date();
            }
            if (endDates.length) {
                groupEndDate = new Date(Math.max(...endDates));
            } else {
                groupEndDate = new Date();
            }
            
            const groupId = `group_${groupIndex++}`;
            ganttTasks.push({
                id: groupId,
                text: groupName,
                type: gantt.config.types.project,
                open: true,
                start_date: format(groupStartDate, "dd-MM-yyyy hh:mm"),
                end_date: format(groupEndDate, "dd-MM-yyyy hh:mm"),
                description: "Не применимо",
                performer: "Не применимо",
                group: "Не применимо",
                priority: 1,
            });
            groupTasks.forEach(task => {
                if (task.deadline) {
                    ganttTasks.push({
                        id: task.task_slug,
                        text: task.title,
                        start_date: task.date_start_task
                            ? new Date(task.date_start_task).toLocaleString().replace(', ', ' ').replace(/\./g, "-")
                            : task.deadline ? new Date(task.deadline).toLocaleString().replace(', ', ' ').replace(/\./g, "-"): "",
                        end_date: task.date_start_task ?
                            new Date(task.deadline) > new Date(task.date_start_task)
                            ? new Date(task.deadline).toLocaleString().replace(', ', ' ').replace(/\./g, "-")
                            : addDays(new Date(task.deadline), 1).toLocaleString().replace(', ', ' ').replace(/\./g, "-"): "",
                        parent: groupId,
                        priority: task.priority || 1,
                        description: task.description || "Не назначено",
                        group: task.group_name,
                        performer: task.performer || "Не назначено",
                    });
                }
            });
        });
        return ganttTasks;
    };

    const transformLinks = (tasks) => {
        const links = [];
        console.log(links)
        tasks.forEach(task => {
            task.predecessors.forEach(predecessorId => {
                links.push({
                    id: `${task.task_id}-${predecessorId}`,
                    source: predecessorId,
                    target: task.task_id,
                    type: "0",
                });
            });
        });
        return links;
    };
    const setScale = (unit) => {
        gantt.config.scales = getScales(unit);
        gantt.render();
    };
    const getScales = (unit) => {
        switch (unit) {
            case "day":
                return [
                    { unit: "day", step: 1, format: "%d %M" },
                    { unit: "hour", step: 1, format: "%H:%i" }
                ];
            case "week":
                return [
                    { unit: "week", step: 1, format: "Нед %W" },
                    { unit: "day", step: 1, format: "%d %M" },
                ];
            case "month":
                return [
                    { unit: "month", step: 1, format: "%F, %Y" },
                    { unit: "week", step: 1, format: "Нед %W" },
                ];
            default:
                return [
                    { unit: "month", step: 1, format: "%F, %Y" },
                ];
        }
    };

    return (
        <div>
            <div id="gantt-change-calendar-zoom">
                <button id="gantt-set-days-btn" onClick={() => setScale("day")}>Дни</button>
                <button id="gantt-set-weeks-btn" onClick={() => setScale("week")}>Недели</button>
                <button id="gantt-set-months-btn" onClick={() => setScale("month")}>Месяцы</button>
            </div>
            <div className="gantt_container">
                <div ref={ganttContainer} style={{ width: '100%', height: '100%' }}/>
            </div>
        </div>
    );
};

export default Gant;