import React, { useState, useEffect } from 'react';
import './styles/ChoiceOrganization.css';
import api from '/app/frontend/src/api';
import { useLocation } from 'react-router-dom';

const ChoiceOrganization = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const token = localStorage.getItem('token');
    const [user, setUser] = useState([]);
    const [user_id, setUser_id] = useState('');
    var screenHeight = window.innerHeight;

    const handleClickChangeOrganization = (organization) => {
        api.patch(`${apiUrl}api/identity/users/${user_id}/update-organization/`, {
            'organization': organization
        },{
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.detail === 'Organization updated successfully.') {
                    window.location.href = '/main';
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const updateAll = () => {
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            if (response.status === 200) {
                setUser(response.data.available_organization || []);
                setUser_id(response.data.id);
                if (response.data.available_organization.length === 1 && location.pathname.startsWith('/choice-organization')) {
                    window.location.href = '/main';
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    useEffect(() => {
        updateAll();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="choice-organization-page">
            {location.pathname.startsWith('/choice-organization') ?
                <label id="label-choice-organization">
                    Выберите организацию
                </label>:
            null}
            {user.length > 0 ? user.map((organization, index) => (
                <button id="button-choice-organization" key={index} onClick={() => handleClickChangeOrganization(organization.id)}>
                    {organization.name}
                </button>
            )) : null}
        </div>
    );
}

export default ChoiceOrganization;