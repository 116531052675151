import React, { useState } from 'react';
import api from '/app/frontend/src/api';

const UserFile = ({ url, task, updateall, task_slug, getFile, deleteFile}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    
    const handleGetFile = (data, namefile) => {
        //это чтобы сразу файлы скачивать а по ссылке не переходить
        // fetch(data.file, {
        //     method: 'HEAD',
        //     headers: {
        //         'Authorization': 'Bearer ' + token
        //     }
        // })
        // .then(response => {
        //     if (response.status === 401) {
        //         api.get(`${apiUrl}api/v1/tasks/${task_id}/`, {
        //             headers: {
        //                 'Authorization': 'Bearer ' + token
        //             }
        //         }).then((response) => {
        //             if (response.status === 200){
        //                 if (response.data.file) {
        //                     window.location.href = response.data.file.find(item => item.id === data.id).file;
        //                 } 
        //             }
        //         }).catch(error => {
        //             console.error(error);
        //             const status = error.response ? error.response.status: null;
        //             if (error.response.status === 403) {
        //                 alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
        //                 window.location.href = '/main';
        //             }
        //         });
        //     } else if (response.status === 200) {
        //         window.location.href = data.file;
        //     }
        // })
        // .catch(error => {
        //     console.error('Ошибка запроса:', error);
        // });
        api.get(`${apiUrl}api/v1/tasks/${task_slug}/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.file) {
                    window.location.href = response.data.file.find(item => item.id === data.id).file;
                } 
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleGetFileOnMouseDown = (e, data) => {
        if (e.button === 1) {
            e.preventDefault();
            api.get(`${apiUrl}api/v1/tasks/${task_slug}/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.file) {
                        window.open(response.data.file.find(item => item.id === data.id).file);
                    } 
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleGetFileOnClickFile = (e, data) => {
        e.preventDefault();
        api.get(`${apiUrl}api/v1/tasks/${task_slug}/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.file) {
                    window.open(response.data.file.find(item => item.id === data.id).file);
                } 
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDeleteFile = (data) => {
        const result = window.confirm("Файл будет полностью удален из системы.");
        if (result) {
            api.delete(`${apiUrl}api/v1/files/${data}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    updateall();
                }
            })
            .catch(error =>{
                console.error(error);
            });
        }
    };

    const [isHovered, setIsHovered] = useState(false);
    if (url === null) {}
    else {
        const fileParts = url.split('?')[0].split(".");
        const fileExtension = fileParts[fileParts.length - 1];
        if (fileExtension === 'apng' || fileExtension === 'avif' 
            || fileExtension === 'gif' || fileExtension === 'bmp'
            || fileExtension === 'jpeg' || fileExtension === 'ico'
            || fileExtension === 'webp' || fileExtension === 'jpg'
            || fileExtension === 'png') {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <img id="imagefile"
                        src={url}
                        alt=""
                    />
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ?
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id);
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'doc' || fileExtension === 'docx'){
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="doc-extension-file">
                        <label id="doc-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ?
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id);
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'abw' || fileExtension === 'azw' 
            || fileExtension === 'odt' || fileExtension === 'rtf'
            || fileExtension === 'txt'){
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="txt-extension-file">
                        <label id="txt-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ?
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id)
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'pdf') {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="pdf-extension-file">
                        <label id="pdf-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ?
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id);
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'ppt' || fileExtension === 'pptx' || fileExtension === 'odp') {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="ppt-extension-file">
                        <label id="ppt-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ?
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id);
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'ods') {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="xls-extension-file">
                        <label id="xls-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ? 
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id);
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'bz' || fileExtension === 'bz2'
            || fileExtension === 'gz' || fileExtension === 'jar'
            || fileExtension === 'rar' || fileExtension === 'tar'
            || fileExtension === 'zip' || fileExtension === '7z') {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="zip-extension-file">
                        <label id="zip-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered && (
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ? 
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id)
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else if (fileExtension === 'aac' || fileExtension === 'cda'
            || fileExtension === 'midi' || fileExtension === 'mid'
            || fileExtension === 'mp3' || fileExtension === 'oga'
            || fileExtension === 'opus' || fileExtension === 'wav'
            || fileExtension === 'weba' || fileExtension === '3g2'
            || fileExtension === '3gp' || fileExtension === 'mpga') {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="txt-extension-file">
                        <label id="txt-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered &&(
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ? 
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id)
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
        else {
            return (
                <div id="div-for-files"
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleGetFileOnClickFile(e, task);
                    }}
                >
                    <div id="txt-extension-file">
                        <label id="txt-type-extension-div">
                            {fileExtension.slice(0, 4)}
                        </label>
                        <label id="file-name-for-user">
                            {decodeURI(url.split('?')[0].split('/media/uploads/')[1])}
                        </label>
                    </div>
                    {isHovered &&(
                        <div id="div-delete-get-file">
                            <button id="get-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGetFile(task, decodeURI(url.split('?')[0].split('/media/uploads/')[1]));
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleGetFileOnMouseDown(e, task);
                                }}
                            />
                            {deleteFile ? 
                                <button id="delete-file-task"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(task.id);
                                    }}
                                />
                            : null}
                        </div>
                    )}
                </div>
            )
        }
    }
};

export default UserFile;