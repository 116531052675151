import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import TelegramLoginButton from 'react-telegram-login';
import './styles/Login.css';

const LoginPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const telegramBotName = process.env.REACT_APP_TELEGRAM_BOT_NAME;
  const yandexAuthButtonContainerId = 'yandexAuthButtonContainer';
  const hasInitialized = useRef(false);
  const YANDEX_CLIENT_ID = process.env.REACT_APP_YA_CLIENT_ID;
  const YANDEX_REDIRECT_URI = 'https://yourtask.ru/yandex_auth_callback.html';
  const YANDEX_TOKEN_PAGE_ORIGIN = 'https://yourtask.ru';

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  useEffect(() => {
      window.YaAuthSuggest.init(
      {
        client_id: YANDEX_CLIENT_ID,
        response_type: 'token',
        redirect_uri: YANDEX_REDIRECT_URI,
      },
      YANDEX_TOKEN_PAGE_ORIGIN,
      {
        view: 'button',
        parentId: yandexAuthButtonContainerId,
        buttonSize: 'm',
        buttonView: 'main',
        buttonTheme: 'dark',
        buttonBorderRadius: '10',
        buttonIcon: 'ya',
      }
      )
      .then(({ handler }) => handler())
      .then(data => {
          const accessToken = data?.access_token;
          if (accessToken) {
            sendTokenToBackend(accessToken);
          }
      })
      .catch(error => {
          console.error('Ошибка авторизации:', error);
      });
  }, []);

  const sendTokenToBackend = async (token) => {
    console.log('Sending token to backend:', token);
    try {
        await axios.post(`${apiUrl}api/identity/auth/yandex_id/`, {
          access_token: token,
        }).then((response) => {
            if (response.status === 200) {
              localStorage.setItem('token', response.data.access_token);
              localStorage.setItem('refreshToken', response.data.refresh_token);
              window.location.href = "/choice-organization";
            }
        }).catch(error =>{
          console.log(error)
          const status = error.response ? error.response.status : null;
          let message = `Ошибка авторизации (код ${status}).`;
          if (status === 404) {
            message = 'Аккаунт с полученными данными не найден. Чтобы авторизоваться через Яндекс ID, привяжите аккаунт в профиле!';
          } else if (error.response.data?.errors?.[0]?.detail) {
              message = `Код ошибки: ${status}, ${error.response.data.errors[0].detail}`;
          } else if (error.response.data?.detail) {
              message = error.response.data.detail;
          }
          alert(message)
        });
    } catch (error) {
      console.error('Failed to send token to backend:', error);
      alert('Ошибка при отправке данных на сервер.');
    }
  };

  const handleTelegramResponse = response => {
    axios.get(`${apiUrl}api/`, {
      params:response 
    }).then(response => {
      if (response.status === 200){
        const tokenJson = response.data;
        if (tokenJson.access_token && tokenJson.refresh_token) {
          localStorage.setItem('token', tokenJson.access_token);
          localStorage.setItem('refreshToken', tokenJson.refresh_token);
          window.location.href = "/choice-organization";
        }
        else {
          alert("Ваш аккаунт не найден, обратитесь к администратору вашей организации.");  
        }
      }
      else{
        alert("Ваш аккаунт не найден, обратитесь к администратору вашей организации.");
      }
    }).catch(error => {
      const status = error.response ? error.response.status : null;
      alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${apiUrl}api/identity/auth/jwt/create/`, {
      username: username,
      password: password
    })
    .then(response => {
      const { access } = response.data;
      localStorage.setItem('token', access);
      localStorage.setItem('refreshToken', response.data.refresh);
      window.location.href = "/choice-organization";
    })
    .catch(error =>{
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        alert('Вы ввели неверный логин или пароль!');
      } else {
        alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
      }
    });
  };

  document.title = "Вход - ТвояЗадача";

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit} className="login-form">
        <h1>Авторизация</h1>
        <div className="form-group-login">
          <input
            type="text"
            id="username-txt"
            value={username}
            onChange={handleUsernameChange}
            placeholder='Логин' 
            required
          />
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder='Пароль'
            required
          />
        </div>
        <button id="login" type="submit">Войти</button>
        <div id="div-tg-btn">
          <TelegramLoginButton id="tgbutton" buttonSize="medium" dataOnauth={handleTelegramResponse} botName={telegramBotName}/>
        </div>
        <div id={yandexAuthButtonContainerId}></div>
      </form>
    </div>
  );
};

export default LoginPage;