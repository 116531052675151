import React, { useState } from 'react';

const CustomPrioritySelect = ({ options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="custom-priority-select">
            <div
                className="selected-priority-option" 
                onClick={() => setIsOpen(!isOpen)}
            >
                {value || 'Не назначен'}
            </div>
            {isOpen && (
                <div className="priority-options"
                >
                    {options.map(option => (
                        <div 
                            key={option.value}
                            className="priority-option"
                            onClick={() => {
                                onChange(option.value);
                                setIsOpen(false);
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomPrioritySelect;