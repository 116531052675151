import React, { useState, useCallback, useEffect, useRef} from 'react';
import './styles/CreateTask.css';
import axios from "axios";
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { useDropzone } from 'react-dropzone';
import CKeditor5 from './components/CKeditor5';
import api from '/app/frontend/src/api';
import './styles/cked.css';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';
import GetOnlineUser from './components/CustomOnlineUser';
import { useSelector, useDispatch } from 'react-redux';
import { closeModalCreateTaskPage } from '../redux/modalSlice';
import CustomPrioritySelect from './components/CustomPrioritySelect';

const CreateTask = () => {
    const dispatch = useDispatch();
    const dateStartCreateTask = useSelector((state) => state.modal.dateStartCreateTask);
    const deadlineCreateTask = useSelector((state) => state.modal.deadlineCreateTask);
    const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;
    const [loading, setLoading] = useState(false);
    const timerRef = useRef(null);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const slugboards = localStorage.getItem('slugboards');
    const grouptaskcreate = localStorage.getItem('grouptaskcreate');
    const group_system_status = localStorage.getItem('grouptasksystemstatus');
    const grouptaskidcreate = localStorage.getItem('grouptaskidcreate');
    const related_task_id = localStorage.getItem('relatedtaskid');
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    const [totalStars] = useState(10);
    const [descriptiontask, setDescriptionTask] = useState('');
    const [titletask, setTitleTask] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenResponsible, setIsOpenModalResponsible] = useState(false);
    const [modalIsOpenLabel, setIsOpenLabel] = useState(false);
    const [modalIsOpenGetLabel, setIsOpenGetLabel] = useState(false);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [modalIsOpenCalendarStartDate, setIsOpenCalendarStartDate] = useState(false);
    const [modalIsOpenCalendarreminder, setIsOpenCalendarreminder] = useState(false);
    const [performer, setperformer] = useState([]);
    const [responsible, setResponsible] = useState([]);
    const [label, setLabel] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [selectedResponsibles, setSelectedResponsibles] = useState([]);
    const [namelabel, setNameLabel] = useState('');
    const [files, setFiles] = useState([]);
    const [filesinfo, setFilesinfo] = useState([]);
    const [deadline, setDeadline] = useState();
    const [startdate, setStartdate] = useState();
    const [reminderdate, setreminderdate] = useState();
    const [hhTime, setHHTime] = useState('00');
    const [mmTime, setMMTime] = useState('00');
    const [hhTimereminder, setHHTimereminder] = useState('00');
    const [mmTimereminder, setMMTimereminder] = useState('00');
    const [hhTimeDeadline, setHHTimeDeadline] = useState('18');
    const [mmTimeDeadline, setMMTimeDeadline] = useState('00');
    const handleChangeHHTime = (event) => {
      setHHTime(event.target.value);
    };
    const handleChangeMMTime = (event) => {
      setMMTime(event.target.value);
    };
    const handleChangeHHTimereminder = (event) => {
        setHHTimereminder(event.target.value);
    };
    const handleChangeMMTimereminder = (event) => {
        setMMTimereminder(event.target.value);
    };
    const handleChangeHHTimeDeadline = (event) => {
        setHHTimeDeadline(event.target.value);
    };
    const handleChangeMMTimeDeadline = (event) => {
        setMMTimeDeadline(event.target.value);
    };
    const priorityOptions = Array.from({ length: 10 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`
      }));
    const webcolors = [
        "#FF0000", "#FF7F50", "#FFD700", "#ADFF2F",
        "#32CD32", "#20B2AA", "#00CED1", "#4682B4",
        "#1E90FF", "#6A5ACD", "#9370DB", "#FF1493",
        "#A9A9A9", "#FFFFFF", "#000000", "#808080",
    ];

    useEffect(() => {
        if (dateStartCreateTask) {
            handleUpdateTaskStart(dateStartCreateTask)
            setHHTime(String(new Date(dateStartCreateTask).getHours()).padStart(2, '0'));
            setMMTime(String(new Date(dateStartCreateTask).getMinutes()).padStart(2, '0'));
        }
        if (deadlineCreateTask) {
            handleUpdateTaskDeadline(deadlineCreateTask)
            setHHTimeDeadline(String(new Date(deadlineCreateTask).getHours()).padStart(2, '0'));
            setMMTimeDeadline(String(new Date(deadlineCreateTask).getMinutes()).padStart(2, '0'));
        }
    }, [dateStartCreateTask, deadlineCreateTask]);

    const namedColors = {
        red: "#FF0000",
        coralex: "#FF7F50",
        gold: "#FFD700",
        greenyellow: "#ADFF2F",
        limegreen: "#32CD32",
        lightseagreen: "#20B2AA",
        darkturquoise: "#00CED1",
        steelblue: "#4682B4",
        dodgerblue: "#1E90FF",
        slateblue: "#6A5ACD",
        mediumpurple: "#9370DB",
        deeppink: "#FF1493",
        darkgray: "#A9A9A9",
        white: "#FFFFFF",
        black: "#000000",
        gray: "#808080"
    };


    const getHexColor = (color) => {
        if (color[0] === "#") return color;
        return namedColors[color.toLowerCase()] || color;
    };
      
    const [selectedColor, setSelectedColor] = useState('');
    const [modalIsOpenTaskTemplate, setModalIsOpenTaskTemplate] = useState(false);
    const [templatestasks, settemplatestasks] = useState([]);
    const [filtertemplatestasks, setfiltertemplatestasks] = useState([]);
    const [value, onChangeCalendar] = useState(new Date());
    const [valueStart, onChangeCalendarStart] = useState(new Date());
    const [valueReminder, onChangeCalendarReminder] = useState(new Date());
    const handleUpdateTaskDeadline = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        setDeadline(formattedDate);
        closeModal();
    };

    const handleUpdateTaskStart = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        setStartdate(formattedDate);
        closeModal();
    };

    const handleUpdateTaskReminder = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        setreminderdate(formattedDate);
        closeModal();
    };

    useEffect(() => {
        if (grouptaskcreate !== '') {
        } 
        else {
            window.location.href = '/main'
        }
        // eslint-disable-next-line
    }, []);

    function closeModal() {
        setIsOpen(false);
        setIsOpenLabel(false);
        setIsOpenGetLabel(false);
        setIsOpenCalendar(false);
        setIsOpenCalendarStartDate(false);
        setModalIsOpenTaskTemplate(false);
        setIsOpenModalResponsible(false);
        setIsOpenCalendarreminder(false);
    };

    function openModalCalendar() {
        closeModal();
        setIsOpenCalendar(true);
    };

    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
        const rawData = window.atob(base64);
        return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
    }
    function getsogl() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.getRegistration().then(function (registration) {
                if (registration && registration.pushManager) {
                    console.log('Сервисный работник уже зарегистрирован. Подписка не требуется.');
                    return;
                }
                return Notification.requestPermission().then(function (permissionResult) {
                    if (permissionResult !== 'granted') {
                        throw new Error('Permission not granted.');
                    }
                    return navigator.serviceWorker.register('/service-worker.js');
                }).then(function (registration) {
                    return navigator.serviceWorker.ready;
                }).then(function (registration) {
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlB64ToUint8Array(publicVapidKey)
                    });
                }).then(function (subscription) {
                    console.log("Подписка завершена, отправляю данные.");
                    const subscriptionData = {
                        endpoint: subscription.endpoint,
                        keys: {
                            p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                            auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))
                        }
                    };
                    return api.post(`${apiUrl}api/subscribe/`, subscriptionData, {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    });
                }).then((response) => {
                    if (response.status === 201) {
                        console.log('Успешная подписка на уведомления.');
                    }
                }).catch(error => {
                    console.error('Ошибка во время подписки:', error);
                });
            }).catch(error => {
                console.error('Ошибка получения регистрации сервисного работника:', error);
            });
        } else {
            console.log("Push-уведомления или Service Worker не поддерживаются в этом браузере.");
        }
    }

    function openModalCalendarreminder() {
        getsogl();
        closeModal();
        setIsOpenCalendarreminder(true);
    };

    function openModalCalendarStartDate() {
        closeModal();
        setIsOpenCalendarStartDate(true);
    };

    function openModal() {
        closeModal();
        fetchAllUsers(1, [], false);
    };

    function openModalResponsible() {
        closeModal();
        fetchAllUsers(1, [], true);
    };

    function fetchAllUsers(page = 1, allUsers = [], responsible = false) {
        if (responsible) {
            api.get(`${apiUrl}api/identity/users/`, {
                params: { 
                    page: page,
                    board_id: slugboards,
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedUsers = response.data;
                    const updatedUsersList = allUsers.concat(fetchedUsers);
                    if (response.data.next) {
                        fetchAllUsers(page + 1, updatedUsersList);
                    } else {
                        setResponsible(updatedUsersList);
                        setIsOpenModalResponsible(true);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else{
            api.get(`${apiUrl}api/identity/users/`, {
                params: { 
                    page: page,
                    board_id: slugboards,
                    group_id: grouptaskidcreate
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedUsers = response.data;
                    const updatedUsersList = allUsers.concat(fetchedUsers);
                    if (response.data.next) {
                        fetchAllUsers(page + 1, updatedUsersList);
                    } else {
                        setperformer(updatedUsersList);
                        setIsOpen(true);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };
    const textareaRef = useRef(null);
    const autoResize = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    function openModalLabel() {
        closeModal();
        setIsOpenLabel(true);
    };

    function openModalGetLabel() {
        closeModal();
        getlabel();
        setIsOpenGetLabel(true);
    };

    function getlabel() {
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status && status === 403) {
                alert(`Код ошибки: ${status},${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleCreateLabel = (event) => {
        event.preventDefault();
        if (label.some(labeli => labeli.name === namelabel)) {
            alert("Метка с таким названием уже существует");
        }
        else {
            api.post(`${apiUrl}api/v1/labels/`, {
                'name': namelabel,
                'color': selectedColor
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    closeModal()
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleChangeNameLabel = (event) => {
        setNameLabel(event.target.value);
    };

    const handleCheckboxChangeResponsibles = (performer) => {
        if (selectedResponsibles.includes(performer)) {
            setSelectedResponsibles(selectedResponsibles.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedResponsibles([...selectedResponsibles, performer]);
        }
    };

    const handleCheckboxChangePerformer = (performer) => {
        if (selectedperformers.includes(performer)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedPerformers([...selectedperformers, performer]);
        }
    };

    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
        } else {
            setSelectedLabels([...selectedlabels, label]);
        }
    };

    const handleOpenProfileClick = (user) => {
        window.location.href = "/profile/" + user;
    };

    const convertToUTC = (date, time) => {
        const localDateTime = new Date(`${date}T${time}:00`);
        const utcDateTime = new Date(localDateTime).toISOString();
        return utcDateTime;
    };

    const handlecreateTask = (event) => {
        event.preventDefault();
        const utcDateTime = startdate ? convertToUTC(format(new Date(startdate), 'yyyy-MM-dd'), `${hhTime}:${mmTime}`): null;
        const utcDateTimeDeadline = deadline ? convertToUTC(format(new Date(deadline), 'yyyy-MM-dd'), `${hhTimeDeadline}:${mmTimeDeadline}`): null;
        const utcDateTimereminder = reminderdate ? convertToUTC(format(new Date(reminderdate), 'yyyy-MM-dd'), `${hhTimereminder}:${mmTimereminder}`): null; 
        if (titletask === ""){
            alert("Укажите заголовок задачи!");
        }
        else{
            setLoading(true);
            const refreshToken = localStorage.getItem('refreshToken');
            axios.post(`${apiUrl}api/identity/auth/jwt/refresh/`, {
                'refresh': refreshToken
            },{
                headers: {
                    'Authorization': 'Bearer ' + refreshToken
                },
            }).then((response) => {
                const newToken = response.data.access;
                localStorage.setItem('token', response.data.access);
                if (newToken) {
                    const subtasksArray = [];
                    if (related_task_id !== '') {
                        subtasksArray.push(related_task_id);
                    }
                    const filterforperformer = performer.filter((performer) => selectedperformers.includes(performer.username));
                    const performerid = filterforperformer.map(performer => performer.id);
                    const filterforresponsible = responsible.filter((performer) => selectedResponsibles.includes(performer.username));
                    const responsibleid = filterforresponsible.map(performer => performer.id);
                    api.post(`${apiUrl}api/v1/tasks/`, {
                        'title': titletask,
                        'deadline': utcDateTimeDeadline,
                        'date_start_task': utcDateTime,
                        'group': grouptaskidcreate,
                        'priority': rating,
                        'description': descriptiontask,
                        'label': selectedlabels,
                        'performer': performerid,
                        'system_status': group_system_status,
                        'subtasks': subtasksArray,
                        'responsible': responsibleid,
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + newToken
                        },
                    }
                    ).then((response) => {
                    if (response.status === 201) {
                        localStorage.setItem('relatedtaskid', '');
                        closeModal();
                        setSelectedLabels([]);
                        setSelectedPerformers([]);
                        setSelectedResponsibles([]);
                        setTitleTask("");
                        setDeadline("");
                        setStartdate("");
                        setRating("");
                        if (response.data.task_slug) {
                            api.post(`${apiUrl}api/v1/tasks/${response.data.task_slug}/reminders/`, {
                                'date_time_send_notification': utcDateTimereminder,
                            },{
                                headers: {
                                    'Authorization': 'Bearer ' + token
                                }
                            }).then((response) => {
                                if (response.status === 201){
                                    if (files.length === 0) {
                                        localStorage.setItem('grouptaskcreate', '');
                                        if (!window.location.pathname.startsWith('/boards/')) {
                                            window.location.href = `/boards/${slugboards}/`;
                                        }
                                        else{
                                            dispatch(closeModalCreateTaskPage())
                                        }
                                    }
                                }
                            }).catch(error => {
                                console.error(error);
                            });
                            if (files.length !== 0) {
                                handleUpload({task_slug: response.data.task_slug});
                            }
                            else {
                                localStorage.setItem('grouptaskcreate', '');
                                if (!window.location.pathname.startsWith('/boards/')) {
                                    window.location.href = `/boards/${slugboards}/`;
                                }
                                else{
                                    dispatch(closeModalCreateTaskPage())
                                }
                            }
                        }
                        else {
                            localStorage.setItem('grouptaskcreate', '');
                            if (!window.location.pathname.startsWith('/boards/')) {
                                window.location.href = `/boards/${slugboards}/`;
                            }
                            else{
                                dispatch(closeModalCreateTaskPage())
                            }
                        }
                    }
                    }).catch(error => {
                        setLoading(false);
                        const status = error.response ? error.response.status : null;
                        if (status && status === 403 && error.response.data.errors[0].detail === 'NotAllowCreateTaskInCompletedStatus') {
                            alert(`Код ошибки: ${status}, В вашей организации запрещено создавать задачи в статусе выполнено`, error);
                        }
                    }); 
            }})
            .catch(error => {
                console.error(error);
            });
    }};

    const onDropRejected = (rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors[0].code === 'file-too-large') {
                alert('Ошибка: Файл должен быть не более 25MB');
            } else {
                alert('Произошла ошибка при загрузке файла: ' + file.errors[0].message);
            }
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        const updateFiles = [...filesinfo, ...acceptedFiles];
        setFilesinfo(updateFiles);
        setFiles(updateFiles.map((file) => file));
    }, [filesinfo]);

    const {getRootProps, getInputProps,} = useDropzone({onDrop, onDropRejected, maxSize: 26214400,});
    
    function handleUpload(data) {
        if (files) {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('file', file);
            });
            api.post(`${apiUrl}api/upload-file/`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(response => {
                if (response.status === 201) {
                    AddFileToTask({task_slug: data.task_slug, file_id: response.data.map(file => file.id)});
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
        else {
            localStorage.setItem('grouptaskcreate', '');
            if (!window.location.pathname.startsWith('/boards/')) {
                window.location.href = `/boards/${slugboards}/`;
            }
            else{
                dispatch(closeModalCreateTaskPage())
            }
        }
    };

    function AddFileToTask (data){
        api.patch(`${apiUrl}api/v1/tasks/${data.task_slug}/`, {
            'file': data.file_id,
            'is_initial': true,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        ).then((response) => {
        if (response.status === 200) { 
            localStorage.setItem('grouptaskcreate', '');
            if (!window.location.pathname.startsWith('/boards/')) {
                window.location.href = `/boards/${slugboards}/`;
            }
            else{
                dispatch(closeModalCreateTaskPage())
            }
        }
        }).catch(error => {
            console.error(error);
        });
        console.log("И файлы добавились");
    };
    
    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        URL.revokeObjectURL(file.preview);
        const newfilesinfo = [...filesinfo];
        newfilesinfo.splice(newfilesinfo.indexOf(file), 1);
        setFilesinfo(newfilesinfo);
    };

    const handleTitleTaskChange = (event) => {
        const lines = event.target.value.split('\n').length;
        if (lines <= 6) {
            setTitleTask(event.target.value);
            autoResize();
        }
    };

    const handleEditorChange = (data) => {
        setDescriptionTask(data);
    };

    const handleOpenTemplateTask = () => {
        api.get(`${apiUrl}api/v1/template-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                settemplatestasks(response.data);
                setfiltertemplatestasks(response.data);
                setModalIsOpenTaskTemplate(true);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status && status === 403) {
                alert(`Код ошибки: ${status},${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleSearch = (searchitem) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (searchitem.length >= 1) {
                const regex = new RegExp(searchitem, "i");
                const filtered = filtertemplatestasks.filter((task) => regex.test(task.title));
                setfiltertemplatestasks(filtered);
            } 
            else if (searchitem.length < 1) {
                setfiltertemplatestasks(templatestasks);
            }
        }, 500);
    };

    const handleCreateTaskFromTemplate = (data) => {
        getlabel();
        fetchAllUsers();
        setTitleTask(data.title);
        setDescriptionTask(data.description);
        setRating(data.priority);
        if (data.label) {
            setSelectedLabels(data.label.split(',').map(label => parseInt(label)));
        }
        setSelectedPerformers(data.performer.split(','));
        closeModal();
    };
    
    function transparentColor(hex, opacity) {
        hex = getHexColor(hex)
        if (hex[0] === "#") {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            let rgb = result ? [
                parseInt(result[1], 16),
                parseInt(result[2], 16),
                parseInt(result[3], 16)
            ] : null;
    
            return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
        }
        else {
            return `rgba(255, 255, 255, ${opacity})`
        }
    }

    document.title = "Создание задачи - ТвояЗадача";

    if (loading) {
        return (
            <div style={{minWidth: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", display: "flex"}}>
                <span class="loader"></span>
            </div>
        )
    }

    return (
        <div className='create-task-page'>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenTaskTemplate}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "template-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Выбор шаблона
                    </label>
                </div>        
                <input id="search-input-subtasks-div"
                    placeholder="Введите текст, чтобы найти шаблон задачи"
                    onChange={(event) => handleSearch(event.target.value)}
                />
                <div id="scroll-subtask" style={{backgroundColor: 'none', borderRadius: '0px'}}>
                    {filtertemplatestasks.map((tt, ttindex) => (
                        <button id="btn-with-labels-name-task" key={ttindex}
                            onClick={() => handleCreateTaskFromTemplate(tt)}
                        >
                            {tt.title}
                        </button>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenGetLabel}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                shouldCloseOnOverlayClick={false}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "label-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Метка
                    </label>
                </div>
                <div className="div-data-component-list">
                    {label.slice()
                        .sort((a, b) => {
                        const aSelected = selectedlabels.includes(a.id);
                        const bSelected = selectedlabels.includes(b.id);
                        return bSelected - aSelected;
                        }).map((lb, labelindex) => (
                        <label className="data-component-add" key={labelindex}
                            style={{ 
                                background: selectedlabels.includes(lb.id) ? 'var(--btn-elenemt-checkbox-active-color)': 'var(--btn-elenemt-checkbox-color)', padding: '10px 15px 10px 15px',
                            }}
                        >
                            <label id="name-labels">
                                <input
                                    type="checkbox"
                                    name="performers"
                                    onChange={() => handleCheckboxChangeLabel(lb.id)}
                                    checked={selectedlabels.includes(lb.id)}
                                />
                                <span style={{marginLeft: "10px", color: lb.color, filter: 'brightness(2)'}}>
                                    {lb.name}
                                </span>
                            </label>
                        </label>
                    ))}
                </div>
                <button id="confirmtask-create"
                    onClick={openModalLabel}
                >
                    Создать
                </button>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "performer-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Исполнитель
                    </label>
                </div>
                <div className="div-data-component-list">
                    {performer.slice()
                        .sort((a, b) => {
                        const aSelected = selectedperformers.includes(a.username);
                        const bSelected = selectedperformers.includes(b.username);
                        return bSelected - aSelected;
                        }).map((pf, pfindex) => (
                        <label className="data-component-add" key={pfindex}
                            style={{ 
                                background: selectedperformers.includes(pf.username) ? 'var(--btn-elenemt-checkbox-active-color)': 'var(--btn-elenemt-checkbox-color)', padding: '10px 15px 10px 15px',
                            }}
                        >
                            <GetOnlineUser user={pf.id}/>
                            <img id="component-task-image-performer"
                                src= {pf.image ? pf.image: apiUrlFrontend + "person-square.svg" }
                                alt=''
                            />
                            <input
                                type="checkbox"
                                name="performers"
                                onChange={() => handleCheckboxChangePerformer(pf.username)}
                                checked={selectedperformers.includes(pf.username)}
                            />
                            <span style={{marginLeft: '10px', color: 'white', fontFamily: 'Roboto, sans-serif'}}>
                                {pf.formated_fio ?
                                    pf.formated_fio:
                                    pf.username
                                }
                            </span>
                        </label>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenResponsible}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "responsible-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Ответственный
                    </label>
                </div>
                <div className="div-data-component-list">
                    {responsible.slice()
                        .sort((a, b) => {
                        const aSelected = selectedResponsibles.includes(a.username);
                        const bSelected = selectedResponsibles.includes(b.username);
                        return bSelected - aSelected;
                        }).map((pf, pfindex) => (
                        <label className="data-component-add" key={pfindex}
                            style={{ 
                                background: selectedResponsibles.includes(pf.username) ? 'var(--btn-elenemt-checkbox-active-color)': 'var(--btn-elenemt-checkbox-color)', padding: '10px 15px 10px 15px',
                            }}
                        >  
                            <GetOnlineUser user={pf.id}/>
                            <img id="component-task-image-performer"
                                src= {pf.image ? pf.image: apiUrlFrontend + "person-square.svg" }
                                alt=''
                            />
                            <input
                                type="checkbox"
                                name="performers"
                                onChange={() => handleCheckboxChangeResponsibles(pf.username)}
                                checked={selectedResponsibles.includes(pf.username)}
                            />
                            <span style={{marginLeft: '10px', color: 'white', fontFamily: 'Roboto, sans-serif'}}>
                                {pf.formated_fio ?
                                    pf.formated_fio:
                                    pf.username
                                }
                            </span>
                        </label>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenLabel}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "label-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">Создание метки</label>
                </div>
                <label className="hint-new-label">Название</label>
                <input id="new-name-label"
                    placeholder="Введите название метки"
                    onChange={handleChangeNameLabel}
                />
                <label className="hint-new-label">Цвет</label>
                <div style={{ width: '240px', display: 'flex', flexWrap: 'wrap', borderRadius: "10px", background: "none", justifyContent: 'center'}}>
                    {webcolors.map((color, index) => (
                        <div key={index} style={{border: selectedColor === color ? '2px Solid white': 'none', borderRadius: '15px'}}
                            onClick={() => setSelectedColor(color)}
                        >
                            <div style={{ backgroundColor: color,
                                height: selectedColor === color ? '26px': '30px',
                                margin: '4px', cursor: 'pointer',
                                borderRadius: '10px',
                                width: selectedColor === color ? '46px': '50px'
                            }}/>
                        </div>
                    ))}
                </div>
                <button id="create-label"
                    onClick={handleCreateLabel}
                >
                    Создать
                </button>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            > 
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <select className="custom-select-time" value={hhTimeDeadline} onChange={handleChangeHHTimeDeadline}>
                            {[...Array(24).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                            </select>
                            <label className="custom-label-time">:</label>
                            <select className="custom-select-time" value={mmTimeDeadline} onChange={handleChangeMMTimeDeadline}>
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="div-calendar">
                    <Calendar onChange={(date) => {
                        onChangeCalendar(date);
                        handleUpdateTaskDeadline(date);
                    }} 
                        value={value}
                    />
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component" 
                isOpen={modalIsOpenCalendarStartDate}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <select className="custom-select-time" value={hhTime} onChange={handleChangeHHTime}>
                            {[...Array(24).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                            </select>
                            <label className="custom-label-time">:</label>
                            <select className="custom-select-time" value={mmTime} onChange={handleChangeMMTime}>
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="div-calendar"> 
                    <Calendar onChange={(date) => {
                        onChangeCalendarStart(date);
                        handleUpdateTaskStart(date);
                    }} 
                        value={valueStart}
                    />
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component" 
                isOpen={modalIsOpenCalendarreminder}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <select className="custom-select-time" value={hhTimereminder} onChange={handleChangeHHTimereminder}>
                            {[...Array(24).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                            </select>
                            <label className="custom-label-time">:</label>
                            <select className="custom-select-time" value={mmTimereminder} onChange={handleChangeMMTimereminder}>
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="div-calendar"> 
                    <Calendar onChange={(date) => {
                        onChangeCalendarReminder(date);
                        handleUpdateTaskReminder(date);
                    }} 
                        value={reminderdate}
                    />
                </div>
            </Modal>
            <div className="left-column-task-create">
                <div style={{display: 'flex', width: '100%'}}>
                    <textarea
                        id="title-task"
                        placeholder="Введите заголовок задачи"
                        required
                        maxLength={250}
                        value={titletask}
                        ref={textareaRef}
                        onChange={handleTitleTaskChange}
                    />
                    <button className="btn-open-change-data"
                        onClick={handleOpenTemplateTask}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Выбрать задачу из шаблона"
                    >
                        <img 
                            id="img-header-task"
                            src={apiUrlFrontend + "template-icon.svg"}
                            alt=''
                        />
                        <span>Шаблоны</span>
                    </button>
                </div>
            </div>
            <div className="right-column-task-create">
                <div style={{display: 'flex', alignItems: 'center', height: '43px'}}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "group-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">
                        Состояние
                    </label>
                    <label className="label-data-component-task">
                        {grouptaskcreate === null ? "" : grouptaskcreate.slice(0, 12)}
                    </label>
                </div>
                <div className="data-component-task-div" onClick={openModalCalendarStartDate}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "deadline-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Срок начала</label>
                    <div className="div-data-component-task">
                        <label className="label-data-component-task">
                            {startdate ? `${startdate} ${hhTime}:${mmTime}` : "Не назначен"}
                        </label>
                    </div>
                </div>
                <div className="data-component-task-div" onClick={openModalCalendar}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "deadline-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Срок сдачи</label>
                    <div className="div-data-component-task">
                        <label className="label-data-component-task">
                            {deadline ? `${deadline} ${hhTimeDeadline}:${mmTimeDeadline}` : "Не назначен"}
                        </label>
                    </div>
                </div>
                <div className="data-component-task-div" onClick={() => openModal()} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "performer-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Исполнитель</label>
                    <div className="div-data-component-task">
                        {selectedperformers.length > 0 ?
                            performer.filter(pf => selectedperformers.includes(pf.username)).map((k, kindex) => (
                                <div className="component-task-user" key={kindex}>
                                    <img id="component-task-image-performer"
                                        src={k.image ? k.image : apiUrlFrontend + "person-square.svg"}
                                        alt=''
                                    />
                                    <button id="component-task-open-profile-performer"
                                        onClick={() => handleOpenProfileClick(k.username)}
                                    >
                                        {k.formated_fio ? k.formated_fio: k.username}
                                    </button>
                                </div>
                            )): 
                            <label className="label-data-component-task">
                                Не назначен
                            </label>
                        }
                    </div>
                </div>
                <div className="data-component-task-div" onClick={() => openModalResponsible()}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "responsible-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Ответственный</label>
                    <div className="div-data-component-task">
                        {selectedResponsibles.length > 0 ?
                            responsible.filter(rs => selectedResponsibles.includes(rs.username)).map((k, kindex) => (
                                <div className="component-task-user" key={kindex}>
                                    <img id="component-task-image-performer"
                                        src={k.image ? k.image : apiUrlFrontend + "person-square.svg"}
                                        alt=''
                                    />
                                    <button id="component-task-open-profile-performer"
                                        onClick={() => handleOpenProfileClick(k.username)}
                                    >
                                        {k.formated_fio ? k.formated_fio: k.username}
                                    </button>
                                </div>
                            )):
                            <label className="label-data-component-task">
                                Не назначен
                            </label>
                        }
                    </div>
                </div>                          
                <div className="data-component-task-div" onClick={openModalGetLabel}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "label-icon.svg"}
                        alt=""
                    />
                    <label className="label-component-task">Метка</label>
                    <div className="div-data-component-task">
                        {selectedlabels.length > 0 ?
                            label.filter((lb) => selectedlabels.includes(lb.id)).map((lb, lbindex) => (
                                <div className="component-task-user" key={lbindex} style={{background: transparentColor(lb.color, 0.2), padding: '10px 15px 10px 15px'}}>
                                    <label className="label-name-component-task" style={{color: lb.color, filter: 'brightness(2)'}}>{lb.name}</label>
                                </div>
                            )):
                            <label className="label-data-component-task">
                                Не назначена
                            </label>
                        }
                    </div>
                </div>
                <div className="data-component-task-div"  onClick={openModalCalendarreminder}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "reminder-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Напоминание</label>
                    <div className="div-data-component-task">
                        <label className="label-data-component-task">
                            {reminderdate ? `${reminderdate} ${hhTimereminder}:${mmTimereminder}` : "Не назначено"}
                        </label>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', height: '43px'}}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "priority-icon.svg"}
                        alt=""
                    />
                    <label className="label-component-task">Приоритет</label>
                    <div className="div-priority">
                        {[...Array(totalStars)].map((_, index) => {
                            const currentRating = index + 1;
                            return (
                                <label key={index}>
                                    <input key={currentRating}
                                        type="radio"
                                        name="rating"
                                        value={currentRating}
                                        onChange={() => setRating(currentRating)}
                                    />
                                    <div className="rating-item"
                                        style={{ color: currentRating <= (hover || rating) ? "var(--big-priority-text-selected-color)" : "var(--big-priority-text-color)",
                                            background: currentRating <= (hover || rating) ? "var(--big-priority-selected-color)" : "var(--big-priority-color)"}}
                                            onMouseEnter={() => setHover(currentRating)}
                                            onMouseLeave={() => setHover(null)}
                                        >
                                        {currentRating}
                                    </div>
                                </label>
                            );
                        })}
                    </div>
                    <CustomPrioritySelect 
                        options={priorityOptions}
                        value={rating}
                        onChange={setRating}
                    />
                </div>
            </div>
            <div className="left-column-task-create">
                <CKeditor5
                    onEditorChange={(data) => handleEditorChange(data)}
                    editorDisabled={true}
                    descriptiontask={descriptiontask}
                />
                <div id="div-with-place-file" {...getRootProps()}>
                    <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                        {filesinfo.length > 0 ?
                            <div id="div-task-file">
                            {filesinfo.map((file) => (
                                <div id="file-info-create" key={file.name}>
                                    {file.name}
                                    <button id="delete-file" onClick={(e) => {
                                            e.stopPropagation();
                                            removeFile(file);
                                        }}>
                                        <img id="btn-del-file" 
                                            src={apiUrlFrontend + 'close-btn.svg'}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            ))}
                            </div>
                        : <label id="label-place-file">Выберите или перетащите файлы</label>}
                        <img
                            id="add-files-icon"
                            src={apiUrlFrontend + 'plus-btn.svg'}
                            alt=""
                        />
                    </div>
                    <input id="place-file-here" {...getInputProps()}/>
                </div>
            </div>
            <div className="left-column-task-create">
                <button id="confirmtask-create"
                    onClick={handlecreateTask}
                >
                    Создать
                </button>
            </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
    );
}

export default CreateTask;