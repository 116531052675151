import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        openModalCreateTask: false,
        openModalDetailTask: false,
        dateStartCreateTask: null,
        deadlineCreateTask: null,
        modalTaskId: null,
    },
    reducers: {
        openModalCreateTaskPage: (state) => {
            state.openModalCreateTask = true;
        },
        closeModalCreateTaskPage: (state) => {
            state.openModalCreateTask = false;
        },
        openModalDetailTaskPage: (state) => {
            if (state.openModalDetailTask === false) {
                state.openModalDetailTask = true;
            }
        },
        closeModalDetailTaskPage: (state) => {
            state.openModalDetailTask = false;
            state.modalTaskId = null;
        },
        setDateStartCreateTask: (state, action) => {
            state.dateStartCreateTask = action.payload;
        },
        setDeadlineCreateTask: (state, action) => {
            state.deadlineCreateTask = action.payload;
        },
        setModalTaskId: (state, action) => {
            state.modalTaskId = action.payload;
        },
    },
});

export const {
    openModalCreateTaskPage,
    closeModalCreateTaskPage,
    openModalDetailTaskPage,
    closeModalDetailTaskPage,
    setDateStartCreateTask,
    setDeadlineCreateTask,
    setModalTaskId,
} = modalSlice.actions;
export default modalSlice.reducer;