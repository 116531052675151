import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { setOnlineUsers } from './redux/userSlice';
import Sidebar from './pages/Sidebar';
import routes from './routes';
import { useDispatch } from 'react-redux';

function App() {
  let connectionAttempts = 0;
  const MAX_CONNECTION_ATTEMPTS = 20;
  const token = localStorage.getItem('token');
  const [confirmAuthMobile, setConfirmAuthMobile] = useState(false);
  const authmobile = localStorage.getItem('authmobile');
  const wsUrl = process.env.REACT_APP_WS_URL;
  const ws = useRef(null);
  const dispatch = useDispatch();

  function isMobileDevice() {
    if (confirmAuthMobile === false && authmobile !== 'false') {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    else {
      if (authmobile !== 'false') {
        localStorage.setItem('authmobile', 'false');
      }
      return false;
    }
  };

  function WebsocketConnectOnline() {
      const token = localStorage.getItem('token');
      ws.current = new WebSocket(`${wsUrl}ws/user_status/?token=${token}`);
      connectionAttempts++
      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.active_users) {
          dispatch(setOnlineUsers(data.active_users));
        }
      };

      ws.current.onopen = () => {
      };

      ws.current.onerror = (error) => {
      };

      ws.current.onclose = () => {
        if (connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
          WebsocketConnectOnline();
        }
      };

      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
  }
  const location = useLocation();

  useEffect(() => {
    if (ws.current !== null) {
      ws.current.onclose();
    }
    if (token) {
      WebsocketConnectOnline();
    }
    // eslint-disable-next-line
  }, [token]);



  if (isMobileDevice()) {
    return (
      <div id="not-adaptive-device">
          В данный момент адаптация системы под мобильные устройства находится в разработке. Вы хотите продолжить?
          <button id='confirm-not-adaptive-device'
            onClick={() => setConfirmAuthMobile(!confirmAuthMobile)}
          >
            Да
          </button>
        </div>
    )
  }

  return (
      <div className="App" style={{display: 'flex', width: '100%'}}>
        <Sidebar/>
        <main style=
            {{
            paddingLeft:  
              (location.pathname==='/login' || location.pathname==='/' 
              || location.pathname==='' || location.pathname==='/create-appeal' 
              || location.pathname==='/about' || location.pathname==='/privacy-policy'
              || location.pathname==='/developers-info/' || location.pathname==='/developers-info'
              || location.pathname==='/create-appeal/' 
              || location.pathname==='/about/' || location.pathname==='/privacy-policy/'
              || location.pathname==='/prices-info' || location.pathname==='/prices-info/'
              || location.pathname==='/solutions-info' || location.pathname==='/solutions-info/'
              || location.pathname==='/registration' || location.pathname==='/registration/'
              || location.pathname.startsWith('/invite-organization/') || location.pathname.startsWith('/change-password/') 
              || location.pathname.startsWith('/choice-organization')) ?
              '0px': '120px',
              width: (location.pathname==='/login' || location.pathname==='/' 
                || location.pathname==='' || location.pathname==='/create-appeal' 
                || location.pathname==='/about' || location.pathname==='/privacy-policy'
                || location.pathname==='/developers-info/' || location.pathname==='/developers-info'
                || location.pathname==='/create-appeal/' 
                || location.pathname==='/about/' || location.pathname==='/privacy-policy/'
                || location.pathname==='/prices-info' || location.pathname==='/prices-info/'
                || location.pathname==='/solutions-info' || location.pathname==='/solutions-info/'
                || location.pathname==='/registration' || location.pathname==='/registration/'
                || location.pathname.startsWith('/invite-organization/') || location.pathname.startsWith('/change-password/') 
                || location.pathname.startsWith('/choice-organization')) ? '100%':
                'calc(100% - 120px)',
               position: 'relative'}}
          >
          <Routes>
            {routes.map(({ path, element }) => (
              <Route key={path} path={path} element={element}/>
            ))}
          </Routes>
        </main>
    </div>
  );
}

export default App;
