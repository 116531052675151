import React, { useEffect, useRef, useState } from 'react';
import { scheduler } from 'dhtmlx-scheduler';
import '../styles/TaskCalendarCustom.css'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler.css';
import CreateTask from '../CreateTaskPage';
import api from '/app/frontend/src/api';
import { format } from 'date-fns';
import "dhtmlx-scheduler/codebase/dhtmlxscheduler.js";
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { openModalCreateTaskPage, closeModalCreateTaskPage,
        openModalDetailTaskPage, closeModalDetailTaskPage,
        setDateStartCreateTask, setDeadlineCreateTask,
        setModalTaskId  } from '../../redux/modalSlice';
import DetailTask from '../DetailTaskPage';


const TaskCalendar = () => {
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const dispatch = useDispatch();
    const openModalCreateTask = useSelector((state) => state.modal.openModalCreateTask);
    const openModalDetailTask = useSelector((state) => state.modal.openModalDetailTask);

    let isDoubleClickHandled = false;
    const schedulerContainer = useRef(null);
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [group, setGroup] = useState([]);
    const [openModalChangeGroupTask, setopenModalChangeGroupTask] = useState(false);
    const [openModalInvalidTask, setopenModalInvalidTask] = useState(false);
    const [taskDefaultId, setTaskDefaultId] = useState()
    const [invalidTasks, setInvalidTasks] = useState([]);

    const convertToUTC = (dateTimeStr) => {
        const isoStr = dateTimeStr.replace(' ', 'T') + ':00';
        const utcDateTime = new Date(isoStr).toISOString();
        return utcDateTime;
    };

    useEffect(() => {
        scheduler.plugins({
            quick_info: true,
            active_links: true,
            minical: true,
            export_api: true,
            agenda_view: true,
            year_view: true,
        });
        scheduler.templates.quick_info_content = function (start, end, task) {
            return `
                <div id="sheduler-quick-info-custom">
                    <strong>Номер:</strong> ${task.id}
                    <p><strong>Группа:</strong> ${task.group}</p>
                    <p><strong>Исполнитель:</strong> ${task.performer}</p>
                    <p><strong>Описание:</strong> ${task.description}</p>
                </div>
            `;
        };
        scheduler.templates.event_class = function(start, end, event){
            if (event.priority < 4) {
                return "priority-low-sh";
            } else if (event.priority < 8) {
                return "priority-medium-sh";
            } else if (event.priority < 11) {
                return "priority-high-sh";
            }
            return "";
        };
        scheduler.locale = {
            date: {
                month_full: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                month_short: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
                day_full: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                day_short: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
            },
            labels: {
                dhx_cal_today_button: "Сегодня",
                day_tab: "День",
                week_tab: "Неделя",
                month_tab: "Месяц",
                new_event: "Новое событие",
                icon_save: "Сохранить",
                icon_cancel: "Отмена",
                icon_details: "Детали",
                icon_edit: "Редактировать",
                icon_delete: "Удалить",
                confirm_closing: "Ваши изменения будут потеряны, продолжить?",
                confirm_deleting: "Событие будет удалено без возможности восстановления, продолжить?",
                agenda_tab: "Список задач",
                year_tab: "Год",
            }
        };
        scheduler.config.details_on_dblclick = true;
        scheduler.config.details_on_create=true;
        scheduler.config.xml_date = "%d-%m-%Y %H:%i:%s";
        scheduler.config.drag_create = true;
        scheduler.config.lightbox = false;
        scheduler.config.header = [
            "day",
            "week",
            "month",
            "year",
            "date",
            "prev",
            "today",
            "next",
            "agenda",
        ];
        scheduler.i18n.setLocale("ru");
        scheduler.config.buttons_right=["edit_button", "delete_button"];
        scheduler.init(schedulerContainer.current, new Date(), "week");

        const loadTasks = async () => {
            try {
                const response = await api.get(`${apiUrl}api/v1/tasks/`, {
                    params: {
                        'slugboard': window.location.pathname.replace(/\/$/, '').split('/').pop()
                    },
                    headers: { 'Authorization': 'Bearer ' + token },
                });
                const validTasks = []
                const invalidTasks = []
                response.data.forEach(task => {
                    if (task.deadline && task.date_start_task) {
                        validTasks.push(task);
                    } else {
                        invalidTasks.push(task);
                    }
                });
                setInvalidTasks(invalidTasks);
                const tasks = transformTasks(validTasks);
                scheduler.parse({data: tasks}, "json");
            } catch (error) {
                console.error('Ошибка загрузки задач для scheduler:', error);
            }
        };

        loadTasks();

        scheduler.attachEvent("onBeforeLightbox", function(id) {
            if (scheduler.getEvent(id).group) {
                if (isDoubleClickHandled) {
                    return;
                }
                dispatch(setModalTaskId(id));
                dispatch(openModalDetailTaskPage());
                setTaskDefaultId(id);
                isDoubleClickHandled = true;
                setTimeout(() => {
                    isDoubleClickHandled = false;
                }, 300);
            }
            else {
                api.get(`${apiUrl}api/v1/groups/`, {
                    params: {
                        'board_id': window.location.pathname.replace(/\/$/, '').split('/').pop()
                    },
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setGroup(response.data);
                        dispatch(setDeadlineCreateTask(String(scheduler.getEvent(id).end_date)));
                        dispatch(setDateStartCreateTask(String(scheduler.getEvent(id).start_date)));
                        setopenModalChangeGroupTask(true);
                        setTaskDefaultId(id);
                    }
                }).catch(error => {
                    if (error.response.status !== 403) {
                    }
                });
            }
            return false
        });

        scheduler.attachEvent("onEventDeleted", async (id, task) => {
            try {
                await api.delete(`${apiUrl}api/v1/tasks/${id}/`,{
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
            } catch (error) {
                console.error(`Ошибка при удалении:`, error);
            }
        });

        scheduler.attachEvent("onEventChanged", async(eventId, eventObject) => {
            try {
                await api.patch(`${apiUrl}api/v1/tasks/${eventId}/`, {
                    deadline: convertToUTC(format(new Date(eventObject.end_date), 'yyyy-MM-dd HH:mm')),
                    date_start_task: convertToUTC(format(new Date(eventObject.start_date), 'yyyy-MM-dd HH:mm')),
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                });
            } catch (error) {
                console.error(`Ошибка при обновлении задачи ${eventId}:`, error);
            }
        });

        const handleDragOver = (e) => {
            e.preventDefault();
            closeModal()
        };
        
        const handleDrop = (e) => {
            e.preventDefault();
            let data = e.dataTransfer.getData("eventData");
            if (data) {
                data = JSON.parse(data);
                const pos = scheduler.getActionData(e);
                if (pos && pos.date) {
                    console.log(pos)
                    console.log(data)
                    data.start_date = pos.date;
                    if (data.end_date && new Date(data.end_date) > new Date()) {
                        data.end_date = new Date(data.end_date);
                    } else {
                        data.end_date = new Date(pos.date.getTime() + 60 * 60 * 1000);
                    }
                    api.patch(`${apiUrl}api/v1/tasks/${data.id}/`, {
                        deadline: convertToUTC(format(new Date(data.end_date), 'yyyy-MM-dd HH:mm')),
                        date_start_task: convertToUTC(format(new Date(data.start_date), 'yyyy-MM-dd HH:mm')),
                    }, {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        },
                    });
                    setInvalidTasks(prevTasks => prevTasks.filter(task => task.task_id !== data.id));
                    scheduler.addEvent(data);
                }
            }
        }

        schedulerContainer.current.addEventListener("dragover", handleDragOver);
        schedulerContainer.current.addEventListener("drop", handleDrop);

        scheduler.message = function () {
        };

        scheduler.attachEvent("onDblClick", function (id, e) {
            console.log(isDoubleClickHandled)
            if (isDoubleClickHandled) {
                return;
            }
            isDoubleClickHandled = true;
            window.open(`/task/${id}`);
            setTimeout(() => {
                isDoubleClickHandled = false;
            }, 300);
        });

        return () => {
            scheduler.clearAll();
            // schedulerContainer.current.removeEventListener("dragover", handleDragOver);
            // schedulerContainer.current.removeEventListener("drop", handleDrop);
        };
    }, [openModalCreateTask]);

    const transformTasks = (tasks) => {
        return tasks.map(task => ({
            id: task.task_slug,
            text: task.title,
            start_date: task.date_start_task
                ? new Date(task.date_start_task).toLocaleString().replace(', ', ' ').replace(/\./g, "-")
                : task.deadline
                ? new Date(task.deadline).toLocaleString().replace(', ', ' ').replace(/\./g, "-")
                : format(new Date(), "dd-MM-yyyy hh:mm:ss"),
            end_date: task.deadline
                ? new Date(task.deadline).toLocaleString().replace(', ', ' ').replace(/\./g, "-")
                : task.date_start_task
                ? new Date(task.date_start_task).toLocaleString().replace(', ', ' ').replace(/\./g, "-")
                : format(new Date(), "dd-MM-yyyy hh:mm:ss"),
            priority: task.priority || 1,
            description: task.description || "Не назначено",
            group: task.group_name,
            performer: task.performer || "Не назначено",
        }));
    };

    function closeModal() {
        if (openModalChangeGroupTask) {
            scheduler.deleteEvent(taskDefaultId);
        }
        setopenModalChangeGroupTask(false);
        setopenModalInvalidTask(false);
        dispatch(closeModalCreateTaskPage())
        dispatch(closeModalDetailTaskPage())
    };

    return (
        <div style={{width: "100%"}}>
            <div id="gantt-change-calendar-zoom">
                <button id="gantt-set-days-btn" onClick={() => setopenModalInvalidTask(!openModalInvalidTask)}>Незапланированные задачи</button>
            </div>
            <Modal
                className="modal-task-calendar"
                isOpen={openModalChangeGroupTask}
                onRequestClose={closeModal}
                overlayClassName="overlay-task-calendar"
            >   
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "status-add-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Выберите группу
                    </label>
                </div>
                <div id="scroll-Y-div">
                        {group.map((item, index) => (
                            <button id="select-board" key={index} onClick={(event) => {
                                closeModal();
                                dispatch(openModalCreateTaskPage())
                                localStorage.setItem('grouptaskcreate', item.title);
                                localStorage.setItem('grouptasksystemstatus', item.system_status);
                                localStorage.setItem('grouptaskidcreate', item.group_id);
                                localStorage.setItem('relatedtaskid', '');
                            }}>
                                {item.title}
                            </button>
                        ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={openModalInvalidTask}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "status-add-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Незапланированные задачи
                    </label>
                </div>
                <div id="div-with-mini-card-related" style={{width: '400px', overflowY: 'scroll', minHeight: '55vh'}}>
                    {invalidTasks.map((ts, tsindex) => (
                        <div id="div-mini-card-detail-task" key={tsindex}
                            draggable={true}
                            onDragStart={(e) => {
                                const eventData = {
                                    id: ts.task_slug,
                                    text: ts.title,
                                    priority: ts.priority || 1,
                                    end_date: ts.deadline ? new Date(ts.deadline).toString(): null,
                                    description: ts.description || "Не назначено",
                                    group: ts.group_name,
                                    performer: ts.performer || "Не назначено",
                                };
                                e.dataTransfer.setData("eventData", JSON.stringify(eventData));
                              }}
                        >
                            <label id="mini-card-task-title">
                                {ts.title}
                            </label>
                            {ts.system_status_group !== 'Completed' ?
                                <div id="mini-card-div-with-deadline-and-priority">
                                    {ts.deadline ?
                                        <label id="mini-card-task-deadline">
                                            {format(new Date(ts.deadline), 'dd.MM.yyyy')}
                                        </label>: null
                                    }
                                    <label style={{marginLeft: "auto", 
                                            color: ts.priority <= 3 ? 'rgba(100, 120, 214, 1.00)':
                                                ts.priority <= 7 ? 'rgba(220, 136, 0, 1.00)':
                                                'rgba(175, 52, 52, 1.00)'
                                        }}
                                    >
                                        &#9733;
                                    </label>
                                </div>:
                                <div id="mini-card-completed"/>
                            }
                        </div>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-create-task"
                isOpen={openModalCreateTask}
                onRequestClose={closeModal}
                overlayClassName="overlay-modal-create-task"
            >
                <CreateTask/>
            </Modal>
            <Modal
                className="modal-create-task"
                isOpen={openModalDetailTask}
                onRequestClose={closeModal}
                overlayClassName="overlay-modal-create-task"
            >
                <DetailTask/>
            </Modal>
            <div ref={schedulerContainer} className="dhx_cal_container" style={{ width: '100%', height: '88vh', borderRadius: "20px" }}/>
        </div>
    );
};

export default TaskCalendar;