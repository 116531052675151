import React, { useState, useEffect } from 'react';

function TaskTimeInsideGroup({ task, group }) {
  const [timeString, setTimeString] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const groupData = task.group_data_time?.find(
        ts => Number(ts.group_id) === Number(group.group_id)
      );
      if (groupData) {
        setTimeString(calculateGroupTime(groupData));
      }
    };

    updateTime();

    const timer = setInterval(updateTime, 60000);

    return () => clearInterval(timer);
  }, [task, group]);

  return (
    <label id="task-time-inside-group">
      {timeString}
    </label>
  );
}

function calculateGroupTime(groupData) {
  function parseTimeToMs(time) {
    const timeString = typeof time === 'string' ? time : time.toString();
    const parts = timeString.split(':').map(Number);
    let ms = 0;
    if (parts.length === 2) {
      const [hours, minutes] = parts;
      ms = ((hours * 60) + minutes) * 60 * 1000;
    } else if (parts.length === 3) {
      const [hours, minutes, seconds] = parts;
      ms = ((hours * 60 + minutes) * 60 + seconds) * 1000;
    }
    return ms;
  }

  function formatMsToTime(ms) {
    const totalSeconds = Math.floor(ms / 1000);
    const totalHours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    if (totalHours < 24) {
      return `${String(totalHours).padStart(2, '0')}ч ${String(minutes).padStart(2, '0')}м`;
    } else {
      const days = Math.floor(totalHours / 24);
      const hours = totalHours % 24;
      return `${days}д ${String(hours).padStart(2, '0')}ч ${String(minutes).padStart(2, '0')}м`;
    }
  }

  const baseTimeMs = parseTimeToMs(groupData.time_in_group);

  if (groupData.leave_at === null) {
    const joinedAtMs = new Date(
      groupData.joined_at.length === 16
        ? groupData.joined_at.replace(' ', 'T') + 'Z'
        : groupData.joined_at
    ).getTime();
    const nowMs = Date.now();
    const additionalMs = nowMs - joinedAtMs;
    const totalTimeMs = baseTimeMs + additionalMs;
    return formatMsToTime(totalTimeMs);
  } else {
    return groupData.time_in_group;
  }
}

export default TaskTimeInsideGroup;