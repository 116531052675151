import React, { useState, useContext, useRef} from 'react';
import './styles/Main.css';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import { Tooltip } from 'react-tooltip';
import GetOnlineUser from './components/CustomOnlineUser';
import { useTheme } from '../ThemeProvider';

const Main = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { resolvedTheme } = useTheme();
    const token = localStorage.getItem('token');
    const [user, setUser] = useState('');
    const [board, setBoard] = useState([]);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [privatboard, setPrivateBoard] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [modalSetBoard, setIsOpenSetBoard] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState([]);
    const [canCreateBoard, setCanCreateBoard] = useState(false);
    const [canChangeBoard, setCanChangeBoard] = useState(false);
    const [canViewBoard, setCanViewBoard] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [notificationBoard, setNotificationBoard] = useState(false);
    const [notificationBoardsTrue, setNotificationBoardsTrue] = useState([]);
    const [canSeeHiddenBoards, setCanSeeHiddenBoards] = useState(true);
    const [hiddenBoards, setHiddenBoards] = useState([]);
    const scrollContainerRef = useRef(null);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const [isSlugModified, setIsSlugModified] = useState(false);

    const transliterate = (text) => {
        const cyrillicToLatin = {
          а: 'a', б: 'b', в: 'v', г: 'g', д: 'd', е: 'e', ё: 'e', ж: 'zh', з: 'z',
          и: 'i', й: 'y', к: 'k', л: 'l', м: 'm', н: 'n', о: 'o', п: 'p', р: 'r',
          с: 's', т: 't', у: 'u', ф: 'f', х: 'h', ц: 'ts', ч: 'ch', ш: 'sh', щ: 'shch',
          ъ: '', ы: 'y', ь: '', э: 'e', ю: 'yu', я: 'ya',
          А: 'A', Б: 'B', В: 'V', Г: 'G', Д: 'D', Е: 'E', Ё: 'E', Ж: 'Zh', З: 'Z',
          И: 'I', Й: 'Y', К: 'K', Л: 'L', М: 'M', Н: 'N', О: 'O', П: 'P', Р: 'R',
          С: 'S', Т: 'T', У: 'U', Ф: 'F', Х: 'H', Ц: 'Ts', Ч: 'Ch', Ш: 'Sh', Щ: 'Shch',
          Ъ: '', Ы: 'Y', Ь: '', Э: 'E', Ю: 'Yu', Я: 'Ya'
        };
        return text.split('').map(char => cyrillicToLatin[char] || char).join('');
    };
    const generateSlug = (text) => {
        let slug = transliterate(text);
        slug = slug
          .toLowerCase()
          .replace(/[^a-z0-9\s-]/g, '')
          .replace(/\s+/g, '-')
          .replace(/-+/g, '-')
          .slice(0, 50);
        return slug;
    };
    
    const handleTitleChange = (event) => {
        const newText = event.target.value;
        if (newText.length > 50) return;
        setTitle(newText);
        if (!isSlugModified) {
          setSlug(generateSlug(newText));
        }
    };
    
    const handleSlugChange = (event) => {
        const newSlug = event.target.value;
        if (newSlug.length > 50) return;
        setSlug(newSlug.toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .slice(0, 50));
        setIsSlugModified(true);
    };

    // const handleTitleChange = (event) => {
    //     if (event.target.value.length <= 50) {
    //         setTitle(event.target.value);
    //     }
    // };
    // const handleSlugChange = (event) => {
    //     if (event.target.value.length <= 50) {
    //         setSlug(event.target.value);
    //     }
    // };
    const handleSetPrivate = () => {
        if (privatboard === true){
            setPrivateBoard(false);
        }
        else {
            if (selectedUser.length !== 0) {
                alert("Вы не можете сделать доску приватной, пока в ней находится пользователи!");
            }
            else {
                setPrivateBoard(true);
            }
        }
    };

    function closeModal() {
        setIsOpen(false);
        setIsOpenSetBoard(false);
        setTitle("");
        setSlug("");
        setPrivateBoard(false);
        setUsers([]);
        setNotificationBoard(false);
    };

    function openModal() {
        setIsOpen(true);
    };

    const handleClick = (slug, title, id, e) => {
        window.location.href = "/boards/" + slug;
        localStorage.setItem('titleboards', title);
        localStorage.setItem('slugboards', slug);
        localStorage.setItem('boardID', id);
    }
    const handleClickNewTab = (slug, title, id, e) => {
        localStorage.setItem('titleboards', title);
        localStorage.setItem('slugboards', slug);
        localStorage.setItem('boardID', id);
        if (e.button === 1) {
            e.preventDefault();
            window.open("/boards/" + slug);
        }
    }
    const handleClickAuthor = (author, e) => {
        window.location.href = "/profile/" + author;
    };
    const handleMouseDownAuthor = (author, e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/profile/" + author);
        }
    };
    const loadBoards = async () => {
        if (loading || !hasMore) return;
        setLoading(true);

        try {
            const [boardsResponse, permissionsResponse] = await Promise.all([
                api.get(`${apiUrl}api/v1/boards/`, {
                    params: { page },
                    headers: { 'Authorization': 'Bearer ' + token },
                }),
                api.get(`${apiUrl}api/v1/user/permissions/`, {
                    headers: { 'Authorization': 'Bearer ' + token },
                }),
            ]);

            if (boardsResponse.status === 200) {
                setBoard((prevBoards) => {
                    const newBoards = boardsResponse.data.results.filter(
                    (newBoard) => !prevBoards.some((prevBoard) => prevBoard.id === newBoard.id)
                    );
                    return [...prevBoards, ...newBoards];
                });
                setHasMore(boardsResponse.data.next != null);
            }

            if (permissionsResponse.status === 200) {
                const permissions = permissionsResponse.data;
                setCanChangeBoard(permissions.includes('Can change Доска'));
                setCanViewBoard(permissions.includes('Can view Доска'));
                setCanViewUser(permissions.includes('Can view Пользователь'));
                setCanCreateBoard(permissions.includes('Can add Доска'));
                if (permissions.includes('owner_organization')) {
                    setCanChangeBoard(true);
                    setCanViewUser(true);
                    setCanCreateBoard(true);
                    setCanViewBoard(true);
                }
            }
        } catch (error) {
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status !== 403) {
                setPrivateBoard(true);
            }
        } finally {
            setLoading(false);
            setFirstLoading(false);
        }
    };

    React.useEffect(() => {
        loadBoards();
        // eslint-disable-next-line
    }, [page]);

    React.useEffect(() => {
        const handleScroll = (event) => {
          const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
          if (scrollHeight - scrollTop <= clientHeight * 1 && !loading) {
            setPage((prevPage) => prevPage + 1);
          }
        };
        const divRef = scrollContainerRef.current;
        if (divRef) {
          divRef.addEventListener('scroll', handleScroll);
        }
        return () => {
          if (divRef) {
            divRef.removeEventListener('scroll', handleScroll);
          }
        };
    }, [loading]);

    React.useEffect(() => {
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setHiddenBoards(response.data.hidden_boards);
                setUser(response.data);
            }
        }).catch(error =>{
            console.error(error);
        });
        // eslint-disable-next-line
    }, [token]);

    const handleClickOpenCloseAccess = (data) => {
        if (data.item.private_board === false) {
            const loadBoards = async () => {
                await api.get(`${apiUrl}api/identity/users/`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setUsers(response.data);
                        setSelectedUser(response.data.filter(user => 
                            data.item.users.includes(user.id)).map(user=>user.id));
                    }
                }).catch(error => {
                    console.error(error);
                });
            };
            loadBoards();
        }
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setNotificationBoardsTrue(response.data.notification_from_board);
                if (response.data.notification_from_board.includes(data.item.id)) {
                    setNotificationBoard(true);
                }
            }
        }).catch(error => {
            console.error(error);
        });
        setIsOpenSetBoard(true);
        setIsOpen(true);
        setSelectedBoard(data);
        setPrivateBoard(data.item.private_board);
        setTitle(data.item.title);
        setSlug(data.item.slug);
    };

    const handleSubmitPostBoards = (event) => {
        event.preventDefault();
        if (modalSetBoard === true) {
            api.patch(`${apiUrl}api/v1/boards/${selectedBoard.item.slug}/`, {
                'title': title,
                'slug': slug,
                'private_board': privatboard,
                'users': selectedUser,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setSelectedUser([]);
                    closeModal();
                    // window.location.href = "/main";
                }
            }).catch(error =>{
                alert(error);
            });
        }
        else {
            api.post(`${apiUrl}api/v1/boards/`, {
                'title': title,
                'slug': slug,
                'private_board': privatboard,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    closeModal();
                    localStorage.setItem('slugboards', response.data.slug);
                    localStorage.setItem('boardID', response.data.id);
                    window.location.href = `/main`;
                }
            }).catch(error =>{
                const status = error.response ? error.response.status : null;
                if (status === 400) {
                    if (error.response.data.errors[0].attr === 'slug') {
                        alert("Указан неверный идентификатор страницы URL");
                    }
                    else {
                        alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    }
                }
            });
        }
    };

    const handleAccessDelete = (event) => {
        const result = window.confirm("Будут полностью удалены состоянии и задачи, находящиеся в этой доске.");
        if (result) {
            api.delete(`${apiUrl}api/v1/boards/${selectedBoard.item.slug}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    alert("Успешно удалено");
                    window.location.href = "/main";
                }
            })
            .catch(error =>{
                console.error(error);
            });
        }
    };

    const handleSetTruRole = (data) => {
        let newSelectedRole = [...selectedUser];
        if (newSelectedRole.includes(data.id)) {
            newSelectedRole = newSelectedRole.filter(item => item !== data.id);
        } 
        else {
            newSelectedRole.push(data.id);
        }
        setSelectedUser(newSelectedRole);
    };

    const handleChangeNotificationBoard = (id_board) => {
        setNotificationBoard(!notificationBoard);
        if (notificationBoardsTrue.includes(id_board)) {
            let updatedNotifications = notificationBoardsTrue.filter(item => item !== id_board);
            setNotificationBoardsTrue(updatedNotifications);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'notification_from_board': updatedNotifications,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            let updatedNotifications = [...notificationBoardsTrue, id_board];
            setNotificationBoardsTrue([...notificationBoardsTrue, id_board]);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'notification_from_board': updatedNotifications,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleClickHideBoard = (event, item) => {
        if (hiddenBoards.includes(item)) {
            const updatedHiddenBoards = hiddenBoards.filter(board => board !== item);
            setHiddenBoards(updatedHiddenBoards);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'hidden_boards': updatedHiddenBoards,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            let updatedHiddenBoards = [...hiddenBoards, item];
            setHiddenBoards([...hiddenBoards, item]);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'hidden_boards': updatedHiddenBoards,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setBoard((prevBoards) => prevBoards.filter(board => board.id !== item));
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleClickShowHiddenBoards = (event) => {
        event.preventDefault();
        setCanSeeHiddenBoards(!canSeeHiddenBoards);
        if (canSeeHiddenBoards) {
            api.get(`${apiUrl}api/v1/boards/get-hidden-boards/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    const hidenboards = response.data;
                    setBoard((prevBoards) => {
                        const newHiddenBoards = hidenboards.filter(hiddenBoard => 
                            !prevBoards.some(board => board.id === hiddenBoard.id)
                        );
                        return [
                            ...newHiddenBoards,
                            ...prevBoards
                        ];
                    });
                }
            }).catch(error =>{
                console.error(error);
            });
        }
        else {
            setBoard((prevBoards) => prevBoards.filter(board => !hiddenBoards.includes(board.id)));
        }
    };

    document.title = "Активные доски - ТвояЗадача";

    if (firstLoading) {
        return (
            <div className="main-page">
                <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <span class="loader"></span>
                </div>
            </div>
        )
    }

    return (
        <div className="main-page">
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={modalSetBoard === true ? apiUrlFrontend + "board-settings-icon.svg": apiUrlFrontend + "board-add-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        {modalSetBoard === true ? 'Настройки доски' : 'Создать доску'}
                    </label>
                </div>
                <form id="board-form" onSubmit={handleSubmitPostBoards}>
                    {canChangeBoard || privatboard ?
                        <div>
                            <input
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Заголовок"
                                required
                                className="board-input"
                            />
                            <input
                                type="text"
                                className="board-input"
                                value={slug}
                                onChange={handleSlugChange}
                                placeholder="Ключ доски"
                                required
                            />
                            <label id="hint-for-create-board-slug">
                                Идентификатор доски для URL,
                                разрешены символы латиницы, дефис и подчеркивание
                            </label>
                        </div>: null
                    }
                    {canCreateBoard ?
                        modalSetBoard === true ? 
                            selectedBoard.item.author === user.username ?
                                <div id="is-private-div">
                                    <label id="label-private">Приватная доска</label>
                                    <label className="checkbox style-e">
                                        <input id="check-group"
                                            type="checkbox"
                                            onChange={() => handleSetPrivate({})}
                                            checked={privatboard}
                                        />
                                        <div className="checkbox__checkmark"></div>
                                    </label>
                                </div>: null
                            :<div id="is-private-div">
                                <label id="label-private">Приватная доска</label>
                                <label className="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => setPrivateBoard(!privatboard)}
                                        checked={privatboard}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                </label>
                            </div>
                        :<div id="is-private-div">
                            <label id="label-private">Приватная доска</label>
                            <label className="checkbox style-e">
                                <input id="check-group"
                                    type="checkbox"
                                    checked={true}
                                />
                                <div className="checkbox__checkmark"></div>
                            </label>
                        </div>
                    }
                    {modalSetBoard === true ? 
                        <div id="div-notification-from-board">
                            <label id="notification-this-board">
                                Получать уведомления с этой доски
                            </label>
                            <label className="checkbox style-e">
                                <input id="check-group"
                                    type="checkbox"
                                    checked={notificationBoard}
                                    onChange={() => handleChangeNotificationBoard(selectedBoard.item.id)}
                                />
                                    <div className="checkbox__checkmark"></div>
                            </label>
                        </div>: null
                    }
                    {privatboard === false ?
                        canChangeBoard ?
                            <div id="div-with-user-change-access-board">
                                {users.slice().sort((a, b) => {
                                    const aSelected = selectedUser.includes(a.id);
                                    const bSelected = selectedUser.includes(b.id);
                                    if (aSelected && !bSelected) return -1;
                                    if (!aSelected && bSelected) return 1; 
                                    return 0;
                                }).map((us) => (
                                    <div key={us.id} id="checkbox-add-user-board">
                                        <GetOnlineUser user={us.id}/>
                                        <img 
                                            id="image-users"
                                            src={us.image || apiUrlFrontend + "person-square.svg"}
                                            alt={us.username}
                                        />
                                        <div id="name-user-board">
                                            <label id="nameuser">
                                                {us.formated_fio ? us.formated_fio.slice(0, 20) : us.username.slice(0, 20)}
                                            </label>
                                        </div>
                                        <label className="checkbox style-e">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleSetTruRole({ 
                                                id: us.id, 
                                                group_id: selectedBoard.id 
                                                })}
                                                checked={selectedUser.includes(us.id)}
                                            />
                                            <div className="checkbox__checkmark"></div>
                                        </label>
                                    </div>
                                    ))
                                }
                            </div>: 
                        null: 
                    null}
                    {modalSetBoard === true ?
                        <div style={{display: 'flex'}}>
                            <button id="openAccessDelete" type="button" onClick={handleAccessDelete}>Удалить</button>
                            <button id="confirmtask-create" type="submit">Изменить</button>
                        </div>:
                        <button id="confirmtask-create" type="submit">Создать</button>
                    }
                </form>
            </Modal>
                <div className="div-for-btn-header">
                    <label className="label-selected-organization">
                        Текущая организация {user.available_organization?.find(org => org.id === user.organization)?.name}
                    </label>
                    <button className="btn-open-change-data" style={{marginLeft: 'auto', background: 'rgba(22, 179, 74, 0.66)'}} onClick={(event) => handleClickShowHiddenBoards(event)}>
                        <img id="img-header-task" src={apiUrlFrontend + "hide-board.svg"} alt=""/>
                        <span>Скрытые доски</span>
                    </button>    
                    <button className="btn-open-change-data" onClick={openModal} style={{background: 'rgba(22,121,179, 0.808)'}}>
                        <img id="img-header-task" src={apiUrlFrontend + "plus-btn.svg"} alt=""/>
                        <span>Создать</span>
                    </button>
                </div>
                <div className="aside-line"/>
                <div id="main-page-scroll" ref={scrollContainerRef}>
                {board.length > 0 ? board.map((item, index) => (
                    <div id="divforboard" key={index}>
                        <button id="buttonfordiv"
                            onMouseDown={(e) => {handleClickNewTab(item.slug, item.title, item.id, e)}}
                            onClick={(e) => {handleClick(item.slug, item.title, item.id, e)}}>  
                            <div id="title">{item.title}</div>
                        </button>
                        <button id="opencloseaccess"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Настройки доски"
                            onClick={() => {handleClickOpenCloseAccess({item})}}
                        >
                            <img id="btn-menu" src={apiUrlFrontend + 'btn-menu-dot.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                        </button>
                        {hiddenBoards.includes(item.id) ?
                            <img id="show-board-button"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Показать доску"
                                style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                                src={apiUrlFrontend + "show-board.svg"}
                                alt=""
                                onClick={(event) => handleClickHideBoard(event, item.id)}
                            />: 
                            <img id="hide-board-button"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Скрыть доску"
                                src={apiUrlFrontend + "hide-board.svg"}
                                style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                                alt=""
                                onClick={(event) => handleClickHideBoard(event, item.id)}
                            />
                        }
                        {canViewUser ?
                            item.formated_fio_author ?
                                <button id="author"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Профиль пользователя"
                                    onMouseDown={(e) => {handleMouseDownAuthor(item.author, e)}}
                                    onClick={(e) => {handleClickAuthor(item.author, e)}}
                                >
                                    {item.formated_fio_author}
                                </button>: 
                                <button id="author"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Профиль пользователя"
                                    onMouseDown={(e) => {handleMouseDownAuthor(item.author, e)}}
                                    onClick={(e) => {handleClickAuthor(item.author, e)}}
                                >
                                    {item.author}
                                </button>
                            : null
                        }
                    </div>
                    )): canViewBoard === false ? 
                        <div id="null-tasks">Нет прав для просмотра досок, обратитесь к администратору.</div>:
                    <div id="null-tasks">Досок нет, время создавать</div>
                }
                </div>
                <Tooltip 
                    id="my-tooltip"
                    delayShow={750}
                    delayHide={100}
                />
        </div>
    );
}

export default Main;