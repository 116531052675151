
import React, { useEffect, useState, useRef } from 'react';
import './styles/TasksList.css';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import api from '/app/frontend/src/api';
import { format } from 'date-fns';
import CustomSelect from './components/CustomSelect';
import { Tooltip } from 'react-tooltip';
import GetOnlineUser from './components/CustomOnlineUser';
import { useTheme } from '../ThemeProvider';

const TasksList = () => {
    const timerRef = useRef(null);
    const { resolvedTheme } = useTheme();
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const token = localStorage.getItem('token');
    const [task, setTask] = useState([]);
    const [label, setLabel] = useState([]);
    const [performer, setPerformer] = useState([]);
    const [group, setGroup] = useState([]);
    const [priority] = useState([1,2,3,4,5,6,7,8,9,10]);
    const [filteredTasks, setFilteredTask] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [dateRangedeadline, setDateRangedeadline] = useState([null, null]);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [modalIsOpenCalendardeadline, setIsOpenCalendardeadline] = useState(false);
    const [board, setBoard] = useState([]);
    const [openModalChangeGroupTask, setopenModalChangeGroupTask] = useState(false);
    const [openModalChangeBoard, setopenModalChangeBoard] = useState(false);
    const [boardid, setboardid] = useState("");
    const [pageBoard, setPageBoard] = useState(1);
    const [pageTask, setPageTask] = useState(1);
    const [pageForFilterTask, setPageForFilterTask] = useState(1);
    const [loadingTask, setLoadingTask] = useState(false);
    const [loadingTaskFilter, setLoadingTaskFilter] = useState(false);
    const [hasMoreTask, setHasMoreTask] = useState(true);
    const [hasMoreTaskFilter, setHasMoreTaskFilter] = useState(true);
    const [hasMoreBoard, setHasMoreBoard] = useState(true);
    const [loadingBoard, setLoadingBoard] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [canAddTask, setCanAddTask] = useState(false);
    const [changegroup, setchangegroup] = useState([]);
    const [allowCreateTaskInStatusCompleted, setAllowCreateTaskInStatusCompleted] = useState(false);
    const [selectedDefaultFilters, setSelectedDefaultFilters] = useState({
        label: [],
        performer: [],
        status: [],
        priority: [],
    });
    const [selectedFilters, setSelectedFilters] = useState({
        label: [],
        performer: [],
        status: [],
        priority: [],
        title: '',
        datecreatestart: '',
        datecreateend: '',
        deadlinestart: '',
        deadlineend: '',
    });
    const [prevSelectedFiltersRef, setprevSelectedFiltersRef] = useState({
        label: [],
        performer: [],
        status: [],
        priority: [],
        title: '',
        datecreatestart: '',
        datecreateend: '',
        deadlinestart: '',
        deadlineend: '',
    })
    const [openModalDefaultFilter, setOpenModalDefaultFilter] = useState(false);

    const fetchFilteredData = async () => {
        if (selectedFilters.performer.length !== 0 ||
            selectedFilters.label.length !== 0 ||
            selectedFilters.status.length !== 0 ||
            selectedFilters.priority.length !== 0 ||
            selectedFilters.title.length !== 0 ||
            selectedFilters.deadlineend !== '' ||
            selectedFilters.deadlinestart !== '' ||
            selectedFilters.datecreateend !== '' ||
            selectedFilters.datecreatestart !== ''
        ) {
            setPageTask(1);
            const loadTasksFilter = async() => {
                const isEqual = JSON.stringify(prevSelectedFiltersRef) === JSON.stringify(selectedFilters);
                let test = false;
                let test2 = null;
                if (!isEqual) {
                    setPageForFilterTask(1);
                    setHasMoreTaskFilter(true);
                    setLoadingTaskFilter(true);
                    test = true;
                    test2 = 1;
                }
                if ((loadingTaskFilter || !hasMoreTaskFilter) && !test) return;
                test = false;
                setLoadingTaskFilter(true);
                try{
                    const response = await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                        'performers': selectedFilters.performer.map(pf => pf.id),
                        'labels': selectedFilters.label.map(lb => lb.id),
                        'groups': selectedFilters.status.map(st => st.title),
                        'prioritys': selectedFilters.priority,
                        'title': selectedFilters.title,
                        'datecreatestart': selectedFilters.datecreatestart,
                        'datecreateend': selectedFilters.datecreateend,
                        'deadlinestart': selectedFilters.deadlinestart,
                        'deadlineend': selectedFilters.deadlineend,
                    },{
                        params: {page: test2!==null? test2: pageForFilterTask},
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    })
                    if (response.status == 200) {
                        test2 = null;
                        if (pageForFilterTask > 1) {
                            setFilteredTask(prevTasks => {
                                const newTasks = response.data.results.filter(
                                    newTask => !prevTasks.some(prevTask => prevTask.task_id === newTask.task_id)
                                );
                                return [...prevTasks, ...newTasks]
                            });
                        }
                        else {
                            setFilteredTask(response.data.results)
                        }
                        setHasMoreTaskFilter(response.data.next !== null);
                        if (response.data.next) setPageForFilterTask(response.data.next.split('?page=')[1]);
                        if (response.data.results.length === 0) {
                            setLoadingTaskFilter(false);
                            setFirstLoading(false);
                        }
                    }
                } catch (error) {
                    console.error(error);
                    const status = error.response ? error.response.status : null;
                    if (status !== 403) {
                        // Обработка ошибки
                    }
                }
                setprevSelectedFiltersRef(selectedFilters);
                setLoadingTaskFilter(false);
                setFirstLoading(false);
            }
            loadTasksFilter();
        }
        else {
            const loadTasks = async() => {
                if (loadingTask || !hasMoreTask) return;
                setLoadingTask(true);
                try {
                    const response = await api.get(`${apiUrl}api/v1/tasks/`, {
                        params: {page: pageTask},
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });
                    if (response.status == 200) {
                        setPageForFilterTask(1);
                        if (pageTask === 1) {
                            setFilteredTask(response.data.results);
                        }
                        else {
                            setFilteredTask(prevTasks => {
                                const newTasks = response.data.results.filter(
                                    newTask => !prevTasks.some(prevTask => prevTask.task_id === newTask.task_id)
                                );
                                return [...prevTasks, ...newTasks]
                            });
                        }
                        setTask(prevTasks => {
                            const newTasks = response.data.results.filter(
                                newTask => !prevTasks.some(prevTask => prevTask.task_id === newTask.task_id)
                            );
                            return [...prevTasks, ...newTasks]
                        });
                        setHasMoreTask(response.data.next !== null);
                        if (response.data.next) {
                            setPageTask(response.data.next.split('?page=')[1]);
                        }
                        if (response.data.results.length === 0) {
                            setLoadingTask(false);
                            setFirstLoading(false);
                        }
                    }
                } catch (error) {
                    console.error(error);
                    const status = error.response ? error.response.status : null;
                    if (status !== 403) {
                        // Обработка ошибки
                    }
                }
                setLoadingTask(false);
                setFirstLoading(false);
            }
            loadTasks();
        }
    };

    useEffect(() => {
        fetchFilteredData();
        // eslint-disable-next-line
    }, [selectedFilters]);

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            fetchFilteredData();
        }
    };

    useEffect(() => {
        if (filteredTasks.length > 0) {
            setLoadingTask(false);
        }
    }, [filteredTasks]);

    const handleFilterChange = (filterName, selectedOptions) => {
        setSelectedFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: selectedOptions
        }));
    };

    const handleSetDefaultFilters = () => {
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'default_filters_for_task_list': selectedDefaultFilters
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Фильтры по умолчанию сохранены");
                closeModal();
                fetchFilteredData();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDeleteDefaultFilters = () => {
        setSelectedDefaultFilters({
            label: [],
            performer: [],
            status: [],
            priority: [],
        });
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'default_filters_for_task_list': []
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Фильтры по умолчанию сброшены");
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDefaultFilterChange = (filterType, id) => {
        setSelectedDefaultFilters(prevFilters => {
            const currentFilterValues = prevFilters[filterType];
            const isChecked = currentFilterValues.includes(id);
            if (isChecked) {
                return {
                    ...prevFilters,
                    [filterType]: currentFilterValues.filter(existingId => existingId !== id),
                };
            } else {
                return {
                    ...prevFilters,
                    [filterType]: [...currentFilterValues, id],
                };
            }
        });
    }

    function closeModal() {
        setOpenModalDefaultFilter(false);
        setIsOpenCalendar(false);
        setIsOpenCalendardeadline(false);
        setopenModalChangeBoard(false);
        setopenModalChangeGroupTask(false);
    };

    const handleopenModalCalendardeadline = () => {
        closeModal();
        setIsOpenCalendardeadline(true);
    };

    useEffect(() => {
        const CheckPermission = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can add Задача')) {
                        setCanAddTask(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanAddTask(true);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        };
        CheckPermission();
        api.get(`${apiUrl}api/v1/labels/`,{
            headers: {
                 'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if(response.status === 200) {
                const uniqueLabels = response.data.filter((item, index) => {
                    return response.data.findIndex(obj => obj.name === item.name) === index;
                });
                setLabel(uniqueLabels);
            }
        }).catch(error =>{
            console.error(error);
        });
        api.get(`${apiUrl}api/v1/groups/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const uniqueGroups = response.data.filter((item, index) => {
                    return response.data.findIndex(obj => obj.title === item.title) === index;
                });
                setGroup(uniqueGroups);
            }
        }).catch(error =>{
            console.error(error);
        });
        fetchAllUsers();
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.default_filters_for_task_list.length !== 0) {
                    setSelectedDefaultFilters(response.data.default_filters_for_task_list);
                }
            }
        }).catch(error =>{
            console.error(error);
        });
        // eslint-disable-next-line
    }, []);

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } 
                else {
                    setPerformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleOpenModalDefaultFilter = (e) => {
        e.preventDefault();
        closeModal();
        setOpenModalDefaultFilter(true);
    };

    const handleOpenModalBoard = () => {
        closeModal();
        const loadBoards = async () => {
            if (loadingBoard || !hasMoreBoard) return;
            setLoadingBoard(true);
            try {
                const response = await api.get(`${apiUrl}api/v1/boards/`, {
                    params: { page: pageBoard },
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });
                if (response.status === 200) {
                    setBoard(prevBoards => {
                        const newBoards = response.data.results.filter(newBoard => 
                            !prevBoards.some(prevBoard => prevBoard.id === newBoard.id));
                        return [...prevBoards, ...newBoards];
                    });
                    setHasMoreBoard(response.data.next != null);
                    if (response.data.next) setPageBoard(prevPage => prevPage + 1);
                }
            } 
            catch (error) {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status !== 403) {
                }
            }
            setLoadingBoard(false);
        }
        loadBoards();
        setopenModalChangeBoard(true);
    };

    const handleScrollBoards = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            handleOpenModalBoard();
        }
    };

    const handleOpenChangeGroup = (event, board) => {
        closeModal();
        if (board.private_board === true) {
            api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': board.slug
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setchangegroup(response.data);
                    setopenModalChangeGroupTask(true);
                }
            }).catch(error => {
                if (error.response.status !== 403) {
                }
            });
        }
        else {
            api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': board.slug
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setchangegroup(response.data);
                }
            }).catch(error => {
                if (error.response.status !== 403) {
                }
            });
            api.get(`${apiUrl}api/v1/organizations/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setAllowCreateTaskInStatusCompleted(response.data[0].allow_create_task_in_status_completed);
                    setopenModalChangeGroupTask(true);
                }
            }).catch(error =>{
                const status = error.response ? error.response.status : null;
                if (status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };

    const handleDateChange = date => {
        if (dateRange[0] && dateRange[1]) {
            setDateRange([date, null]);
        } 
        else if (dateRange[0] == null) {
            setDateRange([date, null]);
        } 
        else {
            setDateRange(prevRange => [prevRange[0], date]);
            const currentformatdt0 = format(new Date(dateRange[0]), 'yyyy-MM-dd');
            const currentformatdt1 = format(new Date(date), 'yyyy-MM-dd');
            handleFilterChange('datecreatestart', currentformatdt0);
            handleFilterChange('datecreateend', currentformatdt1);
        }
    };

    const handleDateChangedeadline = date => {
        if (dateRangedeadline[0] && dateRangedeadline[1]) {
            setDateRangedeadline([date, null]);
        }
        else if (dateRangedeadline[0] == null) {
            setDateRangedeadline([date, null]);
        }
        else {
            setDateRangedeadline(prevRange => [prevRange[0], date]);
            const currentformatdt0 = format(new Date(dateRangedeadline[0]), 'yyyy-MM-dd');
            const currentformatdt1 = format(new Date(date), 'yyyy-MM-dd');
            handleFilterChange('deadlinestart', currentformatdt0);
            handleFilterChange('deadlineend', currentformatdt1);
        }
    };

    const handleTaskDetailGo = (event, task_slug) => {
        event.preventDefault();
        window.location.href = '/task/' + task_slug;
    };

    const handleTaskDetailGoNewPin = (e, task_slug) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open('/task/' + task_slug);
        }
    };

    const handleClickGotoBoard = (id_task) => {
        api.get(`${apiUrl}api/v1/tasks/${id_task}/get-board-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('titleboards', response.data.title);
                localStorage.setItem('slugboards', response.data.slug);
                localStorage.setItem('boardID', response.data.id);
                window.location.href = "/boards/" + response.data.slug;
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleClickGotoBoardNewPin = (id_task, e) => {
        if (e.button === 1) {
            e.preventDefault();
            api.get(`${apiUrl}api/v1/tasks/${id_task}/get-board-task/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('titleboards', response.data.title);
                    localStorage.setItem('slugboards', response.data.slug);
                    localStorage.setItem('boardID', response.data.id);
                    window.open("/boards/" + response.data.slug);
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleTextareachange = (event) => {
        setSearchItem(event.target.value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            handleFilterChange('title', event.target.value);
        }, 500);
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected) {
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    const handleResetFilter = () => {
        setSelectedFilters({
            title: '',
            datecreatestart: '',
            performer: [],
            label: [],
            status: [],
            priority: [],
            datecreateend: '',
            deadlinestart: '',
            deadlineend: ''
        });
        setSearchItem('');
        // window.location.href = "/tasks/list/"
    };

    const handleRedirectToCreateTask = (event, item) => {
        event.preventDefault();
        localStorage.setItem('grouptaskcreate', item.title);
        localStorage.setItem('grouptasksystemstatus', item.system_status);
        localStorage.setItem('grouptaskidcreate', item.group_id);
        localStorage.setItem('relatedtaskid', '');
        window.location.href = "/create-task";
    };

    const [checkTypeSortCreatedTask, setCheckTypeSortCreatedTask] = useState(false);
    const [checkTypeSortDeadlineTask, setCheckTypeSortDeadlineTask] = useState(false);
    const handleSortedDateTopBottom = (type) => {
        if (type === 'created_at') {
            setFilteredTask(filteredTasks.slice().sort((a, b) => {
                const dateA = Date.parse(a.created_at);
                const dateB = Date.parse(b.created_at);
                return checkTypeSortCreatedTask ? dateB - dateA : dateA - dateB;
            }));
            setCheckTypeSortCreatedTask(!checkTypeSortCreatedTask);
        }
        else {
            setFilteredTask(filteredTasks.slice().sort((a, b) => {
                const dateA = Date.parse(a.deadline) || Infinity;
                const dateB = Date.parse(b.deadline) || Infinity;
                return checkTypeSortDeadlineTask ? dateB - dateA : dateA - dateB;
            }));
            setCheckTypeSortDeadlineTask(!checkTypeSortDeadlineTask);
        }
    };
    // const handleCopyInvitelink = () => {
    //     try {
    //         navigator.clipboard.writeText(window.location);
    //         alert("Ссылка на задачу скопирована в буфер обмена");
    //     } catch (err) {
    //         console.error('Не удалось скопировать: ', err);
    //     }
    //   };
    const [showAllLabel, setShowAllLabel] = useState(false);
    const [showAllPerformer, setShowAllPerformer] = useState(false);
    const [showAllGroup, setShowAllGroup] = useState(false);
    var screenHeight = window.innerHeight;

    document.title = "Список задач - ТвояЗадача";

    return (
        <div className="maindivfortaskslist">
            <Modal className="modal-add-data-component"
                isOpen={openModalDefaultFilter}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "default-sorted-gray-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Фильтры по умолчанию
                    </label>
                </div>
                <div className="div-data-component-list">
                    {label.length > 0 ?
                        <div>
                            <div style={{display: 'flex'}}>
                                <img
                                    id="img-component-task"
                                    src={apiUrlFrontend + 'label-white-icon.svg'}
                                    alt=""
                                    style={{filter: resolvedTheme === 'light' ? 'invert(70%)': null}}
                                />
                                <label className="label-component-filter">
                                    Метка
                                </label>
                            </div>
                            <div className="data-default-filter">
                                {[
                                    ...label.filter((lb) =>
                                    selectedDefaultFilters.label.includes(lb.id)
                                    ),
                                    ...label.filter(
                                    (lb) => !selectedDefaultFilters.label.includes(lb.id)
                                    ),
                                ].slice(0, showAllLabel ? label.length : 6).map((lb, lbindex) => (
                                    <label className="data-component-add" key={lbindex}
                                        style={{ 
                                            background: selectedDefaultFilters.label.includes(lb.id) ? '#087F8C' : 'var(--base-btn-color)', padding: '10px 15px 10px 15px'
                                        }}
                                    >
                                        <label id="name-labels" key={lbindex}>
                                            <input
                                                type="checkbox"
                                                onChange={() => handleDefaultFilterChange('label', lb.id)}
                                                checked={selectedDefaultFilters.label.includes(lb.id)}
                                            />
                                            <span style={{color: lb.color, filter: 'brightness(2)'}}>
                                                {lb.name}
                                            </span>
                                        </label>
                                    </label>
                                ))}
                            </div>
                            {label.length > 6 && (
                                <button className="open-more-filter"
                                    onClick={() => setShowAllLabel(!showAllLabel)}
                                >
                                    {showAllLabel ? `-${label.length - 6}` : `+${label.length - 6}`}
                                </button>
                            )}
                        </div>: null
                    }
                    {performer.length > 0 ?
                        <div>
                            <div style={{display: 'flex'}}>
                                <img
                                    id="img-component-task"
                                    src={apiUrlFrontend + 'performer-white-icon.svg'}
                                    alt=''
                                    style={{filter: resolvedTheme === 'light' ? 'invert(70%)': null}}
                                />
                                <label className="label-component-filter">
                                    Исполнитель
                                </label>
                            </div>
                            <div className="data-default-filter">
                                {[
                                    ...performer.filter((pf) =>
                                    selectedDefaultFilters.performer.includes(pf.id)
                                    ),
                                    ...performer.filter(
                                    (pf) => !selectedDefaultFilters.performer.includes(pf.id)
                                    ),
                                ].slice(0, showAllPerformer ? performer.length : 6).map((pf, pfindex) => (
                                    <label className="data-component-add" key={pfindex}
                                        style={{ 
                                            background: selectedDefaultFilters.performer.includes(pf.id) ? '#087F8C': 'var(--base-btn-color)', padding: '10px 15px 10px 15px',
                                        }}
                                    >
                                        <GetOnlineUser user={pf.id}/>
                                        <img id="component-task-image-performer"
                                            src= {pf.image ? pf.image: apiUrlFrontend + "person-square.svg" }
                                            alt=""
                                        />
                                        <input
                                            type="checkbox"
                                            key={pfindex}
                                            name="performers"
                                            onChange={() => handleDefaultFilterChange('performer', pf.id)}
                                            checked={selectedDefaultFilters.performer.includes(pf.id)}
                                        />
                                        <span style={{marginLeft: '10px', color: 'white', fontFamily: 'Roboto, sans-serif'}}>
                                            {pf.formated_fio ? pf.formated_fio: pf.username}
                                        </span>
                                    </label>
                                ))}
                            </div>
                            {performer.length > 6 && (
                                <button className="open-more-filter"
                                    onClick={() => setShowAllPerformer(!showAllPerformer)}
                                >
                                    {showAllPerformer ? `-${performer.length - 6}` : `+${performer.length - 6}`}
                                </button>
                            )}
                        </div>: null
                    }
                    {group.length > 0 ?
                        <div>
                            <div style={{display: 'flex'}}>
                                <img
                                    id="img-component-task"
                                    src={apiUrlFrontend + 'group-white-icon.svg'}
                                    alt=""
                                    style={{filter: resolvedTheme === 'light' ? 'invert(70%)': null}}
                                />
                                <label className="label-component-filter">
                                    Состояние
                                </label>
                            </div>
                            <div className="data-default-filter">
                                {[
                                    ...group.filter((gp) =>
                                    selectedDefaultFilters.status.includes(gp.title)
                                    ),
                                    ...group.filter(
                                    (gp) => !selectedDefaultFilters.status.includes(gp.title)
                                    ),
                                ].slice(0, showAllGroup ? group.length : 6).map((gp, gpindex) => (
                                    <label className="data-component-add" key={gpindex}
                                        style={{ 
                                            background: selectedDefaultFilters.status.includes(gp.title) ? '#087F8C': 'var(--base-btn-color)', padding: '10px 15px 10px 15px',
                                        }}
                                    >
                                        <label id="name-labels" key={gpindex}>
                                            <input
                                                type="checkbox"
                                                key={gpindex}
                                                onChange={() => handleDefaultFilterChange('status', gp.title)}
                                                checked={selectedDefaultFilters.status.includes(gp.title)}
                                            />
                                            <span style={{color: 'white'}}>
                                                {gp.title}
                                            </span>
                                        </label>
                                    </label>
                                ))}
                            </div>
                            {group.length > 6 && (
                                <button className="open-more-filter"
                                    onClick={() => setShowAllGroup(!showAllGroup)}
                                >
                                    {showAllGroup ? `-${group.length - 6}` : `+${group.length - 6}`}
                                </button>
                            )}
                        </div>: null
                    }
                    <div>
                        <div style={{display: 'flex'}}>
                            <img
                                id="img-component-task"
                                src={apiUrlFrontend + 'priority-white-icon.svg'}
                                alt=""
                                style={{filter: resolvedTheme === 'light' ? 'invert(70%)': null}}
                            />
                            <label className="label-component-filter">
                                Приоритет
                            </label>
                        </div>
                        <div className="data-default-filter">
                            {priority.map((pr, prindex) => (
                                <label className="data-component-add" key={prindex}
                                    style={{ 
                                        background: selectedDefaultFilters.priority.includes(pr) ? '#087F8C': 'var(--base-btn-color)', padding: '10px 15px 10px 15px',
                                    }}
                                >
                                    <label id="name-labels" key={prindex}>
                                        <input
                                            type="checkbox"
                                            key={prindex}
                                            onChange={() => handleDefaultFilterChange('priority', pr)}
                                            checked={selectedDefaultFilters.priority.includes(pr)}
                                        />
                                        <span style={{color: 'white'}}>
                                            {pr}
                                        </span>
                                    </label>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                <div id="div-bottom-button-default-filter">
                    <button id="openAccessDelete"
                        onClick={() => handleDeleteDefaultFilters()}
                    >
                        Сбросить
                    </button>
                    <button id="confirmtask-create"
                        onClick={() => handleSetDefaultFilters()}
                    >
                        Применить
                    </button>
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={openModalChangeBoard}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "group-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Доска
                    </label>
                </div>
                <div className="div-data-component-list" onScroll={handleScrollBoards}>
                    {board.length > 0 ? board.map((item, index) => (
                        <button id="select-board" key={index} onClick={(event) => handleOpenChangeGroup(event, item)}>
                            {item.title}
                        </button>)): null
                    }
                    {loadingBoard && <div style={{color: "white"}}> 
                        <span class="loader"></span>
                    </div>}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={openModalChangeGroupTask}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "group-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Состояние
                    </label>
                </div>
                <div className="div-data-component-list">
                    {allowCreateTaskInStatusCompleted ? changegroup.length > 0 ? 
                        changegroup.map((item, index) => (
                            <button id="select-board" key={index} onClick={(event) => handleRedirectToCreateTask(event, item)}>
                                {item.title}
                            </button>
                        )): null:
                        changegroup.length > 0 ? changegroup.map((item, index) => (
                        item.system_status_name !== 'Completed' ? 
                            <button id="select-board" key={index} onClick={(event) => handleRedirectToCreateTask(event, item)}>
                                {item.title}
                            </button>: null
                        )): null
                    }
                </div>
            </Modal>
            <div id="divforfilter">
                <div id="search-and-reset">
                    <input id="search" style={{outline: 'none'}}
                        placeholder="Введите текст, чтобы найти задачи"
                        onClick={handleTextareaClick}
                        value={searchItem}
                        onChange={handleTextareachange}
                    />
                    <button id="button-reset-filter"
                        onClick={handleResetFilter}
                    >
                        <img
                            id="reset-icon"
                            src={apiUrlFrontend + "reset-btn.svg"}
                            style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                            alt=""
                        />
                    </button>
                </div>
                <CustomSelect
                    name="label" 
                    options={label}
                    onFilterChange={(selected) => handleFilterChange('label', selected)}
                />
                <CustomSelect
                    name="performer"
                    options={performer}
                    onFilterChange={(selected) => handleFilterChange('performer', selected)}
                />
                <CustomSelect
                    name="status"
                    options={group}
                    onFilterChange={(selected) => handleFilterChange('status', selected)}
                />
                <div id="open-calendar">
                    <button id="open-calendar-btn"
                        onClick={handleopenModalCalendardeadline}
                    >
                        Дата создания
                    </button>
                    <img style={{width: "20px", height: "20px", marginLeft: "auto", filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                        onClick={() => handleSortedDateTopBottom("created_at")}
                        alt=""
                        src={apiUrlFrontend + "btn-sort.svg"}
                    />
                </div>
                <div id="open-calendar">
                    <button id="open-calendar-btn"
                        onClick={handleopenModalCalendardeadline}
                    >
                        Срок сдачи
                    </button>
                    <img style={{width: "20px", height: "20px", marginLeft: "auto", filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                        onClick={() => handleSortedDateTopBottom("deadline")}
                        alt=""
                        src={apiUrlFrontend + "btn-sort.svg"}
                    />
                </div>
                <CustomSelect
                    name="priority" 
                    options={priority}
                    onFilterChange={(selected) => handleFilterChange('priority', selected)}
                />
                <img
                    onClick={(e) => handleOpenModalDefaultFilter(e)}
                    id="default-sorted-icon"
                    src={apiUrlFrontend + "default-sorted-white-icon.svg"}
                    style={{filter: resolvedTheme === 'light' ? 'invert(70%)': null}}
                    alt=""
                />
                {canAddTask ?
                    <img
                        onClick={(e) => handleOpenModalBoard(e)}
                        id="default-sorted-icon"
                        src={apiUrlFrontend + "plus-btn.svg"}
                        style={{filter: resolvedTheme === 'light' ? 'invert(70%)': null}}
                        alt=""
                    />: null
                }
            </div>
            {loadingTask || loadingTaskFilter || firstLoading ?
                <div className="divforlisttasks">
                    <div style={{minWidth: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <span class="loader"></span>
                    </div>
                </div>:
                filteredTasks.length > 0 ?
                    <div id="divforlisttasks" onScroll={handleScroll}>
                        {filteredTasks.map((task, index) => (
                            <div id="tasksdivlist" key={index} style={{borderLeft: (new Date() - new Date(task.created_at)) < 86400000
                                ? "5px solid rgb(146, 253, 178)"
                                : "none"}}>
                                <button id="tasks"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Перейти к задаче"
                                    onClick={(event) => handleTaskDetailGo(event, task.task_slug)}
                                    onMouseDown={(e) => handleTaskDetailGoNewPin(e, task.task_slug)}
                                >
                                    {task.title}
                                </button>
                                <div id="divforalllabel">
                                    {label.length > 0 ? 
                                        task.label.map(lb => lb).slice(0, 3).map((label, labelIndex) => (
                                            <div key={labelIndex} id="divforlabeltitle">
                                                <div id="circle"
                                                    style={{background: label.color, marginRight: "5px"}}
                                                />
                                                {label.name.slice(0, 15)}
                                            </div>
                                        )):null
                                    }
                                </div>
                                <div id="divforallperformer">
                                    {task.formated_performer_fio ?
                                        task.formated_performer_fio.slice(0, 3).map((pf_fio, pf_fio_Index) => (
                                            <div key={pf_fio_Index} id="divforperformerusername">
                                                <label id="labelwithperformerusername">{performer.length > 0 ? pf_fio.slice(0, 20): null}</label>
                                            </div>
                                        )):
                                        task.performer.slice(0, 3).map((per_username, performerIndex) => (
                                            <div key={performerIndex} id="divforperformerusername">
                                                <label id="labelwithperformerusername">{performer.length > 0 ? per_username.slice(0, 20): null}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                <label id="grouptasklist">
                                    {group.map(lt => lt.title).includes(task.group_name) ?
                                        task.group_name.slice(0, 28): null
                                    }
                                </label>
                                <label id="datetimetaskslist">
                                    {new Date(task.created_at.replace(' ', 'T') + 'Z').toLocaleString().slice(0, 17)}
                                </label>
                                {task.deadline ?
                                    <label id="deadlinetasklist" 
                                        style={{ color: ((new Date(task.deadline.substring(0, 10)) - new Date()) / 86400000) <= 3 ?
                                                'rgba(175, 52, 52, 1.00)': ((new Date(task.deadline.substring(0, 10)) - new Date()) / 86400000) <= 7 ? 
                                                'rgba(220, 136, 0, 1.00)': 'rgba(100, 120, 214, 1.00)', fontWeight: 'bold'
                                        }}
                                    >
                                        {new Date(task.deadline).toLocaleString().slice(0, 17)}
                                    </label>: 
                                    <label id="deadlinetasklist" 
                                        style={{fontWeight: 'bold', color: 'rgb(63, 127, 232)'}}
                                    >
                                        Не назначен
                                    </label>
                                }
                                {task.priority ?
                                    <label id="prioritytasklist"
                                        style={{ color: task.priority <=3 ? 'rgba(100, 120, 214, 1.00)':
                                                task.priority <=7 ? 'rgba(220, 136, 0, 1.00)':
                                                'rgba(175, 52, 52, 1.00)'
                                        }}
                                    >
                                        {task.priority}
                                    </label>: 
                                    <label id="prioritytasklist"
                                        style={{fontWeight: 'bold', fontSize: '18px', color: 'rgb(63, 127, 232)'}}
                                    >
                                        Нет
                                    </label>
                                }
                                 {/* <label className='Number-task'
                                    style={{marginTop: '-5px'}}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={'Скопировать ссылку на задачу ' + task.task_slug}
                                    onClick={() => handleCopyInvitelink()}
                                >
                                    <img id="copy-link"
                                        src={apiUrlFrontend + "copy.svg"}
                                        alt=""
                                        style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                                    />
                                </label> */}
                                <button id="go-to-board"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={task.board_name}
                                    onClick={() => handleClickGotoBoard(task.task_slug)}
                                    onMouseDown={(e) => handleClickGotoBoardNewPin(task.task_slug, e)}
                                >
                                    На доску
                                </button>
                            </div>
                        ))}
                    </div>:
                <div id="null-tasks" style={{height: screenHeight - 200}}>
                    Задачи не найдены
                </div>
            }
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >   
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                </div>
                <div className="div-calendar">
                    <Calendar
                        value={dateRange}
                        onChange={handleDateChange}
                    />
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendardeadline}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >    
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                </div>
                <div className="div-calendar">
                    <Calendar  
                        value={dateRangedeadline}
                        onChange={handleDateChangedeadline}
                    />
                </div>
            </Modal>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
    );
}

export default TasksList;