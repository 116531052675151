import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userData: null,
        onlineUsers: null
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setOnlineUsers: (state, action) => {
            state.onlineUsers = action.payload;
        },
    },
});

export const {
    setUserData,
    setOnlineUsers,
} = userSlice.actions;
export default userSlice.reducer;