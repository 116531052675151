import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modalSlice';
import userReducer from './userSlice';

const store = configureStore({
    reducer: {
        modal: modalReducer,
        user: userReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;