
let slugBoard = '';
let openModalCreateTask = false;
let countTask = '';

const setSlugBoard = (newSlug) => {
    slugBoard = newSlug;
};

export { slugBoard, setSlugBoard };

const setOpenModalCreateTask = (newOpenModal) => {
    openModalCreateTask = newOpenModal;
    if (newOpenModal) {
        return true
    }
    else {
        return false
    }
}; 

export { openModalCreateTask, setOpenModalCreateTask};