import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/Sidebar.css';
import { Tooltip } from 'react-tooltip';
import api from '/app/frontend/src/api';
import { openModalCreateTask, slugBoard } from './components/CustomContextToHeader';
import ChoiceOrganization from './ChoiceOrganizationPage';
import Note from './components/Note';
import { setUserData } from '../redux/userSlice';
import { useDispatch } from 'react-redux';
import ThemeSwitcher from '../ThemeSwitcher';
import { useTheme } from '../ThemeProvider';
import { motion, useAnimation } from 'framer-motion';

const Sidebar = () => {
    const controls = useAnimation();
    const { resolvedTheme } = useTheme();
    const dispatch = useDispatch();
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const location = useLocation();
    const [openChangeOrganization, setOpenChangeOrganization] = useState(true);
    const [modalIsOpenNotes, setIsOpenNotes] = useState(false);
    const token = localStorage.getItem('token');
    const [user, setUser] = useState('');
    const [boardname, setBoardName] = useState('');
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    // const [numberoftasks, setTasksnumber] = useState([]);
    const [author, setauthor] = useState('');
    const [checkRole, setCheckRole] = useState(false);
    const [checkPermission, setCheckPermission] = useState(false);
    const [addRole, setAddRole] = useState(false);
    const [modalIsOpenPhone, setModalIsOpenPhone] = useState(false);
  
    function closeModal() {
      setModalIsOpenPhone(false);
    };
    const handleGoToBoards = (event) => {
        window.location.href = "/main";
    };
    const handleOpenNotes = (event) => {
      setIsOpenNotes(true)
    };
    const handleGoToBoardsNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/main");
      }
    };
    const handleGoToAuth = (event) => {
        window.location.href = "/login";
    };
    const handleGoToRegist = (event) => {
      window.location.href = "/registration";
    };
    const handleGoToProfile = (event) => {
        window.location.href = "/profile/" + user.username;
    };
    const handleGoToProfileNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/profile/" + user.username);
      }
    };
    const handleGoToRevertBoard = (event) => {
        window.location.href = `/boards/${slugBoard}`;
    };
    const handleGoToRevertBoardNewPin = (e) => {
      if (e.button === 1) {
        window.open(`/boards/${slugBoard}`);
      }
  };

  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
    const handleGoToTask = (event) => {
        window.location.href = "/tasks/list/";
    };
    const handleGoToTaskNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/tasks/list/");
      }
    };

    const handleCopyInvitelink = () => {
      try {
          navigator.clipboard.writeText(window.location);
          alert("Ссылка на задачу скопирована в буфер обмена");
      } catch (err) {
          console.error('Не удалось скопировать: ', err);
      }
    };

    function getnumbertask() {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        return (
          <label className='Number-task'
            data-tooltip-id="sidebar-tooltip"
            data-tooltip-content="Скопировать ссылку на задачу"
            onClick={() => handleCopyInvitelink()}
          >
              <div>
                <img id="copy-link"
                    src={apiUrlFrontend + "copy.svg"}
                    alt=""
                    style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                />
              </div>
              <div>Задача</div>
              {task_pk.split('-').slice(-1)[0]}
          </label>
          );
    };

    const handleGoToSettings = (event) => {
      window.location.href = "/settings";
    };
    const handleGoToSettingsNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/settings");
        }
    };

    const handleGoToUnauth = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
        window.location.href = '/login';
    };
  
    function CheckAuth() {
        if (user !== '') {
            if (location.pathname === '/' || location.pathname==='/login' || location.pathname==='/registration' || location.pathname==='/registration/')
            {
              return(<button id="btn-add-new-data-component-task" onClick={() => window.location.href = '/main'}>{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
            else {
                return(
                    <button onMouseDown={(e) => handleGoToProfileNewPin(e)} onClick={handleGoToProfile} id="redirectProfile">
                        <img id="username-image-asidebar" src={user.image ? user.image : apiUrlFrontend + 'person-square.svg'} alt=''/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                            <span id='username-asidebar'>{user.formated_fio ? user.formated_fio: user.username}</span>
                            <span id='post-asidebar'>{user.post_name}</span>
                        </div>
                    </button>
                );
            }
        }
      else {
        return(
            <div id="div-with-auth-and-reg-button">
                <button onClick={handleGoToAuth} id="btn-auth">Войти</button>
                <button onClick={handleGoToRegist} id='btn-auth' >Зарегистрироваться</button>
            </div>
        );
      }
    };
    function handleCloseNotes () {
      setIsOpenNotes(false);
    };

    const handleGoToTechnicalSupport = (event) => {
      window.location.href = "/main-info";
    };
    const handleGoToTechnicalSupportNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/main-info");
        }
    };

    React.useEffect(() => {
      const CheckPermission = async () => {
        await api.get(`${apiUrl}api/v1/user/permissions/`, {
          headers: {
              'Authorization': 'Bearer ' + token
          },
        }).then((response) => {
          if (response.status === 200) {
              if (location.pathname.startsWith('/create-task')){
                if (response.data.includes('owner_organization') || response.data.includes('Can add Задача')) {
                }
                else{
                  window.location.href = '/main';
                }
              }
              if (location.pathname.startsWith('/settings')){
                if (response.data.includes('owner_organization') || response.data.includes('Can view group')
                  || (response.data.includes('Can add group') && response.data.includes('Can view permission'))
                  ) {}
                else{
                  window.location.href = '/main';
                }
              }
              if (response.data.includes('owner_organization')){
                setCheckRole(true);
              }
              if (response.data.includes('Can view permission')){
                setCheckPermission(true);
              }
              if (response.data.includes('Can add group')){
                setAddRole(true);
              }
          }
      }).catch(error => {
          console.error(error);
      });
      }
          api.get(`${apiUrl}api/identity/auth/users/me/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
            if (response.status === 200) {
              setUser(response.data);
              dispatch(setUserData(response.data));
              if (response.data.username === author) {
                localStorage.setItem('author', author);
              }
              else {
                localStorage.setItem('author', '');
              }
              localStorage.setItem('username', response.data.username);
              localStorage.setItem('can_changed_task', response.data.id)
            }
          }).catch(error =>{
            console.error(error);
          });
    CheckPermission();
    // eslint-disable-next-line
  }, []);
  const [openAsidebarMobile, setOpenAsidebarMobile] = useState(false);
  function isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };
  const handleHover = (isOver) => {
    if (!isMobileDevice()) {
      controls.start({ width: isOver ? 350 : 80 });
    }
  };

  if (location.pathname==='/login' || location.pathname==='/' 
  || location.pathname==='' || location.pathname==='/create-appeal' 
  || location.pathname==='/about' || location.pathname==='/privacy-policy'
  || location.pathname==='/developers-info/' || location.pathname==='/developers-info'
  || location.pathname==='/create-appeal/' 
  || location.pathname==='/about/' || location.pathname==='/privacy-policy/'
  || location.pathname==='/prices-info' || location.pathname==='/prices-info/'
  || location.pathname==='/solutions-info' || location.pathname==='/solutions-info/'
  || location.pathname==='/registration' || location.pathname==='/registration/'
  || location.pathname.startsWith('/invite-organization/') 
  || location.pathname.startsWith('/change-password/')) {
    return (
      <header className="header">
            <div id="companyName">
              <Link onClick={token ? () => window.location.href= '/main': () => window.location.href= '/'} id="project-name">
                <img id="logo-tz" src={resolvedTheme === 'dark' ? apiUrlFrontend + 'logo-light.svg' : apiUrlFrontend + 'logo-dark.svg'} alt=''/>
              </Link>
            </div>
            {CheckAuth()}
      </header>
    );
  } 
  else if (location.pathname==='/main' ||
        location.pathname.startsWith('/profile/') || 
        location.pathname==='/create-task' || 
        location.pathname==='/settings' || location.pathname.startsWith('/choice-organization')
        || location.pathname==='/main-info' || location.pathname==='/main-info/' || location.pathname === '/tasks/list/' || location.pathname.startsWith('/boards/') || location.pathname.startsWith('/task/')){
        return(
            isMobileDevice() && openAsidebarMobile===false ?
               <button id="btn-closed-asidebar-icon" onClick={() => setOpenAsidebarMobile(true)}>
                  <img id="open-asidebar-icon" src={apiUrlFrontend + 'open-asidebar-mobile-icon.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
              </button>
              :
            <motion.aside className="sidebar"
              animate={controls}
              onMouseEnter={() => handleHover(true)}
              onMouseLeave={() => handleHover(false)}
              transition={{ duration: 0.3 }}
            >
                <button id="btn-open-asidebar-icon" onClick={() => setOpenAsidebarMobile(false)}>
                    <img id="open-asidebar-icon" src={apiUrlFrontend + 'arrow-return-left.svg'} alt=''/>
                </button>
                {location.pathname.startsWith('/task/') ?
                  getnumbertask()
                : null}
                <button onMouseDown={(e) => handleGoToProfileNewPin(e)} onClick={handleGoToProfile} id="redirectProfile">
                    <img id="username-image-asidebar" src={user.image ? user.image : apiUrlFrontend + 'person-square.svg'} alt=''/>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                        <span id='username-asidebar'>{user.formated_fio ? user.formated_fio: user.username}</span>
                        <span id='post-asidebar'>{user.post_name}</span>
                    </div>
                </button>
                <div className="aside-line"/>
                <div className="div-main-btn-sidebar">
                    <button className="btn-aside"
                        onMouseDown={(e) => handleGoToBoardsNewPin(e)}
                        onClick={handleGoToBoards}
                    >
                        <img id="img-aside-icon" src={apiUrlFrontend + 'active-board-icon.svg'} alt="" style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                        <span style={{marginLeft: '20px'}}>Активные доски</span>
                    </button>
                    <button className="btn-aside"
                        onMouseDown={(e) => handleGoToTaskNewPin(e)}
                        onClick={handleGoToTask}
                    >
                        <img id="img-aside-icon" src={apiUrlFrontend + 'task-icon.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                        <span style={{marginLeft: '20px'}}>Задачи</span>
                    </button>
                    {checkRole === true || (checkPermission === true && addRole === true) ?
                            <button className="btn-aside"
                                onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                                onClick={(event) => handleGoToSettings(event)} 
                            >
                                <img id="img-aside-icon" src={apiUrlFrontend + 'settings-icon.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                                <span style={{marginLeft: '20px'}}>Настройки</span>
                            </button>
                        : null
                    }
                    <button className="btn-aside"
                        onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)}
                        onClick={(event) => handleGoToTechnicalSupport(event)}
                    >
                        <img id="img-aside-icon" src={apiUrlFrontend + 'statistics-icon.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                        <span style={{marginLeft: '20px'}}>Обзор задач</span>
                    </button>
                    <button className="btn-aside"
                        onClick={handleOpenNotes}
                    >
                        <img id="img-aside-icon" src={apiUrlFrontend + 'note-icon-side.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                        <span style={{marginLeft: '20px'}}>Заметки</span>
                    </button>
                </div>
                <div className="aside-line"/>
                {user ? user.available_organization.length > 1 ?
                    <button className="btn-aside" style={{marginLeft: '20px'}}
                        onClick={() => setOpenChangeOrganization(!openChangeOrganization)}
                    >
                        <img id="img-aside-icon" src={apiUrlFrontend + 'organization-icon.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                        <span style={{marginLeft: '20px'}}>Организации</span>
                    </button>
                : null : null}
                {user ? user.available_organization.length > 1 && openChangeOrganization ?
                  <span>
                      <ChoiceOrganization/>
                  </span>
                  : null : null}
                  <Note
                    modalIsOpenNotes={modalIsOpenNotes}
                    onClose={handleCloseNotes}
                  />
                <div className="aside-line" style={{marginTop: 'auto'}}/>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px', marginTop: '5px'}}>
                  <button className="btn-aside" style={{marginLeft: '25px', maxWidth: '110px'}} onClick={() => handleGoToUnauth()}>
                      <img id="img-aside-icon" src={apiUrlFrontend + 'unauth-icon.svg'} alt='' style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}/>
                      <span style={{marginLeft: '10px'}}>Выйти</span>
                  </button>
                  <ThemeSwitcher />
                </div>
                <Tooltip 
                    id="sidebar-tooltip"
                    delayShow={750}
                    delayHide={100}
                />
            </motion.aside>
            );
  }
}

export default Sidebar;