import React, { useState } from 'react';
import './styles/HelloPage.css'
import axios from 'axios';

const MainPage = () => {
  const [nameOrganization, setNameOrganization] = useState('');
  const [mail, setMail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const handlenameOrganizationChange = (event) => {
    if (event.target.value.length <= 200) {
      setNameOrganization(event.target.value);
    }
  };  
  const handlemailChange = (event) => {
    setMail(event.target.value);
  };
  const handlephoneNumberChange = (event) => {
    if (/^[+]?\d*$/.test(event.target.value) && event.target.value.length <= 12){
      setphoneNumber(event.target.value);
    } else {
      return;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${apiUrl}api/send-appeal/`, {
      name_organization: nameOrganization.trim(),
      mail: mail,
      phone_number: phoneNumber
    })
    .then(response => {
      alert('Заявка отправлена');
      setNameOrganization('');
      setMail('');
      setphoneNumber('');
    })
    .catch(error =>{
      console.error(error);
    });
  };

  return (
    <div className="hello-page">
            <div id="main-txt-div">
              <text id="mainText">Управляй временем</text>
              <text id="miniText">Интеллектуальная система управления задачами, которая функционирует как ваш персональный организатор, оптимизируя эффективность и удобство ведения дел.</text>
              <form onSubmit={handleSubmit} className="application-form">
                <div className="form-group">
                  <h2 id="maintextinform">Оставьте заявку на подключение</h2>
                  <input
                    type="text"
                    id="nameOrganization"
                    value={nameOrganization}
                    onChange={handlenameOrganizationChange} 
                    placeholder='Название организации'
                    required
                  />
                  <input
                    type="mail"
                    id="mail"
                    value={mail}
                    onChange={handlemailChange}
                    placeholder='Ваш почтовый адрес'
                    required
                  />
                  <input
                    type="phoneNumber"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={handlephoneNumberChange}
                    placeholder='Номер телефона'
                    required
                  />
                </div>
                <button id="send" type="submit">Подключиться</button>
              </form>
          </div>
          <div className="div-info-color-bg">
            <div className="div-info-lists-tz">
              <img id="img-info-lists" src={apiUrlFrontend + '/img-lists.svg'} alt="" ></img>
              <p id="text-info-lists">Создавайте списки дел, начиная с простых задач и переходя к сложным проектам с множественными этапами и подзадачами. Каждая подзадача может быть разбита на конкретные шаги для более точного планирования и выполнения действий</p>
              <div id="gradient-one"></div>
            </div>
            <div className="div-info-filtr">
              <img id="img-info-filtr" src={apiUrlFrontend + '/img-filtr.svg'} alt="" ></img>
              <p id="text-info-filtr">В <b>ТвояЗадача</b> вы можете создавать метки для быстрой фильтрации задач по приоритетам и категориям, устанавливать даты сдачи для четкого контроля сроков выполнения и назначать исполнителей, чтобы распределить ответственность за задачи внутри команды.</p>
              <div id="gradient-two"></div>
            </div>
            <div className="div-info-user">
              <img id="img-info-user" src={apiUrlFrontend + '/img-user.svg'} alt=""></img>
              <p id="text-info-user">В приложении <b>ТвояЗадача</b> вы можете легко редактировать профиль, указывая должность и организацию, чтобы коллеги быстрее находили вас и понимали вашу роль в проектах</p>
              <div id="gradient-three"></div>
            </div>
          </div>
          <div className="carousel-info">
            <img id="img-carousel" src={apiUrlFrontend + '/card-01.svg'} alt="" ></img>
            <img id="img-carousel" src={apiUrlFrontend + '/card-02.svg'} alt="" ></img>
            <img id="img-carousel" src={apiUrlFrontend + '/card-03.svg'} alt="" ></img>
            <img id="img-carousel" src={apiUrlFrontend + '/card-04.svg'} alt="" ></img>
          </div>
    </div>
  );
};

export default MainPage;
