import React, { useState, useEffect, useCallback, useRef } from 'react';
import './styles/DetailTask.css';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { useDropzone } from 'react-dropzone';
import UserFile from './components/UserFile';
import { setSlugBoard } from './components/CustomContextToHeader';
import CKeditor5 from './components/CKeditor5';
import api from '/app/frontend/src/api';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useSelector } from 'react-redux';
import GetOnlineUser from './components/CustomOnlineUser';
import CustomPrioritySelect from './components/CustomPrioritySelect';
import { useTheme } from '../ThemeProvider';

const DetailTask = () => {
    const userData = useSelector((state) => state.user.userData);
    const [firstLoading, setFirstLoading] = useState(true);
    const modalTaskId = useSelector((state) => state.modal.modalTaskId);
    const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;
    const { resolvedTheme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [loadingDeleteTask, setLoadingDeleteTask] = useState(false);
    const socketRef = useRef(null);
    const commentsEndRef = useRef(null);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const wsUrl = process.env.REACT_APP_WS_URL;
    const token = localStorage.getItem('token');
    const containerRef = useRef(null);
    const containerRefModal = useRef(null);
    const username = localStorage.getItem('username');
    const [taskhistory, setTaskHistory] = useState([]);
    const [task, setTask] = useState([]);
    const [selectedtask, setselectedtask] = useState([]);
    const [commentuser, setCommentUser] = useState('');
    const [comments, SetComments] = useState([]);
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    const [totalStars] = useState(10);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [descriptiontask, setDescriptionTask] = useState('');
    const [titletask, setTitleTask] = useState('');
    const [isActiveComment, setIsActiveComment] = useState(false);
    const [isActiveHistory, setIsActiveHistory] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenResponsible, setIsOpenResponsible] = useState(false);
    const [modalIsOpenLabel, setIsOpenLabel] = useState(false);
    const [modalIsOpenGetLabel, setIsOpenGetLabel] = useState(false);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [modalIsOpenCalendarStartDate, setIsOpenCalendarStartDate] = useState(false);
    const [modalIsOpenCalendarReminder, setIsOpenCalendarReminder] = useState(false);
    const [modalIsOpenUniteSubtasks, setModalIsOpenUniteSubtasks] = useState(false);
    const [performer, setperformer] = useState([]);
    const [label, setLabel] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [selectedResponsibles, setSelectedResponsibles] = useState([]);
    const [selectedColor, setSelectedColor] = useState('');
    const [namelabel, setNameLabel] = useState('');
    const [group, setGroup] = useState([]);
    const [groups, setGroups] = useState([]);
    const [boardSlug, setBoardSlug] = useState([]);
    const [handledeletelabel, sethandledeletelabel] = useState(false);
    const [relatedSubtasks, setRelatedSubtasks] = useState([]);
    const [whoCanEditTask, setWhoCanEditTask]= useState(null);
    const timerRef = useRef(null);
    const webcolors = [
        "#FF0000", "#FF7F50", "#FFD700", "#ADFF2F",
        "#32CD32", "#20B2AA", "#00CED1", "#4682B4",
        "#1E90FF", "#6A5ACD", "#9370DB", "#FF1493",
        "#A9A9A9", "#FFFFFF", "#000000", "#808080",
    ];
    const [value, onChangeCalendar] = useState(new Date());
    const [valueReminder, onChangeCalendarReminder] = useState(new Date());
    const [valueStartDate, onChangeCalendarStartDate] = useState(new Date());
    const [modalIsOpencomment, setmodalIsOpencomment] = useState(false);
    const [changecomment, setChangeComment] = useState([]);
    const [selectedcomment, setSelectedComment] = useState("");
    const [files, setFiles] = useState([]);
    const [openModalChangeGroup, setopenModalChangeGroup] = useState(false);
    const [isactiveupdatetasks, setisactiveupdatetasks] = useState(false);
    const [filtertasklist, setfiltertasklist] = useState([]);
    const [selectedSubtasks, setselectedSubtasks] = useState([]);
    const [modalIsOpenAnswerComment, setmodalIsOpenAnswerComment] = useState(false);
    const [selectedcommentanswer, setselectedcommentanswer] = useState([]);
    const [answercommenttext, setanswercommenttext] = useState("");
    const [hintselectuserselect, sethintselectuserselect] = useState(false);
    const [hintuserfilter, sethintuserfilter] = useState([]);
    const [canChangeTask, setCanChangeTask] = useState(false);
    const [canViewComment, setViewComment] = useState(false);
    const [canViewHistoryTask, setCanViewHistoryTask] =  useState(false);
    const [canAddComment, setCanAddComment] = useState(false);
    const [canChangeComment, setCanChangeComment] = useState(false);
    const [canDeleteComment, setCanDeleteComment] = useState(false);
    const [canDeleteTask, setCanDeleteTask] = useState(false);
    const [canViewLabel, setCanViewLabel] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [canDeleteFile, setCanDeleteFile] = useState(false);
    const [openModalChangeBoard, setopenModalChangeBoard] = useState(false);
    const [board, setBoard] = useState([]);
    const [page, setPage] = useState(1);
    const [loadingSubtask, setLoadingSubtask] = useState(false);
    const [loadingBoards, setLoadingBoards] = useState(false);
    const [hasMoreSubTask, setHasMoreSubTask] = useState(true);
    const [hasMoreBoards, setHasMoreBoards] = useState(true);
    const [openModalChangeGroupTask, setopenModalChangeGroupTask] = useState(false);
    const [changegroup, setchangegroup] = useState([]);
    const [pageForTask, setPageForTask] = useState(1);
    const [pageForFilterTask, setPageForFilterTask] = useState(1);
    const [loadingTaskFilter, setLoadingTaskFilter] = useState(false);
    const [hasMoreTaskFilter, setHasMoreTaskFilter] = useState(true);
    const [prevTaskTitleFind, setPrevTaskTitleFinde] = useState("");
    const [activeSeeSubtasks, setActiveSeeSubtasks] = useState(true);
    const [activeSeeHistory, setActiveSeeHistory] = useState(false);
    const [activeSeeComments, setActiveSeeComments] = useState(false);
    const split = window.location.pathname.split('/');
    const task_pk = modalTaskId ? modalTaskId: split[2];
    const isActiveUpdateTasksRef = useRef(isactiveupdatetasks);
    const priorityOptions = Array.from({ length: totalStars }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`
    }));
    const [reminderUser, setReminderUser] = useState([]);

    useEffect(() => {
        isActiveUpdateTasksRef.current = isactiveupdatetasks;
    }, [isactiveupdatetasks]);
    const [hhTime, setHHTime] = useState('00');
    const [mmTime, setMMTime] = useState('00');
    const [hhTimeDeadline, setHHTimeDeadline] = useState('00');
    const [mmTimeDeadline, setMMTimeDeadline] = useState('00');
    const [hhTimeReminder, setHHTimeReminder] = useState('00');
    const [mmTimeReminder, setMMTimeReminder] = useState('00');
    const handleChangeHHTime = (event) => {
        setHHTime(event.target.value);
        const utcDateTime = convertToUTC(format(new Date(valueStartDate), 'yyyy-MM-dd'), `${event.target.value}:${mmTime}`);
        if (selectedtask.date_start_task === utcDateTime) {
            alert("Текущая дата уже выбрана");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'date_start_task': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };
    
    const handleChangeMMTime = (event) => {
        setMMTime(event.target.value);
        const utcDateTime = convertToUTC(format(new Date(valueStartDate), 'yyyy-MM-dd'), `${hhTime}:${event.target.value}`);
        if (selectedtask.date_start_task === utcDateTime) {
            alert("Текущая дата уже выбрана");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'date_start_task': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    const handleChangeHHTimeReminder = (event) => {
        setHHTimeReminder(event.target.value);
        if (!reminderUser.id) {
            handleChangeReminderPost(valueReminder, event.target.value, mmTimeReminder);
        }
        else {
            const utcDateTime = convertToUTC(format(new Date(valueReminder), 'yyyy-MM-dd'), `${event.target.value}:${mmTimeReminder}`);
            api.patch(`${apiUrl}api/v1/tasks/${task_pk}/reminders/${reminderUser.id}/`, {
                'date_time_send_notification': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
                closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    const handleChangeMMTimeReminder = (event) => {
        setMMTimeReminder(event.target.value);
        const utcDateTime = convertToUTC(format(new Date(valueReminder), 'yyyy-MM-dd'), `${hhTimeReminder}:${event.target.value}`);
        if (!reminderUser.id) {
            handleChangeReminderPost(valueReminder, hhTimeReminder, event.target.value);
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/${task_pk}/reminders/${reminderUser.id}/`, {
                'date_time_send_notification': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
                closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    function handleChangeReminderPost (date, hh, mm) {
        const utcDateTime = convertToUTC(format(new Date(date), 'yyyy-MM-dd'), `${hh}:${mm}`);
        api.post(`${apiUrl}api/v1/tasks/${task_pk}/reminders/`, {
            'date_time_send_notification': utcDateTime,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 201){
                updateall();
                closeModal();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const handleChangeHHTimeDeadline = (event) => {
        setHHTimeDeadline(event.target.value);
        const utcDateTime = convertToUTC(format(new Date(value), 'yyyy-MM-dd'), `${event.target.value}:${mmTimeDeadline}`);
        if (selectedtask.deadline === utcDateTime) {
            alert("Текущая дата уже выбрана");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'deadline': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };
    const handleChangeMMTimeDeadline = (event) => {
        setMMTimeDeadline(event.target.value);
        const utcDateTime = convertToUTC(format(new Date(value), 'yyyy-MM-dd'), `${hhTimeDeadline}:${event.target.value}`);
        if (selectedtask.deadline === utcDateTime) {
            alert("Текущая дата уже выбрана");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'deadline': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    const handleOpenGroup = () => {
        closeModal();
        setopenModalChangeGroup(true);
        api.get(`${apiUrl}api/v1/groups/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {
                'board_id': boardSlug
            }
        }).then((response) => { 
            if (response.status === 200) {
                setGroups(response.data);
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status && status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleOpenModalCreateRelatedTask = (event) => {
        localStorage.setItem('grouptaskcreate', task.group_name);
        localStorage.setItem('grouptasksystemstatus', task.system_status_id);
        localStorage.setItem('grouptaskidcreate', task.group);
        localStorage.setItem('relatedtaskid', task.task_id);
        window.location.href = '/create-task';
    };

    const handleOpenChangeGroup = (event, board) => {
        closeModal();
        const performerthistask = performer.filter(pf => task.performer.includes(pf.username)).map(pf => pf.id);
        if (performerthistask.some(tpf => !board.users.includes(tpf))) {
            alert("У исполнителей нет доступа к доске, в которую вы хотите переместить задачу.");
        }
        else {
            api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': board.slug
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setchangegroup(response.data);
                    setopenModalChangeGroupTask(true);
                }
            }).catch(error => {
                if (error.response.status !== 403) {
                }
            });
        }
    };

    const handlechangeboardgrouptask = (event, group) => {
        if (selectedtask.group === group.group_id) {
            alert("Не тыкай пыыросто так");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/${task.task_id}/update-group/`, {
                'group': group.group_id
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    closeModal();
                    updateall();
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    function updateTaskGroup(group_id) {
        console.log(group_id)
        if (selectedtask.group === group_id) {
            alert("Задача уже находится в этом состоянии");
        }
        else{
            api.patch(`${apiUrl}api/v1/tasks/${task_pk}/update-group/`, {
                'group': group_id
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                    closeModal();
                }
            }).catch(error =>{
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };

    const onDropRejected = (rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors[0].code === 'file-too-large') {
                setLoading(false);
                alert('Ошибка: Файл должен быть не более 25MB');
            } else {
                alert('Произошла ошибка при загрузке файла: ' + file.errors[0].message);
            }
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map((file) => file));
        handleUpload(acceptedFiles);
        // eslint-disable-next-line
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop, onDropRejected, maxSize: 26214400, })
    
    function handleUpload(acceptedFiles) {
        if (files){
            setLoading(true);
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('file', file);
            });
            api.post(`${apiUrl}api/upload-file/`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(response => {
                if (response.status === 201) {
                    let existingfiles = localStorage.getItem('selected_files');
                    if (existingfiles === "") {
                        AddFileToTask({task_id: task_pk, 
                            file_id: response.data.map(file => file.id)}
                        );
                    }
                    else{
                        let existing = existingfiles.split(',').map(item => parseInt(item));
                        let allfiles = [...existing, ...response.data.map(file => file.id)];
                        AddFileToTask({task_id: task_pk, 
                            file_id: allfiles}
                        );
                    } 
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
    };

    function AddFileToTask (data){
        api.patch(`${apiUrl}api/v1/tasks/${data.task_id}/`, {
            'file': data.file_id,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        ).then((response) => {
        if (response.status === 200) {
            setFiles([]);
            setLoading(false);
            updateall();
        }
        }).catch(error => {
            console.error(error);         
        });
    };

    const convertToUTC = (date, time) => {
        const localDateTime = new Date(`${date}T${time}:00`);
        const utcDateTime = new Date(localDateTime).toISOString();
        return utcDateTime;
    };

    const handleUpdateTaskDeadline = (date) => {
        const utcDateTime = convertToUTC(format(new Date(date), 'yyyy-MM-dd'), `${hhTimeDeadline}:${mmTimeDeadline}`);
        if (selectedtask.deadline === utcDateTime) {
            alert("Текущая дата уже выбрана");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'deadline': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    const handleUpdateTaskStartDate = (date) => {
        const utcDateTime = convertToUTC(format(new Date(date), 'yyyy-MM-dd'), `${hhTime}:${mmTime}`);
        if (selectedtask.date_start_task === utcDateTime) {
            alert("Текущая дата уже выбрана");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'date_start_task': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    const handleUpdateTaskReminder = (date) => {
        const utcDateTime = convertToUTC(format(new Date(date), 'yyyy-MM-dd'), `${hhTimeReminder}:${mmTimeReminder}`);
        if (!reminderUser.id) {
            handleChangeReminderPost(date, hhTimeReminder, mmTimeReminder);
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/${task_pk}/reminders/${reminderUser.id}/`, {
                'date_time_send_notification': utcDateTime,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) {
                updateall();
                closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    function closeModal() {
        setIsOpen(false);
        setIsOpenLabel(false);
        setIsOpenGetLabel(false);
        setIsOpenCalendar(false);
        setIsOpenCalendarReminder(false);
        setChangeComment([]);
        setopenModalChangeBoard(false);
        setmodalIsOpencomment (false);
        setopenModalChangeGroup(false);
        setModalIsOpenUniteSubtasks(false);
        setmodalIsOpenAnswerComment(false);
        setopenModalChangeGroupTask(false);
        setIsOpenResponsible(false);
        setShowPickerModal(false);
        setIsOpenCalendarStartDate(false);
    };

    function openModalCalendar() {
        closeModal();
        setIsOpenCalendar(true);
    };

    function openModalCalendarStartDate() {
        closeModal();
        setIsOpenCalendarStartDate(true);
    };

    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
        const rawData = window.atob(base64);
        return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
    }
    function getsogl() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.getRegistration().then(function (registration) {
                if (registration && registration.pushManager) {
                    console.log('Сервисный работник уже зарегистрирован. Подписка не требуется.');
                    return;
                }
                return Notification.requestPermission().then(function (permissionResult) {
                    if (permissionResult !== 'granted') {
                        throw new Error('Permission not granted.');
                    }
                    return navigator.serviceWorker.register('/service-worker.js');
                }).then(function (registration) {
                    return navigator.serviceWorker.ready;
                }).then(function (registration) {
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlB64ToUint8Array(publicVapidKey)
                    });
                }).then(function (subscription) {
                    console.log("Подписка завершена, отправляю данные.");
                    const subscriptionData = {
                        endpoint: subscription.endpoint,
                        keys: {
                            p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                            auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))
                        }
                    };
                    return api.post(`${apiUrl}api/subscribe/`, subscriptionData, {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    });
                }).then((response) => {
                    if (response.status === 201) {
                        console.log('Успешная подписка на уведомления.');
                    }
                }).catch(error => {
                    console.error('Ошибка во время подписки:', error);
                });
            }).catch(error => {
                console.error('Ошибка получения регистрации сервисного работника:', error);
            });
        } else {
            console.log("Push-уведомления или Service Worker не поддерживаются в этом браузере.");
        }
    }

    function openModalCalendarReminder() {
        getsogl();
        closeModal();
        setIsOpenCalendarReminder(true);
    };

    function openModal() {
        closeModal();
        setIsOpen(true);
    };

    function openModalResponsible() {
        closeModal();
        setIsOpenResponsible(true);
    };

    function openModalLabel() {
        closeModal();
        setIsOpenLabel(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            return;
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendCommentClick(e);
        }
    };

    function openModalGetLabel() {
        closeModal();
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
                setIsOpenGetLabel(true);
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status && status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        });
    };

    const handleUpdateTitleDescTask = () => {
        api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
            'who_can_edit_task_online': parseInt(userData.id),
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
        if (response.status === 200) {
            setisactiveupdatetasks(true);
        }}).catch(error => {
            console.error(error);
        });
    };

    const handleCreateLabel = (event) => {
        event.preventDefault();
        if (label.some(labeli => labeli.name === namelabel)) {
            alert("Метка с таким названием уже существует");
        }
        else {
            api.post(`${apiUrl}api/v1/labels/`, {
                'name': namelabel,
                'color': selectedColor
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    setSelectedColor("");
                    setNameLabel("");
                    updateall();
                    closeModal();
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };

    const handleChangeNameLabel = (event) => {
        setNameLabel(event.target.value);
    }

    const handleCheckboxChangePerformer = (username) => {
        if (selectedperformers.includes(username)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== username));
            const filterforperformer = performer.filter((performer) => (selectedperformers.filter((selectedPerformer) => selectedPerformer !== username).includes(performer.username)));
            const performerid = filterforperformer.map(performer => performer.id);
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'performer': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                    if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true) {
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== username));
                }
            });
        } else {
            setSelectedPerformers([...selectedperformers, username]);
            const filterforperformer = performer.filter((performer) => [...selectedperformers, username].includes(performer.username));
            const performerid = filterforperformer.map(performer => performer.id);
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'performer': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== username));
                }
            });
        }
    };

    const handleCheckboxChangeResponsible = (username) => {
        if (selectedResponsibles.includes(username)) {
            setSelectedResponsibles(selectedResponsibles.filter((selectedPerformer) => selectedPerformer !== username));
            const filterforperformer = performer.filter((performer) => (selectedResponsibles.filter((selectedPerformer) => selectedPerformer !== username).includes(performer.username)));
            const performerid = filterforperformer.map(performer => performer.id);
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'responsible': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                    if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true) {
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            setSelectedResponsibles([...selectedResponsibles, username]);
            const filterforperformer = performer.filter((performer) => [...selectedResponsibles, username].includes(performer.username));
            const performerid = filterforperformer.map(performer => performer.id);
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'responsible': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true) {
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'label': selectedlabels.filter((selectedLabel) => selectedLabel !== label),
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    updateall();
                    if (isActiveHistory === true) {
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            setSelectedLabels([...selectedlabels, label]);
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'label': [...selectedlabels, label],
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    updateall();
                    if (isActiveHistory === true) {
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleOpenProfileClick = (user) => {
        window.location.href = "/profile/" + user;
    };

    function updateHistory() {
        if (!activeSeeHistory) {
            api.get(`${apiUrl}api/v1/tasks/${task_pk}/historys/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 200) {
                    setTaskHistory(response.data);
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };

    function updateall() {
        const loadBoards = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    for (const item in response.data) {
                        if (response.data.includes('owner_organization')) {
                            setCanChangeTask(true);
                            setViewComment(true);
                            setCanViewHistoryTask(true);
                            setCanAddComment(true);
                            setCanChangeComment(true);
                            setCanDeleteTask(true);
                            setCanDeleteComment(true);
                            setCanViewLabel(true);
                            setCanViewUser(true);
                            setCanDeleteFile(true);
                        }
                        if (response.data[item] === 'Can change Задача') {
                            setCanChangeTask(true);
                        }
                        else if (response.data[item] === 'Can view Комментарий') {
                            setViewComment(true);   
                        }
                        else if (response.data[item] === 'Can view historical Задача') {
                            setCanViewHistoryTask(true);
                        }
                        else if (response.data[item] === 'Can add Комментарий') {
                            setCanAddComment(true);
                        }
                        else if (response.data[item] === 'Can change Комментарий') {
                            setCanChangeComment(true);
                        }
                        else if (response.data[item] === 'Can delete Задача') {
                            setCanDeleteTask(true);
                        }
                        else if (response.data[item] === 'Can delete Комментарий') {
                            setCanDeleteComment(true);
                        }
                        else if (response.data[item] === 'Can view Метка') {
                            setCanViewLabel(true);
                        }
                        else if (response.data[item] === 'Can view Пользователь') {
                            setCanViewUser(true);
                        }
                        else if (response.data[item] === 'Can delete Файл') {
                            setCanDeleteFile(true);
                        }
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
        loadBoards();
        api.get(`${apiUrl}api/v1/tasks/${task_pk}/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setBoardSlug(response.data.board_slug);
                setSlugBoard(response.data.board_slug);
                setRating(response.data.priority);
                if ((!titletask || !descriptiontask) && isActiveUpdateTasksRef.current === false) {
                    setTitleTask(response.data.title);
                    autoResize();
                    if (response.data.description) {
                        setDescriptionTask(response.data.description);
                    }
                }
                setSelectedPerformers(response.data.performer);
                setSelectedResponsibles(response.data.responsible);
                setSelectedLabels(response.data.label.map(lab => lab.id));
                if (response.data.date_start_task) {
                    setHHTime((new Date(response.data.date_start_task).toLocaleString()).split(", ")[1].slice(0, 2))
                    setMMTime((new Date(response.data.date_start_task).toLocaleString()).split(":")[1].slice(0, 2))
                    let datetimelocalformat = new Date(response.data.date_start_task).toLocaleString()
                    let [startday, startmonth, startyear] = datetimelocalformat.split(", ")[0].split(".");
                    onChangeCalendarStartDate(new Date(`${startyear}-${startmonth}-${startday}`))
                }
                setGroup(response.data.group_name);
                if (response.data.deadline) {
                    setHHTimeDeadline((new Date(response.data.deadline).toLocaleString()).split(", ")[1].slice(0, 2))
                    setMMTimeDeadline((new Date(response.data.deadline).toLocaleString()).split(":")[1].slice(0, 2))
                    let datetimelocalformat = new Date(response.data.deadline).toLocaleString()
                    let [startday, startmonth, startyear] = datetimelocalformat.split(", ")[0].split(".");
                    onChangeCalendar(new Date(`${startyear}-${startmonth}-${startday}`))
                }
                localStorage.setItem('groupid', response.data.group);
                localStorage.setItem('selected_files', response.data.file_id.map(file => file));
                setTask(response.data);
                setselectedtask(response.data);
                setWhoCanEditTask(response.data.who_can_edit_task_online);
                setselectedSubtasks(response.data.subtasks);
                if (response.data.subtasks && response.data.subtasks.length > 0) {
                    api.get(`${apiUrl}api/v1/tasks/${response.data.task_slug}/get-related-task/`, {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }).then((response) => {
                        if (response.status === 200){
                            setRelatedSubtasks(response.data);
                        }
                    }).catch(error => {
                        console.error(error);
                        const status = error.response ? error.response.status : null;
                        if (status && status === 403) {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                            window.location.href = '/main';
                        }
                    });
                }
                fetchAllUsers(1, [], response.data.board_slug, response.data.group_id);
                setFirstLoading(false);
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (status && status === 403) {
                if (error.response.data.errors[0].detail.split(" ")[0] === "change-organization") {
                    if (window.confirm("Задача находится в другой организации. Перейти в другую организацию?"
                    )) {
                        api.get(`${apiUrl}api/identity/auth/users/me/`, {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                        }).then((response) => {
                          if (response.status === 200) {
                            api.patch(`${apiUrl}api/identity/users/${response.data.id}/update-organization/`, {
                                'organization': error.response.data.errors[0].detail.split(" ")[1]
                            },{
                                headers: {
                                    'Authorization': 'Bearer ' + token,
                                },
                            }).then((response) => {
                                if (response.status === 200) {
                                    if (response.data.detail === 'Organization updated successfully.') {
                                        updateall();
                                    }
                                }
                            }).catch(error => {
                                console.error(error);
                            });
                          }
                        }).catch(error =>{
                          console.error(error);
                        });
                    }
                    else {
                        window.location.href = '/main';
                    }
                }
                else {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    window.location.href = '/main';    
                }
            }
            if (status && status === 404) {
                alert(`Код ошибки: ${status}, Задача не найдена`, error);
                window.location.href = '/main';
            }
        });
        api.get(`${apiUrl}api/v1/tasks/${task_pk}/reminders/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setReminderUser(response.data);
                if (response.data.id && response.data.date_time_send_notification !== null) {
                    setHHTimeReminder((new Date(response.data.date_time_send_notification).toLocaleString()).split(", ")[1].slice(0, 2));
                    setMMTimeReminder((new Date(response.data.date_time_send_notification).toLocaleString()).split(":")[1].slice(0, 2));
                    let datetimelocalformat = new Date(response.data.date_time_send_notification).toLocaleString();
                    let [startday, startmonth, startyear] = datetimelocalformat.split(", ")[0].split(".");
                    onChangeCalendarReminder(new Date(`${startyear}-${startmonth}-${startday}`));
                }
            }
        }).catch(error => {
            console.error(error);
        });
        // handleCheckboxChangeComments(comment);
    };

    useEffect(() => {
        if (isActiveHistory === false) {
            setIsActiveComment(true);
        }
        else{
            setIsActiveComment(false);
        }
    }, [isActiveHistory]);
    
    useEffect(() => {
        updateall();
        // eslint-disable-next-line
    }, []);

    function fetchAllUsers(page = 1, allUsers = [], board_slug, group_id) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { 
                page: page,
                board_id: board_slug,
            },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList, board_slug, group_id);
                } else {
                    setperformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };


    const textareaRef = useRef(null);
    const textareaRefComment = useRef(null);
    const textareaRefCommentChange = useRef(null);
    const textareaRefCommentAnswer = useRef(null);
    const autoResizeCommentChange = () => {
        const textarea = textareaRefCommentChange.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }; 
    const autoResizeComment = () => {
        const textarea = textareaRefComment.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };
    const autoResizeCommentAnswer = () => {
        const textarea = textareaRefCommentAnswer.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };
    const autoResize = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }; 
    const handleTitleTaskChange = (event) => {
        const lines = event.target.value.split('\n').length;
        if (lines <= 6) {
            setTitleTask(event.target.value);
            autoResize();
        }
    };

    const handleCommentTaskChange = (event) => {
        const match = event.target.value.match(/(?:^|\s)@([\p{L}\p{N}_]*)$/u);
        const lines = event.target.value.split('\n').length;
        if (match) {
            sethintselectuserselect(true);
            const searchTerm = match[1].toLowerCase();
            sethintuserfilter(performer.filter(user => user.formated_fio ? user.formated_fio.toLowerCase().startsWith(searchTerm): user.username.toLowerCase().startsWith(searchTerm)));
        }
        else {
            sethintselectuserselect(false);
        }
        if (lines <= 6) {
            setCommentUser(event.target.value);
            autoResizeComment();
        }
    };

    const handledCommentChange = (event) => {
        const lines = event.target.value.split('\n').length;
        if (lines <= 6) {
            setSelectedComment(event.target.value);
            autoResizeCommentChange();
        }
    };

    const handleCommentAnswer = (event) => {
        const lines = event.target.value.split('\n').length;
        if (lines <= 6) {
            setanswercommenttext(event.target.value);
            autoResizeCommentAnswer();
        }
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected) {
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    function updateComment() {
        if (!activeSeeComments) {
            api.get(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => { 
                if (response.status === 200) {
                    SetComments(response.data);
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                }
            });
        }
    };


    function WebsocketConnect() {
        const token = localStorage.getItem('token');
        socketRef.current = new WebSocket(`${wsUrl}ws/tasks/${task_pk}/comments/?token=${token}`);
        socketRef.current.onmessage = function (event) {
            const data = JSON.parse(event.data);
            if (data.comment) {
                SetComments((prevComments) => {
                    if (data.type === 'comment_deleted') {
                        setTask(prevTask => ({
                            ...prevTask,
                            count_comments: prevTask.count_comments - 1 
                        })); 
                        return prevComments.filter((c) => c.id !== data.comment.id);
                    }
                    else if (data.type === 'comment_updated') {
                        return prevComments.map((c) => 
                            c.id === data.comment.id ? { ...c, text: data.comment.text } : c
                        );
                    }
                    else if (data.type === 'comment_created') {
                        setTask(prevTask => ({
                            ...prevTask,
                            count_comments: prevTask.count_comments + 1 
                        })); 
                        return [...prevComments, data.comment];
                    }
                });
                textareaRefComment.current.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'end'
                });
            }
            else if (data.file) {
                if (data.type === 'file_deleted') {
                    setTask((prevTask) => {
                        return {
                            ...prevTask,
                            file: prevTask.file.filter((file) => file.id !== data.file.id),
                        };
                    });
                }
            }
            else {
                setBoardSlug(data.task.board_slug);
                setSlugBoard(data.task.board_slug);
                setRating(data.task.priority);
                if ((!titletask || !descriptiontask) && isActiveUpdateTasksRef.current === false)  {
                    setTitleTask(data.task.title);
                    autoResize();
                    if (data.task.description) {
                        setDescriptionTask(data.task.description);
                    }
                }
                setWhoCanEditTask(data.task.who_can_edit_task_online);
                setSelectedPerformers(data.task.performer);
                setSelectedResponsibles(data.task.responsible);
                setSelectedLabels(data.task.label.map(lab => lab.id));
                setGroup(data.task.group_name);
                if (data.task.deadline) {
                    setHHTimeDeadline((new Date(data.task.deadline).toLocaleString()).split(", ")[1].slice(0, 2))
                    setMMTimeDeadline((new Date(data.task.deadline).toLocaleString()).split(":")[1].slice(0, 2))
                    let datetimelocalformat = new Date(data.task.deadline).toLocaleString()
                    let [startday, startmonth, startyear] = datetimelocalformat.split(", ")[0].split(".");
                    onChangeCalendar(new Date(`${startyear}-${startmonth}-${startday}`))
                }
                if (data.task.date_start_task) {
                    setHHTime((new Date(data.task.date_start_task).toLocaleString()).split(", ")[1].slice(0, 2))
                    setMMTime((new Date(data.task.date_start_task).toLocaleString()).split(":")[1].slice(0, 2))
                    let datetimelocalformat = new Date(data.task.date_start_task).toLocaleString()
                    let [startday, startmonth, startyear] = datetimelocalformat.split(", ")[0].split(".");
                    onChangeCalendarStartDate(new Date(`${startyear}-${startmonth}-${startday}`))
                }
                localStorage.setItem('groupid', data.task.group);
                localStorage.setItem('selected_files', data.task.file_id.map(file => file));
                setTask(data.task);
                setselectedtask(data.task);
                setselectedSubtasks(data.task.subtasks);
                if (data.task.subtasks && data.task.subtasks.length > 0) {
                    api.get(`${apiUrl}api/v1/tasks/${data.task.task_slug}/get-related-task/`, {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }).then((response) => {
                        if (response.status === 200){
                            setRelatedSubtasks(response.data);
                        }
                    }).catch(error => {
                        console.error(error);
                        const status = error.response ? error.response.status : null;
                        if (status && status === 403) {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                            window.location.href = '/main';
                        }
                    });
                }
                //тут тоже надо думать
                // fetchAllUsers(1, [], data.task.board_slug, data.task.group_id);
            }
        };

        socketRef.current.onclose = function () {
        }
        socketRef.current.error = function (error) {
        };

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    };

    useEffect(() => {
        if (isactiveupdatetasks === false) {
            if (userData && userData.id === task.who_can_edit_task_online) {
                setisactiveupdatetasks(true);
            }
        }
    }, [isactiveupdatetasks, userData, task]);

    useEffect(() => {
        if (socketRef.current !== null) {
            socketRef.current.close();
        }
        WebsocketConnect();
        // eslint-disable-next-line
    }, [task_pk, token]);

    useEffect(() => {
        api.get(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                SetComments(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
        // eslint-disable-next-line
    }, [task_pk, token]);

    const handleSendCommentClick = (event) => {
        event.preventDefault();
        if (commentuser) {
            api.post(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
                'text': commentuser
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 201){
                    setCommentUser("");
                    sethintselectuserselect(false);
                    textareaRefComment.current.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'end'
                    });
                    updateComment();
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleChangePriorityTask = (priority) => {
        setRating(priority);
        if (selectedtask.priority !== priority) {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'priority': priority,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token  
                },
            }).then((response) => {
            if (response.status === 200) { 
                if (isActiveHistory === true) {
                    updateHistory();
                }
                updateall();
            }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleUpdateDescTask = () => {
        setIsTextSelected(false);
        api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
            'title': titletask,
            'description': descriptiontask,
            'who_can_edit_task_online': null,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
        if (response.status === 200) {
            setisactiveupdatetasks(false);
            updateall();
        }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeComment = (data) => {
        closeModal();
        setChangeComment([]);
        setSelectedComment(data.text)
        setChangeComment(data);
        setmodalIsOpencomment(true);
    };

    function handleSubmitChangeComment(event) {
        event.preventDefault();
        api.patch(`${apiUrl}api/v1/tasks/${task_pk}/comments/${changecomment.id}/`, {
            'text': selectedcomment,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
           },
        }).then((response) => {
            if (response.status === 200) {
                closeModal();
            }
        }).catch(error =>{
            console.error(error);
        });
    };

    const handleClickGoToRelatedTaskNewPin = (s, e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open('/task/' + s);
        }
    }

    const scrollToBottom = () => {
        const container = commentsEndRef.current;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [comments]);

    const handleGoToRelatedTask = (s, e) => {
        window.location.href = `/task/${s}`;
    }

    function renderHistory () {
        return(
            <div id="div-for-history-column">
                {taskhistory.map((th, taskindex) => (
                    th.performer_username === "" && th.responsible_username === "" && th.label_name === "" && th.file_name === ""
                    && th.title === "" && th.description === "" && th.deadline === "" && th.priority === "" && th.group_title === "" ? null:
                    <div id="div-not-column" key={taskindex}>
                        <div id='div-for-history-stroke'>
                            <div id="div-for-history-name-and-dateupdate">
                                <label id="label-username">{th.who_changed_username}</label>
                                <label id="label-dateupdate">{getDateUpdate(th.updated_at)}</label>     
                            </div> 
                            <div id="div-for-main-history-information-detail-task">
                                {th.title && <label id="text-change">
                                    <span style={{ color: 'rgb(19, 157, 196)', fontWeight: 'bold' }}>
                                            {th.title.split(' ').slice(0, 2).join(' ')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.title.split(' ').slice(2).join(' ')}
                                </label>}
                                {th.description && <label id="text-change">
                                    <span style={{ color: 'rgb(196, 134, 19)', fontWeight: 'bold' }}>
                                        {th.description.split(' ').slice(0, 2).join(' ').replace(regex, '')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.description.split(' ').slice(2).join(' ').replace(regex, '').replace(regextable, '')}
                                </label>}
                                {th.performer_username && <label id="text-change">
                                    <span style={{ color: 'rgb(196, 19, 19)' , fontWeight: 'bold' }}>
                                        {th.performer_username.split(' ').slice(0, 2).join(' ') }
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.performer_username.split(' ').slice(2).join(' ')}
                                </label>}
                                {th.label_name && <label id="text-change">
                                    <span style={{ color: 'rgb(19, 196, 137)' , fontWeight: 'bold' }}>
                                        {th.label_name.split(' ').slice(0, 2).join(' ')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.label_name.split(' ').slice(2).join(' ')}
                                </label>}
                                {th.file_name && <div id="text-change">
                                    <span style={{ color: 'rgb(127, 84, 207)' , fontWeight: 'bold' }}>
                                        {th.file_name.split(' ').slice(0, 2).join(' ')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.file_name.split(' ').slice(2).join(' ') === "None" ? "None" :
                                        linkfile.push((`${apiUrl}` + (th.file_name.split(' ').slice(2).join(' ')).replace(/, /g, `, ${apiUrl}`)).split(', ')) > 0 ? null: "None"
                                    }
                                    {linkfile.map(lb => lb.map((pm, pmindex) => (
                                        deletedublicates(pm)
                                    )))}
                                </div>}
                                {th.deadline && <label id="text-change">
                                    <span style={{ color: 'rgb(134, 196, 19)', fontWeight: 'bold'}}>
                                        {th.deadline.split(' ').slice(0, 4).join(' ')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.deadline.split(' ').slice(4).join(' ')}
                                </label>}
                                {th.priority && <label id="text-change">
                                    <span style={{ color: 'rgb(149, 74, 168)', fontWeight: 'bold'}}>
                                        {th.priority.split(' ').slice(0, 2).join(' ')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.priority.split(' ').slice(2).join(' ')}
                                </label>}
                                {th.group_title && <label id="text-change">
                                    <span style={{ color: 'rgb(78, 218, 85)', fontWeight: 'bold'}}>
                                        {th.group_title.split(' ').slice(0, 2).join(' ')}
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.group_title.split(' ').slice(2).join(' ')}
                                </label>}
                                {th.responsible_username && <label id="text-change">
                                    <span style={{ color: 'rgb(19, 157, 196)', fontWeight: 'bold'}}>
                                        {th.responsible_username.split(' ').slice(0, 2).join(' ') }
                                    </span>
                                    <span id="arrow-icon">
                                        &#10140;
                                    </span>
                                    {th.responsible_username.split(' ').slice(2).join(' ')}
                                </label>}
                            </div>
                        </div>
                    </div>      
                ))}
            </div>
        )
    }

    const handleGoToRevertBoard = (event) => {
        window.location.href = `/boards/${boardSlug}`;
    };
    const handleGoToRevertBoardNewPin = (e) => {
        if (e.button === 1) {
            window.open(`/boards/${boardSlug}`);
        }
    };

    function renderComment(comment){
        const isCurrentUser = comment.author === username;
        const commentclass = isCurrentUser ? "comment-right" : "comment-left";
        const imageclass = isCurrentUser ? "div-right" : "div-left";
        return (
            <div id="comment-image">
                {performer.filter(pf => pf.username === comment.author).map((k, kindex) => (
                    k.image ?
                        <div className={imageclass}>
                            <img className='image-comment-user'
                                src={k.image}   
                                alt=""
                            />
                            <label id="comment-username">
                                {k.username.slice(0,5)}
                            </label>
                            <GetOnlineUser user={k.id}/>
                        </div>:
                        <div className={imageclass}>
                            <img id="not-image-comment-performer"
                                src={apiUrlFrontend + "person-square.svg"}
                                alt=""
                            />
                            <label id="comment-username">
                                {k.username.slice(0,5)}
                            </label>
                            <GetOnlineUser user={k.id}/>
                        </div>
                    )
                )}
                <div className={commentclass}>
                    {commentclass === 'comment-right' ? canChangeComment ?
                        <button id="comment-text-button"
                            onClick={() => handleChangeComment(comment)}
                        >
                            {comment.text}
                        </button>: 
                        <button id="comment-text-button">
                            {comment.text}
                        </button>: 
                    canAddComment ?
                        <button id="comment-text-button"
                            onClick={() => handleAnswerComment(comment)}
                        >
                            {comment.text}
                        </button>:
                        <button id="comment-text-button">
                            {comment.text}
                        </button>
                    }
                    <label id="date-time-label-in-comment">
                        {new Date(comment.created_at.replace(' ', 'T') + 'Z').toLocaleString().slice(0, 17)}
                    </label>
                </div>
            </div>
        );
    };

    const handleOpenRelatedTask = (event, task_slug) => {
        event.preventDefault();
            if (!activeSeeSubtasks) {
            api.get(`${apiUrl}api/v1/tasks/${task_slug}/get-related-task/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 200) {
                    setRelatedSubtasks(response.data);
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (status && status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                    window.location.href = '/main';
                }
            });
        }
    }

    const handleAnswerComment = (comment) => {
        closeModal();
        setselectedcommentanswer(comment);
        setmodalIsOpenAnswerComment(true);
    };

    const handleDeleteComment = (event) => {
        event.preventDefault();
        api.delete(`${apiUrl}api/v1/tasks/${task_pk}/comments/${changecomment.id}/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) {
                closeModal();
            }
        }).catch(error =>{
            console.error(error);
        });
    };

    const linkfile = [];
    const regex = /<[^>]*>/g;
    const regextable = /&nbsp;/g;

    function getDateUpdate(updated_at) {
        const date = new Date();
        const lastUpdatedDate = new Date(updated_at.split('T')[0]);
        const differenceInMilliseconds = date.getTime() - lastUpdatedDate.getTime();
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        let daysAgoString;
        if (differenceInDays === 0) {
            daysAgoString = 'Обновленно сегодня';
        } else if (differenceInDays === 1) {
            daysAgoString = 'Обновленно 1 день назад';
        } else if (differenceInDays % 10 === 1 && differenceInDays !== 11) {
            daysAgoString = `Обновленно ${differenceInDays} день назад`;
        } else if ((differenceInDays % 10 === 2 || differenceInDays % 10 === 3 || differenceInDays % 10 === 4) && 
            (differenceInDays < 10 || differenceInDays > 20)) {
            daysAgoString = `Обновленно ${differenceInDays} дня назад`;
        } else {
            daysAgoString = `Обновленно ${differenceInDays} дней назад`;
        }
        return daysAgoString;
    };

    const checkdubl = [];

    function deletedublicates(data) {
        if (checkdubl.includes(data)) {
            return null;
        } else {
            checkdubl.push(data);
            const namefile = data.split('/');
            return (
                <button
                    id="link-to-file"
                    onClick={() => alert("Функция временно отключена")}
                >
                    {namefile[namefile.length - 1]}
                </button>
            );
        }
    };

    const handlePostUserFromHint = (username) => {
        const atIndex = commentuser.lastIndexOf('@');
        if (atIndex !== -1) {
            const mentionText = commentuser.substring(atIndex + 1);
            if (mentionText.endsWith(username)) {
                const newCommentText = commentuser.substring(0, atIndex) + `@${username}`;
                setCommentUser(newCommentText);
            } else {
                setCommentUser(prevCommentUser => prevCommentUser + username);
                const newCommentText = commentuser.substring(0, atIndex) + `@${username}`;
                setCommentUser(newCommentText);
            }
        } else {
            setCommentUser(prevCommentUser => prevCommentUser + username);
        }
        sethintselectuserselect(false);
    };
    const [showPickerModal, setShowPickerModal] = useState(false);
    const [showPicker, setShowPicker] = useState(false);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setShowPicker(false);
        }
        if (containerRefModal.current && !containerRefModal.current.contains(event.target)) {
            setShowPickerModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEditorChange = (data) => {
        setDescriptionTask(data);
    };

    const loadBoards = async () => {
        setopenModalChangeBoard(true);
        if (loadingBoards || !hasMoreBoards) return;
        setLoadingBoards(true);
        try {
            const response = await api.get(`${apiUrl}api/v1/boards/`, {
                params: { page: page },
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            if (response.status === 200) {
                setBoard(prevBoards => {
                    const newBoards = response.data.results.filter(newBoard =>
                        newBoard.slug !== boardSlug && !prevBoards.some(prevBoard => prevBoard.slug === newBoard.slug));
                    return [...prevBoards, ...newBoards]; 
                });
                setHasMoreBoards(response.data.next !== null);
                if (response.data.next) setPage(prevPage => prevPage + 1);
            }
        } catch (error) {
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status !== 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        }
        setLoadingBoards(false);
    };

    useEffect(() => {
        if (openModalChangeBoard) {
            loadBoards();
        }
        // eslint-disable-next-line
    }, [openModalChangeBoard]);

    const loadTasksFilter = async(title) => {
        setPageForTask(1);
        setHasMoreSubTask(true);
        if (loadingTaskFilter || !hasMoreTaskFilter) return;
        setLoadingTaskFilter(true);
        try{
            const response = await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                'title': title,
            },{
                params: {page: prevTaskTitleFind !== title ? 1: pageForFilterTask},
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            if (response.status === 200) {
                setPrevTaskTitleFinde(title);
                if (pageForFilterTask > 1 && !title) {
                    setfiltertasklist(prevTasks => {
                        const newTasks = response.data.results.filter(
                            newTask => !prevTasks.some(prevTask => prevTask.task_id === newTask.task_id)
                        );
                        return [...prevTasks, ...newTasks]
                    });    
                }
                else {
                    setfiltertasklist(response.data.results);
                }
                setHasMoreTaskFilter(response.data.next !== null);
                if (response.data.next) setPageForFilterTask(prevPage => prevPage + 1);
                if (response.data.results.length === 0) {
                    setLoadingTaskFilter(false);
                }
            }
        } catch (error) {
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status !== 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        }
        setLoadingTaskFilter(false);
    }

    const loadsubtasks = async() => {
        setPageForFilterTask(1);
        setHasMoreTaskFilter(true);
        if (loadingSubtask || !hasMoreSubTask) return;
        setLoadingSubtask(true);
        try {
            const response = await api.get(`${apiUrl}api/v1/tasks/`, {
                params: { page: pageForTask },
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            if (response.status === 200) {
                if (pageForTask > 1) {
                    setfiltertasklist(prevtasklist => {
                        const sortedTasks = response.data.results.filter(item => item.task_id !== task.task_id && !prevtasklist.some(
                            prevTask => prevTask.task_id === item.task_id)
                        );
                        return [...prevtasklist, ...sortedTasks];
                    });
                }
                else {
                    setfiltertasklist(relatedSubtasks);
                    setfiltertasklist(prevtasklist => {
                        const sortedTasks = response.data.results.filter(item => item.task_id !== task.task_id && !prevtasklist.some(
                            prevTask => prevTask.task_id === item.task_id)
                        );
                        return [...prevtasklist, ...sortedTasks];
                    });
                }
                setHasMoreSubTask(response.data.next !== null);
                if (response.data.next) setPageForTask(prevPage => prevPage + 1);
            }
        }
        catch (error) {
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (status !== 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
            }
        }
        setLoadingSubtask(false);
    };

    const handleScrollTask = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            if (pageForFilterTask > 1) {
                loadTasksFilter()
            }
            else if (pageForTask > 1) {
                loadsubtasks();
            }
        }
    };

    useEffect(() => {
        if (modalIsOpenUniteSubtasks) {
            loadsubtasks();
        }
        // eslint-disable-next-line
    }, [modalIsOpenUniteSubtasks]);

    const handleSearch = (searchitem) => {
        setIsTextSelected(false);
        if (searchitem.length >= 1) {
            loadTasksFilter(searchitem);
        } 
        else if (searchitem.length < 1) {
            loadsubtasks();
        }
    };

    const handleCheckboxChangeSubtasks = (subtask) => {
        if (selectedSubtasks.includes(subtask)) {
        setselectedSubtasks(selectedSubtasks.filter((selectedsubtasks) => selectedsubtasks !== subtask));
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'subtasks': selectedSubtasks.filter((selectedsubtasks) => selectedsubtasks !== subtask)
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) { 
                if (isActiveHistory === true) {
                    updateHistory();
                }
                updateall()
            }}).catch(error => {
                console.error(error);
            });
            } 
            else {
                setselectedSubtasks([...selectedSubtasks, subtask]);
                api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                    'subtasks': [...selectedSubtasks, subtask],
                },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                if (response.status === 200) { 
                if (isActiveHistory === true) {
                    updateHistory();
                }
                updateall()
                }}).catch(error => {
                    console.error(error);
            });
        }
    };

    const handlesetsearchitem = (event) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            handleSearch(event.target.value);
        }, 500);
    };

    const handleCreateTemplate = () =>{
        const result = window.confirm("Создать шаблон задачи?");
        if (result) {
            api.post(`${apiUrl}api/v1/template-task/`, {
                'title': task.title,
                'priority': task.priority,
                'description': task.description,
                'label': `${task.label.map(lb => lb.id)}`,
                'performer': `${task.performer.map(pf => pf)}`,
                'subtasks': `${selectedSubtasks.map(sb => sb)}`,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    alert('Шаблон успешно сохранен!');
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleSendCommentAnswer = () => {
        api.post(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
            'text': answercommenttext,
            'question_user': selectedcommentanswer.author
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 201){
                setselectedcommentanswer([]);
                setanswercommenttext("");
                // updateComment();
                closeModal();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleGoToProfileNewPin = (e, username) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/profile/" + username);
        }
    };

    const handleOpenAccessDeleteTask = (event) => {
        const result = window.confirm("Будет удалена вся информация о задаче включая историю и комментарии.");
        if (result) {
            setLoadingDeleteTask(true);
            api.delete(`${apiUrl}api/v1/tasks/${task.task_slug}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    window.location.href = `/boards/${task.board_slug}`;
                }
            })
            .catch(error =>{
                console.error(error);
            });
        }
    };

    const handleDeleteLabelClick = (event, labeli) => {
        event.preventDefault();
        const result = window.confirm("Метка будет УДАЛЕНА со всех имеющихся задач");
        if (result) {
            api.delete(`${apiUrl}api/v1/labels/${labeli.id}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    alert("Успешно удалено");
                    updateall();
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
    };

    function transparentColor(hex, opacity) {
        hex = getHexColor(hex)
        if (hex[0] === "#") {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            let rgb = result ? [
                parseInt(result[1], 16),
                parseInt(result[2], 16),
                parseInt(result[3], 16)
            ] : null;
    
            return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
        }
        else {
            return `rgba(255, 255, 255, ${opacity})`
        }
    }

    useEffect(() => {
        if (activeSeeComments && textareaRefComment.current) {
            textareaRefComment.current.scrollIntoView({ 
            behavior: 'smooth',
            block: 'end'
          });
        }
    }, [activeSeeComments]);

    const namedColors = {
        red: "#FF0000",
        coralex: "#FF7F50",
        gold: "#FFD700",
        greenyellow: "#ADFF2F",
        limegreen: "#32CD32",
        lightseagreen: "#20B2AA",
        darkturquoise: "#00CED1",
        steelblue: "#4682B4",
        dodgerblue: "#1E90FF",
        slateblue: "#6A5ACD",
        mediumpurple: "#9370DB",
        deeppink: "#FF1493",
        darkgray: "#A9A9A9",
        white: "#FFFFFF",
        black: "#000000",
        gray: "#808080"
    };

    const getHexColor = (color) => {
        if (color[0] === "#") return color;
        return namedColors[color.toLowerCase()] || color;
    };

    function handleCreateReportForTask (event, task_slug) {
        event.preventDefault();
        const handleDownload = async (task_slug) => {
            const response = await fetch(`${apiUrl}api/v1/tasks/${task_slug}/get-report-subtask/`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/pdf',
                },
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'file.pdf';
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                console.error("Ошибка при загрузке PDF");
            }
        };
        handleDownload(task_slug);
    };

    document.title = `Задача ${task.task_slug} - ТвояЗадача`;

    if (loadingDeleteTask) {
        return (
            <div 
                style={{ overflowX: 'auto',overflowY: 'hidden', width: "100%", height: "100vh", justifyContent: "space-between", alignItems: "left", display: "flex"}} 
                className="detail-task-page"
            >
                <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <span class="loader"></span>
                </div>
            </div>
        )
    }

    if (firstLoading) {
        return (
            <div className="main-profile">
            <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <span class="loader"></span>
                </div>
            </div>
        )
    }

    return (
        <div className="create-task-page"> 
            <Modal className="modal-add-data-component"
                isOpen={modalIsOpenAnswerComment}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "comment-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Ответ на комментарий
                    </label>
                </div>
                <label id="modal-label-answercomment">
                    Ответ пользователю: {selectedcommentanswer.author}
                </label>
                <label id="answer-comment">
                    {selectedcommentanswer.text}
                </label>
                <div style={{display: 'flex', overflow: 'visible',}}>
                    <button style={{background: "none", border: "none", marginTop: "6px", maxHeight: "30px", maxWidth: "30px"}} onClick={() => setShowPickerModal(!showPickerModal)}>
                        <img alt="" style={{maxHeight: "25px", maxWidth: "25px", cursor: "pointer"}} src={apiUrlFrontend + "btn-open-smile.svg"}/>
                    </button>
                    {showPickerModal && (
                        <div ref={containerRefModal} style={{ border: 'none', position: 'absolute', backgroundColor: '#393A45', boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: "10px", zIndex: "2", color: "white", }}>
                            <Picker
                                locale="ru"
                                theme="dark" 
                                data={data}
                                onEmojiSelect={(emoji) => {
                                    setanswercommenttext((prevComment) => prevComment + emoji.native);
                                    setShowPickerModal(false);
                                }}
                            />
                        </div>
                    )}
                    <textarea
                        id="text-comment"
                        className="no-resize"
                        placeholder="Введите ответ"
                        onChange={handleCommentAnswer}
                        value={answercommenttext}
                        ref={textareaRefCommentAnswer}
                    />
                </div>
                <button id="confirmtask-create" onClick={handleSendCommentAnswer}>
                    Отправить
                </button>
            </Modal>
            <Modal className="modal-add-data-component"
                isOpen={modalIsOpenUniteSubtasks}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "connect-btn.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Добавить связь
                    </label>
                </div>
                <input
                    id="search-input-subtasks"
                    placeholder='Введите текст, чтобы найти задачи'
                    onClick={handleTextareaClick}
                    onChange={handlesetsearchitem}
                />
                <div id="scroll-subtask" onScroll={handleScrollTask}>
                    {filtertasklist.sort((a, b) => {
                    if (selectedSubtasks.includes(a.task_id) && !selectedSubtasks.includes(b.task_id)) {
                    return -1;
                    } else if (!selectedSubtasks.includes(a.task_id) && selectedSubtasks.includes(b.task_id)) {
                    return 1;
                    } else {
                    return 0;
                    }}).map((ts, tsindex) => (
                        <div id="div-with-subtasks-and-btn" key={tsindex}>
                            <input id="check-is-subtasks"
                                type="checkbox"
                                checked={selectedSubtasks.includes(ts.task_id)}
                                onChange={() => handleCheckboxChangeSubtasks(ts.task_id)}
                            />
                            <button id="open-this-tasks"
                                onClick={() => window.location.href = `${ts.task_id}`}
                            >
                                {ts.title}           
                            </button>
                        </div>
                    ))}
                </div>
            </Modal>
            <Modal className="modal-add-data-component"
                isOpen={modalIsOpencomment}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "comment-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Изменить комментарий
                    </label>
                </div>
                <form onSubmit={handleSubmitChangeComment} className="modal-form-comment">
                    <div style={{display: "flex"}}>
                        <button style={{background: "none", border: "none", marginTop: "6px", maxHeight: "30px", maxWidth: "30px"}}
                            onClick={() => setShowPickerModal(!showPickerModal)}
                            type="button"
                        >
                            <img alt="" style={{maxHeight: "25px", maxWidth: "25px", cursor: "pointer"}} src={apiUrlFrontend + "btn-open-smile.svg"}/>
                        </button>
                        {showPickerModal && (
                            <div ref={containerRefModal} style={{ border: 'none', position: 'absolute', backgroundColor: '#393A45', boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: "10px", zIndex: "10", color: "white", }}>
                                <Picker
                                    locale="ru"
                                    theme="dark" 
                                    data={data}
                                    onEmojiSelect={(emoji) => {
                                        setSelectedComment((prevComment) => prevComment + emoji.native);
                                        setShowPickerModal(false);
                                   }}
                                />
                            </div>
                        )}
                        <div style={{display: "flex", width: "100%", height: 'fit-content'}}>
                            <textarea id="text-comment"
                                placeholder='Введите измененный текст комментария'
                                className="no-resize"
                                required
                                ref={textareaRefCommentChange}
                                value={selectedcomment}
                                onChange={handledCommentChange}
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                        {canDeleteComment ?
                            <button id="openAccessDelete" onClick={handleDeleteComment}>Удалить</button>: null
                        }
                        {canChangeComment ?
                            <button id="confirmtask-create" type="submit">Изменить</button>: null
                        }
                    </div>
                </form>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenGetLabel}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "label-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Метка
                    </label>
                </div>
                <div className="div-data-component-list">
                    {label.slice()
                        .sort((a, b) => {
                        const aSelected = selectedlabels.includes(a.id);
                        const bSelected = selectedlabels.includes(b.id);
                        return bSelected - aSelected;
                        }).map((lb, labelindex) => (
                        canViewLabel ?
                        <label className="data-component-add" key={labelindex}
                            style={{ 
                                background: selectedlabels.includes(lb.id) ? 'var(--btn-elenemt-checkbox-active-color)': 'var(--btn-elenemt-checkbox-color)', padding: '10px 15px 10px 15px',
                            }}
                        >
                            <label id="name-labels"key={labelindex}>
                                <input
                                    type="checkbox"
                                    key={labelindex}
                                    name="performers"
                                    onChange={() => handleCheckboxChangeLabel(lb.id)}
                                    checked={selectedlabels.includes(lb.id)}
                                />
                                <span style={{marginLeft: "10px", color: lb.color, filter: 'brightness(2)'}}>
                                    {lb.name}
                                </span>
                            </label>
                            {handledeletelabel ?
                                <button id="delete-label-btn" onClick={(event)=> handleDeleteLabelClick(event, lb)}>
                                    X
                                </button>
                            : null}
                        </label>
                    : null))}
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <button id="open-modal-delete-label"
                        onClick={() => sethandledeletelabel(!handledeletelabel)}
                    >
                        Удалить
                    </button>
                    <button id="confirmtask-create"
                        onClick={openModalLabel}
                    >
                        Создать
                    </button>
                </div>                    
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "performer-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Исполнитель
                    </label>
                </div>
                <div className="div-data-component-list">
                    {performer.slice()
                        .sort((a, b) => {
                        const aSelected = selectedperformers.includes(a.username);
                        const bSelected = selectedperformers.includes(b.username);
                        return bSelected - aSelected;
                        }).map((pf, pfindex) => (
                        <label className="data-component-add" key={pfindex}
                            style={{ 
                                background: selectedperformers.includes(pf.username) ? 'var(--btn-elenemt-checkbox-active-color)': 'var(--btn-elenemt-checkbox-color)', padding: '10px 15px 10px 15px',
                            }}
                        >
                            <GetOnlineUser user={pf.id}/>
                            <img id="component-task-image-performer"
                                src= {pf.image ? pf.image: apiUrlFrontend + "person-square.svg" }
                                alt=''
                            />
                            <input
                                type="checkbox"
                                key={pfindex}
                                name="performers"
                                onChange={() => handleCheckboxChangePerformer(pf.username)}
                                checked={selectedperformers.includes(pf.username)}
                            />
                            <span style={{marginLeft: '10px', color: 'white', fontFamily: 'Roboto, sans-serif'}}>
                                {pf.formated_fio ?
                                    pf.formated_fio:
                                    pf.username
                                }
                            </span>
                        </label>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenResponsible}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "responsible-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">
                        Ответственный
                    </label>
                </div>
                <div className="div-data-component-list">
                    {performer.slice()
                        .sort((a, b) => {
                        const aSelected = selectedResponsibles.includes(a.username);
                        const bSelected = selectedResponsibles.includes(b.username);
                        return bSelected - aSelected;
                        }).map((pf, pfindex) => (
                        <label className="data-component-add" key={pfindex}
                            style={{ 
                                background: selectedResponsibles.includes(pf.username) ? 'var(--btn-elenemt-checkbox-active-color)': 'var(--btn-elenemt-checkbox-color)', padding: '10px 15px 10px 15px',
                            }}
                        > 
                            <GetOnlineUser user={pf.id}/>
                            <img id="component-task-image-performer"
                                src= {pf.image ? pf.image: apiUrlFrontend + "person-square.svg" }
                                alt=''
                            />
                            <input
                                type="checkbox"
                                key={pfindex}
                                name="performers"
                                onChange={() => handleCheckboxChangeResponsible(pf.username)}
                                checked={selectedResponsibles.includes(pf.username)}
                            />
                            <span style={{marginLeft: '10px', color: 'white', fontFamily: 'Roboto, sans-serif'}}>
                                {pf.formated_fio ?
                                    pf.formated_fio:
                                    pf.username
                                }
                            </span>
                        </label>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenLabel}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "label-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">Создание метки</label>
                </div>
                <label className="hint-new-label">Название</label>
                <input id="new-name-label"
                    placeholder="Введите название метки"
                    onChange={handleChangeNameLabel}
                />
                <label className="hint-new-label">Цвет</label>
                <div style={{ width: '240px', display: 'flex', flexWrap: 'wrap', borderRadius: "10px", background: "none", justifyContent: 'center'}}>
                    {webcolors.map((color, index) => (
                        <div key={index} style={{border: selectedColor === color ? '2px Solid white': 'none', borderRadius: '15px'}}
                            onClick={() => setSelectedColor(color)}
                        >
                            <div style={{ backgroundColor: color,
                                height: selectedColor === color ? '26px': '30px',
                                margin: '4px', cursor: 'pointer',
                                borderRadius: '10px',
                                width: selectedColor === color ? '46px': '50px'
                            }}/>
                        </div>
                    ))}
                </div>
                <button id="create-label"
                    onClick={handleCreateLabel}
                >
                    Создать
                </button>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={openModalChangeGroup}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "group-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">Состояние</label>
                </div>
                <div className="div-data-component-list">
                    {groups.map((groups, index) => (
                        <button id="select-board" key={index} onClick={() => updateTaskGroup(groups.group_id)}>
                            {groups.title}
                        </button>
                    ))}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >   
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <select className="custom-select-time" value={hhTimeDeadline} onChange={handleChangeHHTimeDeadline}>
                            {[...Array(24).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                        <label className="custom-label-time">:</label>
                        <select className="custom-select-time" value={mmTimeDeadline} onChange={handleChangeMMTimeDeadline}>
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="div-calendar">
                    <Calendar  onChange={(date) => {
                        onChangeCalendar(date);
                        handleUpdateTaskDeadline(date);
                    }}  value={value}/>
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendarReminder}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >   
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <select className="custom-select-time" value={hhTimeReminder} onChange={handleChangeHHTimeReminder}>
                            {[...Array(24).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                        <label className="custom-label-time">:</label>
                        <select className="custom-select-time" value={mmTimeReminder} onChange={handleChangeMMTimeReminder}>
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="div-calendar">
                    <Calendar  onChange={(date) => {
                        onChangeCalendarReminder(date);
                        handleUpdateTaskReminder(date);
                    }}  value={valueReminder}/>
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={modalIsOpenCalendarStartDate}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
                id="react-calendar"
            >
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <select className="custom-select-time" value={hhTime} onChange={handleChangeHHTime}>
                            {[...Array(24).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                        <label className="custom-label-time">:</label>
                        <select className="custom-select-time" value={mmTime} onChange={handleChangeMMTime}>
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : m}>
                                    {m < 10 ? `0${m}` : m}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="div-calendar">        
                    <Calendar  onChange={(date) => {
                        onChangeCalendarStartDate(date);
                        handleUpdateTaskStartDate(date);
                    }}  value={valueStartDate}/>
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={openModalChangeBoard}
                onRequestClose={() => setopenModalChangeBoard(false)}
                overlayClassName="overlay-add-data-component"
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "board-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">Доска</label>
                </div>
                <div className="div-data-component-list">
                    {board.length > 0 ? (
                        board.map((item, index) => (
                            <button id="select-board" key={index} onClick={(event) => handleOpenChangeGroup(event, item)}>
                                {item.title}
                            </button>
                        ))
                    ) : null}
                    {loadingBoards && <div style={{color: "white"}}>Загрузка...</div>}
                </div>
            </Modal>
            <Modal
                className="modal-add-data-component"
                isOpen={openModalChangeGroupTask}
                onRequestClose={closeModal}
                overlayClassName="overlay-add-data-component"
            >   
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        className="div-data-component-list-img-return"
                        src={apiUrlFrontend + "arrow-return-right.svg"}
                        alt=''
                        onClick={closeModal}
                    />
                    <img
                        className="div-data-component-list-img"
                        src={apiUrlFrontend + "group-icon.svg"}
                        alt=''
                    />
                    <label className="div-data-component-list-title">Состояние</label>
                </div>
                <div className="div-data-component-list">
                    {changegroup.length > 0 ? changegroup.map((item, index) => (
                        <button id="select-board" key={index} onClick={(event) => handlechangeboardgrouptask(event, item)}>
                            {item.title}
                        </button>
                    )): null}
                </div>
            </Modal>
            <div className="left-column-task-create">
                <div id="title-and-button">
                    <textarea
                        id="title-task"
                        placeholder='Заголовок задачи'
                        required
                        maxLength={250}
                        value={titletask}
                        ref={textareaRef}
                        onClick={handleTextareaClick}
                        onChange={isactiveupdatetasks ? handleTitleTaskChange : undefined}
                        readOnly={!isactiveupdatetasks}
                    />
                    <div id='button-titletask'>
                        {isactiveupdatetasks ?
                            <button className="btn-open-change-data"
                                onClick={() => handleUpdateDescTask()}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Сохранить изменения"
                            >
                                <img id="img-header-task" src={apiUrlFrontend + 'save-icon.svg'} alt=""/>
                                <span>Сохранить</span>
                            </button>:
                            canChangeTask && whoCanEditTask === null ?
                                <button className="btn-open-change-data"
                                    onClick={() => handleUpdateTitleDescTask()}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Редактировать задачу"
                                >
                                    <img id="img-header-task" src={apiUrlFrontend + 'edit-icon.svg'} alt=""/>
                                    <span>Редактировать</span>
                                </button>
                        : null}
                        <button className="btn-open-change-data"
                            onClick={handleGoToRevertBoard}
                            onMouseDown={(e) => handleGoToRevertBoardNewPin(e)}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Вернуться на доску"
                        >
                            <img id="img-header-task" src={apiUrlFrontend + 'board-white-icon.svg'} alt=""/>
                            <span>Доска</span>
                        </button>
                        <button className="btn-open-change-data"
                            onClick={() => handleCreateTemplate()}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Создать шаблон задачи"
                        >
                            <img id="img-header-task" src={apiUrlFrontend + 'template-icon.svg'} alt=""/>
                            <span>Новый шаблон</span>
                        </button>
                        <button className="btn-open-change-data"
                            onClick={() => setModalIsOpenUniteSubtasks(true)}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Добавить связанную задачу"
                        >
                            <img id="img-header-task" src={apiUrlFrontend + 'connect-btn.svg'} alt=""/>
                            <span>Связанные задачи</span>
                        </button>
                        {canDeleteTask ? 
                            <button className="btn-open-change-data"
                                onClick={() => handleOpenAccessDeleteTask()}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Удалить задачу"
                            >
                                <img id="img-header-task" src={apiUrlFrontend + 'delete-icon.svg'} alt=""/>
                                <span>Удалить</span>
                            </button>: null
                        }
                        {canViewUser ?
                            performer.filter(pf => pf.username === task.author).map((k, kindex) => (
                                <button className="btn-open-change-data" key={kindex}
                                    onClick={() => handleOpenProfileClick(task.author)}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Автор задачи"
                                >
                                    <img id="component-task-image-author"
                                        src={k.image ? k.image : apiUrlFrontend + "person-square.svg"}
                                        alt=''
                                    />
                                    <span>
                                        {task.formated_author_fio ? task.formated_author_fio: task.author}
                                    </span>
                                </button>
                            ))
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className="right-column-task-create">
                <div className="data-component-task-div" onClick={canChangeTask ? () => setopenModalChangeBoard(true): null}>
                    <img
                        id="img-component-task"
                        src={apiUrlFrontend + "board-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">
                        Доска
                    </label>
                    <label className="label-data-component-task">
                        {task.board_name === null ? "" : task.board_name}
                    </label>
                </div>
                <div className="data-component-task-div" onClick={canChangeTask ? handleOpenGroup: null}>
                    <img
                        id="img-component-task"
                        src={apiUrlFrontend + "group-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">
                        Состояние
                    </label>
                    <label className="label-data-component-task">
                        {group === null ? "" : group}
                    </label>
                </div>
                <div className="data-component-task-div" onClick={canChangeTask ? openModalCalendarStartDate: null}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "deadline-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Срок начала</label>
                    <label className="label-data-component-task">
                        {task.date_start_task ? `${format(new Date(task.date_start_task), 'dd.MM.yyyy')} ${hhTime}:${mmTime}`: "Не назначен"}
                    </label>
                </div>
                <div className="data-component-task-div" onClick={canChangeTask ? openModalCalendar: null}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "deadline-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Срок сдачи</label>
                    <label className="label-data-component-task">
                        {task.deadline ? `${format(new Date(task.deadline), 'dd.MM.yyyy')} ${hhTimeDeadline}:${mmTimeDeadline}`: "Не назначен"}
                    </label>
                </div>
                {canViewUser ?
                    <div className="data-component-task-div" onClick={canChangeTask ? () => openModal(): null} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <img 
                            id="img-component-task"
                            src={apiUrlFrontend + "performer-icon.svg"}
                            alt=''
                        />
                        <label className="label-component-task">Исполнитель</label>
                        <div className="div-data-component-task">
                            {selectedperformers.length > 0 ?
                                task && task.performer && task.performer.map((performers, taskindex) => (
                                    performer.filter(pf => pf.username === performers).map((k, kindex) => (
                                        <div className="component-task-user" key={kindex}>
                                            <img id="component-task-image-performer"
                                                src={k.image ? k.image : apiUrlFrontend + "person-square.svg"}
                                                alt=''
                                            />
                                            <button id="component-task-open-profile-performer"
                                                // onClick={() => handleOpenProfileClick(performers)}
                                                onMouseDown={(e) => handleGoToProfileNewPin(e, performers)}
                                            >
                                                {k.formated_fio ? k.formated_fio: performers}
                                            </button>
                                        </div>
                                    )))): 
                                <label className="label-data-component-task">
                                    Не назначен
                                </label>
                            }
                        </div>
                    </div>
                : null}
                {canViewUser ?
                    <div className="data-component-task-div" onClick={canChangeTask ? () => openModalResponsible(): null} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <img 
                            id="img-component-task"
                            src={apiUrlFrontend + "responsible-icon.svg"}
                            alt=''
                        />
                        <label className="label-component-task">Ответственный</label>
                        <div className="div-data-component-task">
                            {selectedResponsibles.length > 0 ?
                                task && task.responsible && task.responsible.map((performers, taskindex) => (
                                    performer.filter(pf => pf.username === performers).map((k, kindex) => (
                                        <div className="component-task-user" key={kindex}>
                                            <img id="component-task-image-performer"
                                                src={k.image ? k.image : apiUrlFrontend + "person-square.svg"}
                                                alt=''
                                            />
                                            <button id="component-task-open-profile-performer"
                                                // onClick={() => handleOpenProfileClick(performers)}
                                                onMouseDown={(e) => handleGoToProfileNewPin(e, performers)}
                                            >
                                                {k.formated_fio ? k.formated_fio: performers}
                                            </button>
                                        </div>
                                    )))): 
                                <label className="label-data-component-task">
                                    Не назначен
                                </label>
                            }
                        </div>
                    </div>
                : null}
                {canViewLabel ?
                    <div className="data-component-task-div" onClick={canChangeTask ? openModalGetLabel: null}>
                        <img 
                            id="img-component-task"
                            src={apiUrlFrontend + "label-icon.svg"}
                            alt=''
                        />
                        <label className="label-component-task">Метка</label>
                        <div className="div-data-component-task">
                            {selectedlabels.length > 0 ?
                                task && task.label && task.label.map((lb, lbindex) => (
                                    <div className="component-task-user" key={lbindex} style={{background: transparentColor(lb.color, 0.2), padding: '10px 15px 10px 15px'}}>
                                        <label className="label-name-component-task" style={{color: lb.color, filter: 'brightness(2)'}}>{lb.name}</label>
                                    </div>
                                )):
                                <label className="label-data-component-task">
                                    Не назначена
                                </label>
                            }
                        </div>
                    </div>
                : null}
                <div className="data-component-task-div" onClick={canChangeTask ? openModalCalendarReminder: null}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "reminder-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Напоминание</label>
                    <label className="label-data-component-task">
                        {reminderUser.date_time_send_notification ? `${format(new Date(valueReminder), 'dd.MM.yyyy')} ${hhTimeReminder}:${mmTimeReminder}`: "Не назначено"}
                    </label>
                </div>
                <div style={{display: 'flex', alignItems: 'center', height: '43px'}}>
                    <img 
                        id="img-component-task"
                        src={apiUrlFrontend + "priority-icon.svg"}
                        alt=''
                    />
                    <label className="label-component-task">Приоритет</label>
                    <div className="div-priority">
                        {[...Array(totalStars)].map((_, index) => {
                            const currentRating = index + 1;
                            return (
                                <label key={index}>
                                    <input
                                        key={currentRating}
                                        type="radio"
                                        name="rating"
                                        value={currentRating}
                                        onChange={() => handleChangePriorityTask(currentRating)}
                                    />
                                    <div
                                        className="rating-item"
                                        style={{
                                            color: currentRating <= (hover || rating) ? "var(--big-priority-text-selected-color)" : "var(--big-priority-text-color)",
                                            background: currentRating <= (hover || rating) ? "var(--big-priority-selected-color)" : "var(--big-priority-color)"
                                        }}
                                        onMouseEnter={() => setHover(currentRating)} 
                                        onMouseLeave={() => setHover(null)}
                                    >
                                        {currentRating}
                                    </div>
                                </label>
                            );
                        })}
                    </div>
                    <CustomPrioritySelect 
                        options={priorityOptions}
                        value={rating}
                        onChange={handleChangePriorityTask}
                    />
                </div>
            </div>
            <div className="left-column-task-create">
                <CKeditor5
                    onEditorChange={(data) => handleEditorChange(data)}
                    editorDisabled={isactiveupdatetasks}
                    descriptiontask={descriptiontask}
                />
                <div id="div-with-place-file" {...getRootProps()}>
                    <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                        {task && task.file && task.file.length > 0 ?
                            <div id="div-task-file">
                                {task && task.file && task.file.map((ts, taskindex) => (
                                    ts.file === null ? null:
                                    <UserFile
                                        url={ts.file}
                                        task={ts}
                                        updateall={() => updateall()}
                                        task_slug={task.task_slug}
                                        deleteFile={canDeleteFile}
                                    />
                                ))}
                            </div>
                        : <label id="label-place-file">Выберите или перетащите файлы</label>}
                        {loading ? 
                            <span class="loader-file" style={{maxHeight: "50px", maxWidth: "45px", borderRadius: "30px", marginLeft: "auto"}}></span>
                            : <img
                                id="add-files-icon"
                                src={apiUrlFrontend + 'plus-btn.svg'}
                                alt=""
                            />
                        }
                    </div>
                    <input id="place-file-here" {...getInputProps()}/>
                </div>
            </div>
            <div className="left-column-task-create">
                <div style={{display: 'flex', alignItems: 'center', height: '43px', position: 'sticky', width: '100%'}}>
                    <label className={`checkbox-label ${activeSeeSubtasks ? 'active' : ''}`}>
                        <input
                            type="checkbox"
                            onChange={(event) => {
                                setActiveSeeComments(false);
                                setActiveSeeHistory(false);
                                handleOpenRelatedTask(event, task.task_slug);
                                setActiveSeeSubtasks(true);
                            }}
                            checked={activeSeeSubtasks}
                        />
                        {task.subtasks && task.subtasks.length !== 0 ? task.subtasks.length: null}
                        <img id="checkbox-label-img"
                            alt=""
                            src={apiUrlFrontend + 'connect-btn.svg'}
                            style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                        />
                            Связанные задачи
                        <span className="indicator"></span>
                    </label>
                    {canViewHistoryTask ?
                        <label className={`checkbox-label ${activeSeeHistory ? 'active' : ''}`}>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    setActiveSeeComments(false);
                                    setActiveSeeSubtasks(false);
                                    updateHistory();
                                    setActiveSeeHistory(true);
                                }}
                                checked={activeSeeHistory}
                            />
                            <img id="checkbox-label-img"
                                alt=""
                                src={apiUrlFrontend + 'history-icon.svg'}
                                style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                            />
                            История
                            <span className="indicator"></span>
                        </label>: null}
                    {canViewComment ?
                        <label className={`checkbox-label ${activeSeeComments ? 'active' : ''}`}>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    setActiveSeeSubtasks(false);
                                    setActiveSeeHistory(false);
                                    setActiveSeeComments(true);
                                }}
                                checked={activeSeeComments}
                            />
                            {task.count_comments ? task.count_comments: null}
                            <img id="checkbox-label-img"
                                alt=""
                                src={apiUrlFrontend + 'comment.svg'}
                                style={{filter: resolvedTheme === 'light' ? 'invert(80%)': null}}
                            />
                            Комментарии
                            <span className="indicator"></span>
                        </label>
                    : null}
                    <button id="create-subtask" onClick={(event) => handleOpenModalCreateRelatedTask(event)}>
                        Создать связанную задачу
                    </button>
                    {task.subtasks ?
                        task.subtasks.length > 0 ?
                            <button id="create-report-subtask"
                                onClick={(event) => handleCreateReportForTask(event, task.task_slug)}
                            >
                                Сформировать отчет
                            </button>
                        : null
                    : null}
                </div>
            </div>
            <div className="right-column-task-create-row-2">
                {activeSeeSubtasks ?
                    <div id="div-with-mini-card-related">
                        {relatedSubtasks.map((ts, tsindex) => (
                            <button id="mini-card-detail-task" key={tsindex}
                                onClick={(e) => handleGoToRelatedTask(ts.task_slug, e)}
                                onMouseDown={(e) => handleClickGoToRelatedTaskNewPin(ts.task_slug, e)}
                            >
                                <label id="mini-card-task-title">
                                    {ts.title}
                                </label>
                                {ts.system_status_group !== 'Completed' ?
                                    <div id="mini-card-div-with-deadline-and-priority">
                                        {ts.deadline ?
                                            <label id="mini-card-task-deadline">
                                                {format(new Date(ts.deadline), 'dd.MM.yyyy')}
                                            </label>: null
                                        }
                                        <label style={{marginLeft: "auto", 
                                                color: ts.priority <= 3 ? 'rgba(100, 120, 214, 1.00)':
                                                    ts.priority <= 7 ? 'rgba(220, 136, 0, 1.00)':
                                                    'rgba(175, 52, 52, 1.00)'
                                            }}
                                        >
                                            &#9733;
                                        </label>
                                    </div>:
                                    <div id="mini-card-completed"/>
                                }
                            </button>
                        ))}
                    </div>: 
                    activeSeeHistory ? 
                        renderHistory()
                    : activeSeeComments && canViewComment ?
                        <div id="div-with-comments" ref={commentsEndRef}>
                            {comments.map(renderComment)}
                            {canViewUser && hintselectuserselect ?
                                <div id="hint-with-all-users">
                                    {hintuserfilter.map((pf, pfindex) =>
                                        <div id="hint-with-users">       
                                            <img id="image-performer"
                                                src={pf.image ? pf.image : apiUrlFrontend + "person-square.svg"}
                                                alt=""
                                            />
                                            <button id="select-user"
                                                onClick={() => handlePostUserFromHint(pf.username)}
                                            >
                                                {pf.formated_fio ? pf.formated_fio.slice(0, 42): pf.username.slice(0, 42)}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            : null}
                            {canAddComment ?
                                <div style={{display: "flex", width: "100%", height: 'fit-content', marginTop: '20px'}}>
                                    <textarea id="text-comment"
                                        className="no-resize"
                                        onChange={handleCommentTaskChange}
                                        placeholder='Введите текст комментария'
                                        value={commentuser}
                                        ref={textareaRefComment}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                    />
                                    <button style={{background: "none", border: "none", marginTop: "6px", maxHeight: "30px", maxWidth: "30px"}} onClick={() => setShowPicker(!showPicker)}>
                                        <img alt="" style={{maxHeight: "25px", maxWidth: "25px", cursor: "pointer"}} src={apiUrlFrontend + "btn-open-smile.svg"}/>
                                    </button>
                                    {showPicker && (
                                        <div ref={containerRef} style={{ border: 'none', position: 'absolute', 
                                            backgroundColor: '#393A45', boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.25)", 
                                            borderRadius: "10px", zIndex: "2", color: "white", marginTop: '-400px'
                                        }}>
                                        <Picker
                                            locale="ru"
                                            theme="dark" 
                                            data={data}
                                            onEmojiSelect={(emoji) => {
                                                setCommentUser((prevComment) => prevComment + emoji.native);
                                                setShowPicker(false);
                                            }}
                                        />
                                        </div>
                                    )}
                                    <button id="send-comment"
                                        onClick={handleSendCommentClick}
                                    >
                                        &#10148;
                                    </button>
                                </div>: null
                            }
                        </div>
                    : null
                }
            </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>    
    );
}

export default DetailTask;