import React from 'react';
import { useSelector } from 'react-redux';

const GetOnlineUser = ({ user }) => {
    const onlineUser = useSelector((state) => state.user.onlineUsers);
    const isUserOnline = onlineUser && onlineUser.some(c => c.id === user);

    return (
        <div
            style={{
                background: isUserOnline ? "green" : "red",
                minWidth: "10px",
                minHeight: "10px",
                maxWidth: "10px",
                maxHeight: "10px",
                borderRadius: "100px",
            }}
        />
    );
};

export default GetOnlineUser;