import React, { useState } from 'react';
import './styles/Registration.css';
import axios from 'axios';

const Registration = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const secretKey = process.env.REACT_APP_INVITE_KEY
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [nameOrganization, setNameOrganization] = useState("");
    const [registrationKey, setRegistrationKey] = useState("");
    const [email, setEmail] = useState("");
    const [checkBot, setCheckBot] = useState(1);
    
    document.title = "Регистрация - ТвояЗадача";

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (!/[\u0400-\u04FF]/.test(newValue) && !/\s/.test(newValue)) {
          setLogin(newValue);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkBot > 2) {
            window.location.href = "/";
        }
        else {
            if (secretKey === registrationKey) {
                    axios.post(`${apiUrl}api/identity/users/registration/`, {
                        name_organization: nameOrganization,
                        email: email,
                        password: password,
                        username: login,
                        invite_pass: registrationKey,
                    })
                .then(response => {
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                    window.location.href = "/choice-organization";
                })
                .catch(error =>{
                    const status = error.response ? error.response.status : null;
                    if (status === 400) {
                        if (error.response.data.errors[0].attr === 'email') {
                            alert(`Код ошибки: ${status}, некорректный адрес электронной почты`, error);
                        }                        
                        else if (error.response.data.errors[0].attr === 'username') {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                        }
                        else {
                            alert(`Код ошибки: ${status}, ${error.response.data.errors[0].detail}`, error);
                        }
                    }
                });
            }
            else {
                alert("Введен неверный код приглашения");
                setCheckBot(checkBot + 1);
            }
        }
    };

    return (
        <div className="login-page">
            <form className="registration-form" onSubmit={handleSubmit}>
                <h1>Регистрация</h1>
                <div style={{display: "flex"}}>
                    <div>
                        <input
                            type="text"
                            id="registration-username-txt"
                            value={login}
                            onChange={(e) => handleChange(e)}
                            placeholder='Логин'
                            required
                        />
                        <label id="hint-for-user">
                            разрешены символы латиницы, дефис и подчеркивание
                        </label>
                        <input
                            type="password"
                            id="registration-template-txt"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Пароль' 
                            required
                        />
                        <input
                            type="text"
                            id="registration-template-txt"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Электронная почта' 
                            required
                        />
                        <input
                            type="text"
                            id="registration-template-txt"
                            value={nameOrganization}
                            onChange={(e) => setNameOrganization(e.target.value)}
                            placeholder='Имя организации' 
                            required
                        />
                        <input
                            type="password"
                            id="registration-template-txt"
                            value={registrationKey}
                            onChange={(e) => setRegistrationKey(e.target.value)}
                            placeholder='Код приглашения' 
                            required
                        />
                        <button id="registration-confirm" type="submit">Зарегистрироваться</button>
                    </div>
                </div>
            </form>
        </div>    
    );
}

export default Registration;